import React, { Component } from 'react';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';



export default class AdminIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang
    };
  }


  render() {
    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
        </div>

      </>
    )
  }
}