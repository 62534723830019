import { faCogs, faStoreAlt, faShoppingCart, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as utils from '../lang/menuAgence';
import './navbar.scss'

export default class MenuAgence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      comptes: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.comptes != 'undefined') ? this.props.location.state.info.comptes : ""),
      profil: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.comptes != 'undefined') ? this.props.location.state.info.comptes[0].profil : "")
    };
    this.state.info.lang = this.state.lang;
  }



  render() {
    //console.log(this.props)
    //console.log(this.state.info)
    //console.log("NOW agenceInfo =" + JSON.stringify(this.state.agenceInfo))

    return (
      <>
        {/* <FontAwesomeIcon onClick={() => this.setState({ hidden: !this.state.hidden })} icon={faFlushed} /> */}
        <div className="container-fluid mt-2">
          {(this.state.info !== '' && this.state.comptes !== '') ?
            <>
              {this.state.comptes.map((item, index) => (
                <div key={index} className="card border-info d-flex bd-highlight mb-1">
                  {item.profil === 'Admin' ?
                    <div className="card-footer bg-transparent ">
                      <ul className="list-group list-group-flush">
                        {item.droits[item.droits.findIndex(s => s.id_module === 1)].acces ? 
                          <li className="list-group-item p-0 d-flex justify-content-center">
                            <NavLink to={{
                              pathname: '/magasin-index/' + item.id_agence,
                              state: { info: this.state.info }
                            }} >
                              <b><i>{item.nom_agence}</i></b>
                            </NavLink>
                          </li>
                        : ''}
                        {item.droits[item.droits.findIndex(s => s.id_module === 2)].acces ?
                          <li className="list-group-item p-0">
                            <NavLink to={{
                              pathname: '/creation-article/' + item.id_agence,
                              state: { info: this.state.info }
                            }}>
                              <FontAwesomeIcon className='mr-1' icon={faFile} />
                              {utils.lbl[`${(this.state.lang)}.0`]}
                            </NavLink>
                          </li>
                        : ''}
                        {item.droits[item.droits.findIndex(s => s.id_module === 2)].acces ?
                          <li className="list-group-item p-0">
                            <NavLink to={{
                              pathname: '/article-commercant/' + item.id_agence,
                              state: { info: this.state.info }
                            }}>
                              <FontAwesomeIcon className='mr-1' icon={faStoreAlt} />
                              {utils.lbl[`${(this.state.lang)}.1`]}
                            </NavLink>
                          </li>
                        : ''}
                        {item.droits[item.droits.findIndex(s => s.id_module === 3)].acces ?
                          <li className="list-group-item p-0">
                            <NavLink to={{
                              pathname: '/commande-consultation-commercant/' + item.id_agence,
                              state: { info: this.state.info }
                            }}>
                              <FontAwesomeIcon className='mr-1' icon={faShoppingCart} />
                              {utils.lbl[`${(this.state.lang)}.2`]}
                            </NavLink>
                          </li>
                        : ''}
                        {item.droits[item.droits.findIndex(s => s.id_module === 1)].acces ?
                          <li className="list-group-item p-0">
                            <NavLink to={{
                              pathname: '/modifier-magasin/' + item.id_agence,
                              state: { info: this.state.info }
                            }}>
                              <FontAwesomeIcon className='mr-1' icon={faCogs} />
                              {utils.lbl[`${(this.state.lang)}.3`]}
                            </NavLink>
                          </li>
                        : ''}
                      </ul>
                    </div>
                  : ''}
                  {item.profil === 'Root' ?
                    <div className="card-footer bg-transparent border-success">
                      <ul className="list-group-item">
                        <li className="list-group-item p-0">
                          <NavLink to={{
                            pathname: '/admin-index',
                            state: { info: this.state.info }
                          }}>{utils.lbl[`${(this.state.lang)}.4`]}
                          </NavLink>
                        </li>
                        <li className="list-group-item p-0">
                          <NavLink to={{
                            pathname: '/activation-module-tableau',
                            state: { info: this.state.info }
                          }}>{utils.lbl[`${(this.state.lang)}.5`]}
                          </NavLink>
                        </li>
                        <li className="list-group-item p-0">
                          <NavLink to={{
                            pathname: '/activation-ice-tableau',
                            state: { info: this.state.info }
                          }}>{utils.lbl[`${(this.state.lang)}.6`]}
                          </NavLink>
                        </li>
                        <li className="list-group-item p-0">
                          <NavLink to={{
                            pathname: '/agence-publicite',
                            state: { info: this.state.info }
                          }}>{utils.lbl[`${(this.state.lang)}.8`]}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  : ''}
                </div>
              ))}
            </>
          : ''}
          {this.state.profil === 'Admin' ?
            <div className="container p-0">
              <div className="card border-info d-flex bd-highlight">
                <div className="card-footer bg-transparent p-0 d-flex justify-content-center">
                  <Link to={{
                    pathname: '/nouveau-magasin',
                    state: { info: this.state.info }
                  }}>
                    {utils.lbl[`${(this.state.lang)}.7`]}
                  </Link>
                </div>
              </div>
            </div>
          : ''}
        </div>

      </>
    )
  }
}