export var lbl = [];

lbl["fr.0"] = "DESACTIVATION";
lbl["fr.1"] = "Dénomination";
lbl["fr.2"] = "Identifiant société national";
lbl["fr.3"] = "Date création";
lbl["fr.4"] = "Module";
lbl["fr.5"] = "Début activation";
lbl["fr.6"] = "Fin activation";
lbl["fr.7"] = "Date désactivation";
lbl["fr.8"] = "Désactiver";
lbl["fr.9"] = "Désactivé";
lbl["fr.10"] = "ERREUR : ";

/* AR */

lbl["ar.0"] = "ايقاف التّفعيل";
lbl["ar.1"] = "اسم المتجر";
lbl["ar.2"] = "رقم الشركة الوطني";
lbl["ar.3"] = "تاريخ الإنشاء";
lbl["ar.4"] = "وحدة";
lbl["ar.5"] = "بداية التفعيل";
lbl["ar.6"] = "نهاية التفعيل";
lbl["ar.7"] = "تاريخ التعطيل";
lbl["ar.8"] = "تعطيل";
lbl["ar.9"] = "معطّل";
lbl["ar.10"] = "خطأ : ";

/* EN */

lbl["en.0"] = "DESACTIVATION";
lbl["en.1"] = "Denomination";
lbl["en.2"] = "Client Number";
lbl["en.3"] = "Creation Date";
lbl["en.4"] = "Module";
lbl["en.5"] = "Start of activation";
lbl["en.6"] = "End of activation";
lbl["en.7"] = "Deactivation date";
lbl["en.8"] = "Deactivate";
lbl["en.9"] = "Deactivated";
lbl["en.10"] = "ERROR : ";

/* DE */

lbl["de.0"] = "DEAKTIVIEREN";
lbl["de.1"] = "Name";
lbl["de.2"] = "Kundennummer";
lbl["de.3"] = "Erstelldatum";
lbl["de.4"] = "Modul";
lbl["de.5"] = "Start der Aktivierung";
lbl["de.6"] = "Aktivierungsende";
lbl["de.7"] = "Deaktivierungsdatum";
lbl["de.8"] = "Deaktivieren";
lbl["de.9"] = "Deaktivieren";
lbl["de.10"] = "FEHLER : ";

