import React, { Component } from 'react';
import './creation.css';
import 'react-html5-camera-photo/build/css/index.css';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { PushSpinner } from 'react-spinners-kit';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Modal from 'react-bootstrap4-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import ArticleForm from './articleForm';
import * as utils from '../lang/articleCreation';
import * as comons from '../component/commun';



class ArticleCreation extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.props.location.id_agence_forArticle = this.props.match.params.idAgence;
    this.state = {
      isLoading1: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      id_agence: this.props.match.params.idAgence,
      id_article: '',
      cota_atteint: true,
      cota_msg:'',
      visible: false,
      ModalShow: false,
      hd: false,
      dsButton: false
    };
  }

  getCotaArticles = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cota_articles/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({
        cota_atteint: res.data.ca,
        cota_msg: res.data.msg,
        isLoading1: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount = () => {
    this.getCotaArticles();
  }

  onSaveArticle = (e) => {
    const form = this.formRef.current;

    e.preventDefault()

    //Désactivation du bouton
    this.setState({ dsButton: true });
    //Vérification de la saisie des données demandées
    if (form.state.achat == '' || form.state.vente == '' || form.state.quantite == '' || 
        form.state.annonce == '' || form.state.id_clientelle == '' || form.state.idcatarticle == '' || 
        (form.state.description == '' && form.state.blobURL == '' && form.state.audioInDB == '')) {
      toast.warning(utils.lbl[`${(this.state.lang)}.5`], {
        autoClose: 4000
      });
      this.setState({ dsButton: false });
    }
    else {
      const articleData = {
        idPersonnel: this.state.info.id_personnel,
        token: this.state.info.token,
        // nouveau article, pas d'idArticle 
        tbPrx: [
          {
            _key: '',
            dtDeb: '',
            dtFin: '',
            idTypPrx: 2,
            prx: form.state.vente
          },
          {
            _key: '',
            dtDeb: '',
            dtFin: '',
            idTypPrx: 1,
            prx: form.state.achat,
          }
        ],

        ann: form.state.annonce,
        qtt: form.state.quantite,
        dsc: form.state.description,
        idCli: form.state.id_clientelle,
        idCatArt: form.state.idcatarticle,
        pho: (form.state.dataUri.length > 0 ? form.state.dataUri : ''),
        aud: (form.state.blobURL.length > 0 ? form.state.blobURL : ''),
        idPho: '',
        idAud: '',
        idAgc: this.state.id_agence,
        codArt: form.state.code_article
      };
      if ((form.state.dDebutPromo != '' && form.state.dFinPromo != '' && form.state.promotion != '')) {
        articleData.tbPrx.push({
          _key: '',
          dtDeb: form.state.dDebutPromo,
          dtFin: form.state.dFinPromo,
          idTypPrx: '3',
          prx: form.state.promotion
        });
      }

      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/create_article`, articleData).then(res => {
        this.state.id_article = res.data.id_article;
        this.setState({
          id_article: res.data.id_article,
          ModalShow: true
        });
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
        this.setState({ dsButton: false });
      });
    }
  }



  render() {
    //console.log(this.state)
    //console.log(this.props)
    const { id_agence, isLoading1 } = this.state
    if (id_agence !== this.props.match.params.idAgence) {
      this.setState({
        id_agence: this.props.match.params.idAgence
      })
    }

    if (isLoading1) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading1} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid">
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.6`] : utils.lbl[`${(this.state.lang)}.7`]}
          </span>
          <div className="row">
            <div className="col-sm-5 col-md-5 col-lg-2 m-0 p-0" hidden={this.state.hd}>
              <MenuAgence location={this.props.location} />
            </div>
            <div className={this.state.hd ? "col-12" : "col-sm-12 col-md-12 col-lg-10 m-0 p-0"}>
              {this.state.cota_atteint ? 
                <div className="container-fluid">
                  <div className="row justify-content-center mb-5">
                    <h3>{this.state.cota_msg}</h3>
                  </div>
                </div>
              : 
                <ArticleForm location={this.props.location} ref={this.formRef} />
              }
            </div>
          </div>
        </div>

        {this.state.cota_atteint ? 
          <div className="row justify-content-center"> </div>
        :
          <div className="row justify-content-center">
            {/*----------------------- Buttons ---------------------*/}
            <div className="modal-footer">
              {/*----------------------- Button Créer ---------------------*/}
              <button type="button" disabled={this.state.dsButton} className="btn btn-group btn-group-lg bg-primary ml-5" onClick={this.onSaveArticle}>
                {utils.lbl[`${(this.state.lang)}.0`]}
              </button>
              {/*----------------------- Button Annuler ---------------------*/}
              <Link to={{
                      pathname: `/magasin-index/${this.state.id_agence}`,
                      state: { info: this.state.info }
                    }} type="button" className="btn btn-secondary ml-5">{utils.lbl[`${(this.state.lang)}.1`]}
              </Link>
            </div>
          </div>
        }

        <Modal visible={this.state.ModalShow} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.2`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <h2>{utils.lbl[`${(this.state.lang)}.3`]}</h2>
          </div>
          <div className="modal-footer">
            <Link to={{
                    pathname: `/article-commercant-detail/${this.state.id_article}/${this.state.id_agence}`,
                    state: { info: this.state.info }
                  }} type="button" className="btn btn-primary">{utils.lbl[`${(this.state.lang)}.4`]}
            </Link>
          </div>
        </Modal>
 
      </>
    );
  }

  back() {
    disableBrowserBackButton()
  }
}

export default withRouter(ArticleCreation)