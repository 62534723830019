export var lbl = [];

lbl["fr.0"] = "Consultation Commande Client";
lbl["fr.1"] = "Numéro de commande :";
lbl["fr.2"] = "Mél renseigné :";
lbl["fr.3"] = "Ou tél renseigné :";
lbl["fr.4"] = "Consulter";
lbl["fr.5"] = "Retour à l'accueil";
lbl["fr.6"] = "Magasin";
lbl["fr.7"] = "Annonce";
lbl["fr.8"] = "Image";
lbl["fr.9"] = "Quantité";
lbl["fr.10"] = "Prix";
lbl["fr.11"] = "Montant";
lbl["fr.12"] = "Livraison";
lbl["fr.13"] = "Date";
lbl["fr.14"] = "Opération";
lbl["fr.15"] = "Statut";
lbl["fr.16"] = "Demande d'annulation";
lbl["fr.17"] = "Alerte !";
lbl["fr.18"] = "Saisir le code d'annulation";
lbl["fr.19"] = "Numéro de contrôle :";
lbl["fr.20"] = "Confirmer";
lbl["fr.21"] = "Annuler";
lbl["fr.22"] = "Ok";
lbl["fr.23"] = "Contenu";

/* AR */

lbl["ar.0"] = "تقصّي طلب";
lbl["ar.1"] = "رقم الطلب :";
lbl["ar.2"] = "بريد إلكتروني مدخل :";
lbl["ar.3"] = "أو هاتف مدخل :";
lbl["ar.4"] = "تقصّي";
lbl["ar.5"] = "الرجوع إلى الاستقبال";
lbl["ar.6"] = "متجر";
lbl["ar.7"] = "إعلان";
lbl["ar.8"] = "صورة";
lbl["ar.9"] = "كمية";
lbl["ar.10"] = "ثمن";
lbl["ar.11"] = "حاصل";
lbl["ar.12"] = "توصيل";
lbl["ar.13"] = "تاريخ";
lbl["ar.14"] = "عملية";
lbl["ar.15"] = "وضعية";
lbl["ar.16"] = "دعوة للإلغاء";
lbl["ar.17"] = "!إنذار";
lbl["ar.18"] = "ادخال رمز دعوة الالغاء";
lbl["ar.19"] = "رقم المراقبة :";
lbl["ar.20"] = "تأكيد";
lbl["ar.21"] = "إلغاء";
lbl["ar.22"] = "حسنا";
lbl["ar.23"] = "محتوى";

/* EN */

lbl["en.0"] = "Customer Order Consultation";
lbl["en.1"] = "Order number :";
lbl["en.2"] = "Email entered :";
lbl["en.3"] = "Or phone informed :";
lbl["en.4"] = "To consult";
lbl["en.5"] = "Back to Home";
lbl["en.6"] = "Shop";
lbl["en.7"] = "Advert";
lbl["en.8"] = "Picture";
lbl["en.9"] = "Price";
lbl["en.10"] = "Amount";
lbl["en.11"] = "Annonce";
lbl["en.12"] = "Delivery";
lbl["en.13"] = "Date";
lbl["en.14"] = "Operation";
lbl["en.15"] = "Status";
lbl["en.16"] = "Cancellation request";
lbl["en.17"] = "Alert!";
lbl["en.18"] = "Enter the cancellation code";
lbl["en.19"] = "Control number :";
lbl["en.20"] = "Affirm";
lbl["en.21"] = "Annuler";
lbl["en.22"] = "Ok";
lbl["en.23"] = "Contenu";

/* DE */

lbl["de.0"] = "Beratung zum Kundenauftrag";
lbl["de.1"] = "Bestellnummer :";
lbl["de.2"] = "E-Mail Eingegeben :";
lbl["de.3"] = "Oder Telefon Eingegeben :";
lbl["de.4"] = "Konsultieren";
lbl["de.5"] = "Startseite";
lbl["de.6"] = "Shop";
lbl["de.7"] = "Advert";
lbl["de.8"] = "Bild";
lbl["de.9"] = "Menge";
lbl["de.10"] = "Preis";
lbl["de.11"] = "Menge";
lbl["de.12"] = "Lieferung";
lbl["de.13"] = "Datiert";
lbl["de.14"] = "Operation";
lbl["de.15"] = "Status";
lbl["de.16"] = "Antrag auf Annulierung";
lbl["de.17"] = "Alarm!";
lbl["de.18"] = "Geben Sie den Stornierungscode ein";
lbl["de.19"] = "Kontrollnummer :";
lbl["de.20"] = "Bestätigen";
lbl["de.21"] = "Annuler";
lbl["de.22"] = "Ok";
lbl["de.23"] = "Contenu";

