import React, { Component } from 'react';
import './creation.css';
import 'react-html5-camera-photo/build/css/index.css';
import { Link, withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import { PushSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import ArticleForm from './articleForm';
import * as utils from '../lang/articleModification';
import * as comons from '../component/commun';



class ArticleModification extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.props.location.id_article = this.props.match.params.idArticle;
    this.props.location.id_agence_forArticle = this.props.match.params.idAgence;
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      idArticle: this.props.location.id_article,
      idAgence: this.props.location.state.info.comptes[0].id_agence,
      redirect: false,
      dsButton: false
    };
  }

  onSaveArticle = (e) => {
    const form = this.formRef.current;

    e.preventDefault()

    //Désactivation du bouton
    this.setState({ dsButton: true });
    //Vérification de la saisie des données demandées
    if (form.state.achat == '' || form.state.vente == '' || form.state.quantite == '' || 
        form.state.annonce == '' || form.state.id_clientelle == '' || form.state.idcatarticle == '' || 
        (form.state.description == '' && form.state.blobURL == '' && form.state.audioInDB == '')) {
      toast.warning(utils.lbl[`${(this.state.lang)}.2`], {
        autoClose: 4000
      });
      this.setState({ dsButton: false });
    }
    else {
      const articleData = {
        idPersonnel: this.state.info.id_personnel,
        token: this.state.info.token,
        idArt: this.state.idArticle,
        tbPrx: [
          {
            _key: form.state._keyVente,
            dtDeb: form.state.dateDebutVente,
            dtFin: form.state.dateFinVente,
            idTypPrx: form.state.id_type_prix_Vente,
            prx: form.state.vente
          },
          {
            _key: form.state._keyAchat,
            dtDeb: form.state.dateDebutAchat,
            dtFin: form.state.dateFinAchat,
            idTypPrx: form.state.id_type_prix_Achat,
            prx: form.state.achat,
          }
        ],

        ann: form.state.annonce,
        qtt: form.state.quantite,
        dsc: form.state.description,
        idCli: form.state.id_clientelle,
        idCatArt: form.state.idcatarticle,
        pho: (form.state.dataUri.length > 0 ? form.state.dataUri : ''),
        aud: (form.state.blobURL.length > 0 ? form.state.blobURL : ''),
        idPho: form.state.idPhoto,
        idAud: form.state.idAudio,
        idAgc: form.state.id_agence,
        codArt: form.state.code_article
      };
      if ((form.state.dDebutPromo != '' && form.state.dFinPromo != '' && form.state.promotion != '')) {
        articleData.tbPrx.push({
          _key: '',
          dtDeb: form.state.dDebutPromo,
          dtFin: form.state.dFinPromo,
          idTypPrx: '3',
          prx: form.state.promotion,
        });
      }
      form.state.promoTab.forEach(el => {
        articleData.tbPrx.push({
          _key: el._keyPromo,
          dtDeb: el.dateDebutPromo,
          dtFin: ((el.statusPromo == "en cours" && form.state.datef != '' && form.state.datef != el.dateFinPromo) ? form.state.datef : el.dateFinPromo),
          idTypPrx: el.id_type_prix_Promo,
          prx: el.promo
        });
      });

      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/update_article`, articleData).then(res => {
        this.setState({ redirect: !this.state.redirect });
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
        this.setState({ dsButton: false });
      });
    }
  }

  getArticleData = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/read_article`,
      {
        idArticle: this.state.idArticle,
        token: this.state.info.token,
        idPersonnel: this.state.info.id_personnel
      }).then(res => {
        this.props.location.articleData = res.data[0];
        this.setState({ isLoading: false }); //Informer de la fin du chargement
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        })
      })
  }

  componentDidMount() {
    this.getArticleData();
  }



  render() {
    const { isLoading, redirect, idAgence, idArticle } = this.state;
    if (redirect) {
      return <Redirect to={{
        pathname: `/article-commercant-detail/${idArticle}/${idAgence}`,
        state: { info: this.state.info }
      }} />
    }

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid" style={{ backgroundColor: this.state.color }}>
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.6`] : utils.lbl[`${(this.state.lang)}.7`]}
          </span>
          <div className="row">
            <div className='col-lg-2 col-md-4 col-sm-6 p-0' hidden={this.state.hd}>
              <MenuAgence location={this.props.location} />
            </div>
            <div className={this.state.hd ? "col-12" : 'col-lg-10 col-md-12 col-sm-12 p-0'}>
              <ArticleForm location={this.props.location} ref={this.formRef} />
            </div>
          </div>
        </div>

        {/*----------------------- Buttons ---------------------*/}
        <div className="row justify-content-center">
          <div className="modal-footer">
            {/*----------------------- Button Enregistrer ---------------------*/}
            <button type="button"  disabled={this.state.dsButton} className="btn btn-primary" onClick={this.onSaveArticle}>{utils.lbl[`${(this.state.lang)}.0`]}</button>
            {/*----------------------- Button Annuler ---------------------*/}
            <Link to={{
                pathname: `/magasin-index/${this.state.idAgence}`,
                state: { info: this.state.info }
              }} type="button" className="btn btn-secondary ml-3">{utils.lbl[`${(this.state.lang)}.1`]}
            </Link>
          </div>
        </div>

      </>
    );
  }
}
export default withRouter(ArticleModification)