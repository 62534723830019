export var lbl = [];

lbl["fr.0"] = "Modifier";
lbl["fr.1"] = "Annuler";
lbl["fr.2"] = "Supprimer le compte";
lbl["fr.3"] = "Alerte !";
lbl["fr.4"] = "Mél";
lbl["fr.5"] = "Veuillez saisir le code de contrôle qui vous a été envoyé par mél :";
lbl["fr.6"] = "Confirmer la demande";
lbl["fr.7"] = "d'annulation";
lbl["fr.8"] = "Envoyer le code";
lbl["fr.9"] = "Votre compte a été supprimé";
lbl["fr.10"] = "Votre compte a bien été modifié";
lbl["fr.11"] = "OK";
lbl["fr.12"] = "code erroné";
lbl["fr.13"] = "> Montre Menu Magasin";
lbl["fr.14"] = "v Cache Menu Magasin";

/* AR */

lbl["ar.0"] = "تغيير";
lbl["ar.1"] = "إلغاء";
lbl["ar.2"] = "حذف الحساب";
lbl["ar.3"] = "إنذار !";
lbl["ar.4"] = "بريد إلكتروني";
lbl["ar.5"] = "الرجاء إدخال رمز التحقق المرسل عبر البريد الإلكتروني :";
lbl["ar.6"] = "تأكيد طلب";
lbl["ar.7"] = "الإلغاء";
lbl["ar.8"] = "أرسل الرمز";
lbl["ar.9"] = "تم حذف الحساب";
lbl["ar.10"] = "تم حفظ التغييرات";
lbl["ar.11"] = "حسنا";
lbl["ar.12"] = "خطأ في الرمز";
lbl["ar.13"] = "أظهر قائمة المحل <";
lbl["ar.14"] = "اخف قائمة المحل v";

/* EN */

lbl["en.0"] = "Change";
lbl["en.1"] = "Cancel";
lbl["en.2"] = "Delete account";
lbl["en.3"] = "Alert!";
lbl["en.4"] = "Email";
lbl["en.5"] = "Please enter the control code that was sent to you by email:";
lbl["en.6"] = "Confirm request";
lbl["en.7"] = "cancellation";
lbl["en.8"] = "Send Code";
lbl["en.9"] = "Your account has been deleted";
lbl["en.10"] = "Your account has been modified";
lbl["en.11"] = "OK";
lbl["en.12"] = "Wrong code";
lbl["en.13"] = "> Show Shop Menu";
lbl["en.14"] = "v Hide Shop Menu";

/* DE */

lbl["de.0"] = "Ändern";
lbl["de.1"] = "Abbrechen";
lbl["de.2"] = "Benutzerkonto löschen";
lbl["de.3"] = "Alarm!";
lbl["de.4"] = "Email";
lbl["de.5"] = "Bitte geben Sie den Kontrollcode ein,der Ihnen per E-Mail zugesandtwurde :";
lbl["de.6"] = "Bestätigen Sie die Anfforderung";
lbl["de.7"] = "Stornierung";
lbl["de.8"] = "Code senden";
lbl["de.9"] = "Ihr Konto wurde gelöscht";
lbl["de.10"] = "Ihr Konto wurde verändert";
lbl["de.11"] = "OK";
lbl["de.12"] = "Falscher Code";
lbl["de.13"] = "> Showen Agence Menu";
lbl["de.14"] = "v Hidden Agence Menu";

