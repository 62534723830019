import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './activation.css';
import axios from 'axios';
import ModalAddPublicite from '../Pages/modalAddPublicite';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap4-modal';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/agencePublicite';
import * as comons from '../component/commun';



export default class AgencePublicite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen_add_modal: false,
      isOpen_update_modal: false,
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      seccesMessageDelete: 'seccesfuly Deleting',
      errorMessageDelete: 'field Deleting',
      warnMessageDelete: 'canceled Deleting',
      confirmMessageDelete: 'are you sure,you want to delete this Pub !!',
      publicite: [],
      publicite_to_update: {},
      _isMounted: false,
      data: null,
      module: [],
      ModalShow1: false,
      agences: [],
      villes: [],
      pays: [],
      id_pays: '',
      id_periode: '',
      id_agence: '',
      agence: '',
      id_ville: '',
      selectedItem: {}
    };
  }

  Modal1 = (item) => {
    this.setState({
      selectedItem: item,
      key: item.key,
      id_agence: item.id_agence,
      agence: item.agence,
      id_pays: item.id_pays,
      id_periode: item.periode,
      id_ville: item.id_ville,
      ModalShow1: !this.state.ModalShow1
    });
  }

  componentWillUnmount() {
    this.state = { _isMounted: false }
  }

  getpublicites = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/all_publicite`, dmdData).then(res => {
      this.setState({ publicite: res.data })
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  // componentDidMount() {
  //   // this.state={_isMounted : true};
  //   //if (this._isMounted) {}
  //   this.getpublicites();
  //   //this.getModules();
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id_pays !== this.state.id_pays) {
      this.getVille();
    }
  }

  componentDidMount() {
    this.getpublicites();
    this.getPays();
    // this.getVille();
  }

  getVille = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/ville/${this.state.id_pays}/${this.state.lang}`).then(res => {
      this.setState({
        villes: res.data,
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getPays = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/pays/${this.state.lang}`).then(res => {
      this.setState({
        pays: res.data,
        is_pays_loading: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleAddModal = () => {
    this.setState({
      isOpen_add_modal: !this.state.isOpen_add_modal
    });
  }

  toggleUpdateModal = (item) => {
    this.setState({
      isOpen_update_modal: !this.state.isOpen_update_modal,
      publicite_to_update: item
    });
  }

  deletePublicite = (key) => {
    const pub = {
      key: key,
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    };

    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/delete/publicite`, pub).then(res => {
      this.refreshPublicite(key);
      toast.success(this.state.seccesMessageDelete, {
        autoClose: 3000
      })
    }).catch(err => {
      toast.error(err.response.data.error + err.maessage, {
        autoClose: 3000
      })
    })
  }

  refreshPublicite = (id) => {
    var that = this;
    const newList = that.state.publicite.filter((item) => item.key !== id);
    that.setState({ publicite: newList });
  }

  // deleteAgencePublicite = (key) => {
  //   var r = window.confirm(this.state.confirmMessageDelete);
  //   if (r == true) {
  //     this.deletePublicite(key);
  //   } else {
  //     toast.warning(this.state.warnMessageDelete, {
  //       autoClose: 3000
  //     });
  //   }
  // }

  update_agence_publicite = () => {
    const pub = {
      key: this.state.key,
      id_agence: this.state.id_agence,
      idPersonnel: this.state.info.id_personnel,
      token: this.props.location.state.info.token,
      id_pays: this.state.id_pays,
      id_ville: this.state.id_ville,
      periode: this.state.id_periode
    };

    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/update/publicite`, pub).then(res => {
      this.setState({
        ModalShow1: false
      });
      toast.success('seccessfely Updating', {
        autoClose: 3000
      });
      this.getpublicites();
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 3000
      });
    })
  }



  render() {
    //console.log(this.state)
    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
          <div className="p-2 flex-shrink-1 bd-info">
            <div className="d-flex justify-content-center">
              <h1 className={{ textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
            </div>
            <div className="d-flex justify-content-center">
              <table className="table table-sm table-light">
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.publicite.map(item => (
                    <tr>
                      <td style={{ height: '0.5rem' }}>{item.agence}</td>
                      <td style={{ height: '0.5rem' }}>{item.ville}</td>
                      <td style={{ height: '0.5rem' }}>{item.pays}</td>
                      <td style={{ height: '0.5rem' }}>{item.periode}</td>

                      <td style={{ height: '0.5rem' }}>
                        <button onClick={() => this.deletePublicite(item.key)} className="btn btn-sm mt-2 m-0 btn-danger">
                          {utils.lbl[`${(this.state.lang)}.7`]}
                        </button>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <button onClick={() => this.Modal1(item)} className="btn mt-2 m-0 btn-sm btn-primary">
                          {utils.lbl[`${(this.state.lang)}.8`]}
                        </button>

                      </td>
                    </tr>
                  ))}
                  <tr>

                  </tr>
                </tbody>
                <div >
                  <button onClick={this.toggleAddModal} className="btn mt-2 m-0 btn-primary">
                    {utils.lbl[`${(this.state.lang)}.6`]}
                  </button>
                  <ModalAddPublicite location={this.props.location} show={this.state.isOpen_add_modal} onClose={this.toggleAddModal}>{utils.lbl[`${(this.state.lang)}.6`]}</ModalAddPublicite>

                </div>
              </table>

            </div>
            <div className="d-flex justify-content-center">
              {/*TODO afficher la cv de l'agence*/}
              <span>chez Karim <Link to={'/publicitedetail'}>Detail</Link></span>
            </div>
          </div>
        </div>

        <Modal visible={this.state.ModalShow1} >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">UPDATE AGENCE PUBLICITE</h5>
          </div>

          <div className="modal-body pl-5">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">
                  Nom Agence
              </span>
              </div>
              <input type="text" onChange={this.handleChange} value={this.state.agence}
                name="id_agence" placeholder="Agence Name"
                autoComplete='false' className="form-control" aria-label="Default"
                aria-describedby="inputGroup-sizing-default" disabled />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">
                  Selectionner la semaine
              </span>
              </div>
              <input type="week" onChange={this.handleChange} value={this.state.id_periode}
                name="id_periode" placeholder="date"
                autoComplete='false' className="form-control" aria-label="Default"
                aria-describedby="inputGroup-sizing-default" />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Selectionner Votre Pays</span>
              </div>
              <datalist onChange={this.handleChange} className="form-control" value={this.state.id_pays} name='id_pays'>
                <option className='bg-danger' value="">Selection une pays</option>
                {this.state.pays.map((option) => (
                  <option key={option.id} value={option.id}>{option.lbl}</option>
                ))}
              </datalist>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Selectionner Votre ville</span>
              </div>
              <datalist onChange={this.handleChange} className="form-control" value={this.state.id_ville} name='id_ville'>
                <option className='bg-danger' value="">Selection une ville</option>
                {this.state.villes.map((option) => (
                  <option key={option.id} value={option.id}>{option.lbl}</option>
                ))}
              </datalist>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-primary ml-5 mr-5 mb-2" onClick={this.update_agence_publicite.bind(this)}>Update publicite</button>

            <button className='btn btn-secondary' onClick={() => this.setState({ ModalShow1: false })}>dismiss</button>
          </div>
        </Modal>
      </>
    )
  }
}