import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
import { PushSpinner } from 'react-spinners-kit';
import MenuUser from '../component/menuUser';
import MagasinForm from './magasinForm';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/magasinNouveau';
import * as comons from '../component/commun';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class MagasinNouveau extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      idPersonnel: this.props.location.state.info.id_personnel,
      idSociete: this.props.location.state.info.comptes[0].id_societe,
      idAgence: '',
      visible: false,
      typeChek: [],
      societeData: [],
      hidden: true,
      active: false,
      newItemHoraire: '',
      fortest: [],
      ModalShow: false,
      hd: false,
      dsButton: false
    };
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }

  getNewAgenceData = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_preagence`,
      {
        idPersonnel: this.state.info.id_personnel,
        token: this.state.info.token
      }
    ).then(res => {
      this.props.location.agenceData = res.data;
      this.setState({ isLoading: false }); //Informer de la fin du chargement

      //console.log("NOW dans ModifierCompte.getNewAgenceData = " + JSON.stringify(this.props))
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    this.getNewAgenceData();
  }

  handleCreationAgence = (e) => {
    const form = this.formRef.current;

    e.preventDefault()

    //Désactivation du bouton
    this.setState({ dsButton: true });
    //Vérification de la saisie des données demandées
    if (form.state.tel == '' || form.state.adr == '' || form.state.id_typecommerce == '' ||  form.state.id_clientelle == '' || 
      form.state.id_livraison== '' || form.state.id_ville == '' || form.state.id_suivi == '') {
      toast.warning(utils.lbl[`${(this.state.lang)}.5`], {
        autoClose: 4000
      });
      this.setState({ dsButton: false });
    }
    else {
      const societeData = {
        idPersonnel: this.state.info.id_personnel,
        token: this.state.info.token,
        //idSociete: this.state.idSociete,

        raisonSociale: form.state.raisonSociale,
        //ice: form.state.ice,
        site: form.state.site,
        //mail: form.state.mail,
        tel: form.state.tel,
        adr: form.state.adr,
        cp: form.state.cp,
        frais_livraison: form.state.fraisLiv,
        color: form.state.couleur,
        newHoraire: form.state.newHoraire,
        myFile: form.state.myFile,
        id_typecommerce: form.state.id_typecommerce,
        id_clientelle: form.state.id_clientelle,
        id_livraison: form.state.id_livraison,
        id_ville: form.state.id_ville,
        id_suivi: form.state.id_suivi,
        cgv: form.state.cgvContent
      };
      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/create_agence`, societeData).then(res => {
        this.state.info.comptes.push(res.data[0]);
        this.setState({
          societeData: res.data[0],
          idAgence: res.data[0].id_agence,
          ModalShow: true
        });
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
        this.setState({ dsButton: false });
      });
    }
  }



  render() {
    //console.warn(this.state)
    //console.warn(this.state.societeData)
    //console.warn(this.state.id_suivi)
    const { isLoading } = this.state;

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid">
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.6`] : utils.lbl[`${(this.state.lang)}.7`]}
          </span>
          <div className="row">
            <div className='col-lg-2 col-md-4 col-sm-6 p-0' hidden={this.state.hd}>
              <MenuAgence location={this.props.location} />
            </div>
            <div className={this.state.hd ? "col-12" : 'col-lg-10 col-md-12 col-sm-12 p-0'}>
              <MagasinForm location={this.props.location} ref={this.formRef} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.handleCreationAgence} disabled={this.state.dsButton}>{utils.lbl[`${(this.state.lang)}.0`]}</button>
            <Link to={{
              pathname: `/magasin-index/${this.state.info.comptes[0].id_agence}`,
              state: { info: this.state.info }
            }} type="button" className="btn btn-secondary ml-3">{utils.lbl[`${(this.state.lang)}.1`]}
            </Link>
          </div>
        </div>

        <Modal visible={this.state.ModalShow} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.2`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <h2>{utils.lbl[`${(this.state.lang)}.3`]}</h2>
          </div>
          <div className="modal-footer">
            <Link to={{
              pathname: `/magasin-index/${this.state.idAgence}`,
              state: { info: this.state.info }
            }} type="button" className="btn btn-primary">
              {utils.lbl[`${(this.state.lang)}.4`]}
            </Link>
          </div>
        </Modal>

      </>
    )
  }

  onAnnuler() {
    return <Link to="/" />
  }
}