import axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/commandeConsultationCommercant';
import * as comons from '../component/commun';



class CommandeConsultationCommercant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      idAgence: this.props.match.params.idAgence,
      datas: [],
      tb: 1,
      values: [],
      bo: false,
      ok: 'ali',
      hd: false
    };
  }

  tb = e => {
    this.setState({
      tb: e.target.value
    });
    this.getValue();
  }

  rendringTables = () => {
    return this.state.datas.map((item, index) => {
      return (<tr>
        <td style={{ height: '1rem' }}>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date_cmd))}</td>
        <td style={{ height: '1rem' }}>{item.num_commande}</td>
        <td style={{ height: '1rem' }}>{item.montant}</td>

        <td colSpan="2" style={{ height: '1rem' }}>
          <table>
            {item.panier.map((it) => (
              <tr style={{ backgroundColor: 'transparent', textAlign: 'center', borderColor: 'transparent' }}>
                <td style={{ height: '1rem' }}>x {it.q}</td>
                <td style={{ height: '1rem' }}>{it.annonce}</td>
              </tr>
            ))}
          </table>
        </td>
        <td style={{ height: '1rem' }}>{item.operation}</td>
        <td style={{ height: '1rem' }}>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date_operation))}</td>
        <td style={{ height: '1rem' }}>{item.status}</td>
        <td style={{ height: '1rem' }}>
          <Link to={{
            pathname: `/commande-traitement/${item.id_commande}/${this.state.idAgence}`,
            state: { info: this.state.info }
          }} type="button" className="btn btn-sm btn-success m-0">{utils.lbl[`${(this.state.lang)}.15`]}
          </Link>
        </td>
      </tr>)
    })
  }

  getValue = () => { //TODO modification data retour
    var dmdData = {
      idAgence: this.state.idAgence,
      numf: this.state.tb,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token,
      lng: this.state.lang
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commercant/commandes`, dmdData).then(res => {
      this.setState({
        datas: res.data
      });
    }).catch(err => {
      toast.warning(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    this.getValue();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if ((prevState.idAgence != this.state.idAgence) || (prevState.tb != this.state.tb)) {
      this.getValue();
    }
  }



  render() {
    //console.log(this.state.tb)

    if (this.state.idAgence != this.props.match.params.idAgence) {
      this.setState({
        idAgence: this.props.match.params.idAgence
      });
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid">
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.16`] : utils.lbl[`${(this.state.lang)}.17`]}
          </span>
          <div className="row">
            <div className={this.state.hd ? "col-12 p-0" : "col-lg-2 col-md-3 col-sm-4 p-0"} hidden={this.state.hd}>
              <MenuAgence hidden={true} location={this.props.location} />
            </div>
            <div className='col'>
              <table className="table table-sm table-light table-hover table-striped table-inverse table-responsive">
                <thead>
                  <tr scope="colgroup" className="table-active">
                    <th colspan="9">
                      <div className="container-fluid">
                        <div className='row d-flex flex-row justify-content-center m-0 p-0'>
                          <div className='col-2'>
                            <button onClick={this.tb} type="button" value="1" className={this.state.tb == 1 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.0`]}
                            </button>
                          </div>
                          <div className="col-2">
                            <button onClick={this.tb} type="button" value="2" className={this.state.tb == 2 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.1`]}
                            </button>
                          </div>
                          <div className="col-2">
                            <button onClick={this.tb} type="button" value="3" className={this.state.tb == 3 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.2`]}
                            </button>
                          </div>
                          <div className="col-2">
                            <button onClick={this.tb} type="button" value="4" className={this.state.tb == 4 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.3`]}
                            </button>
                          </div>
                          <div className="col-2">
                            <button onClick={this.tb} type="button" value="5" className={this.state.tb == 5 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.4`]}
                            </button>
                          </div>
                          <div className="col-2">
                            <button onClick={this.tb} type="button" value="6" className={this.state.tb == 6 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>
                              {utils.lbl[`${(this.state.lang)}.5`]}
                            </button>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr className="table-active">
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.7`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.8`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.9`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.10`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.11`]}</th>
                    <th scope="col p-0">{utils.lbl[`${(this.state.lang)}.12`]}</th>
                    <th scope="col p-0">{utils.lbl[`${(this.state.lang)}.13`]}</th>
                    <th scope="col p-0">{utils.lbl[`${(this.state.lang)}.14`]}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.rendringTables()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(CommandeConsultationCommercant)