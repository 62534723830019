export var lbl = [];

lbl["fr.0"] = "Modifier";
lbl["fr.1"] = "Annuler";
lbl["fr.2"] = "Alerte !";
lbl["fr.3"] = "OK";
lbl["fr.4"] = "Veuillez compléter la saisie des données";
lbl["fr.5"] = "> Montre Menu Magasin";
lbl["fr.6"] = "v Cache Menu Magasin";

/* AR */

lbl["ar.0"] = "تغيير";
lbl["ar.1"] = "إلغاء";
lbl["ar.2"] = "إنذار!";
lbl["ar.3"] = "حسنا";
lbl["ar.4"] = "المرجو إتمام إدخال المعلومات";
lbl["ar.5"] = "أظهر قائمة المحل <";
lbl["ar.6"] = "اخف قائمة المحل v";

/* EN */

lbl["en.0"] = "Edit";
lbl["en.1"] = "Cancel";
lbl["en.2"] = "Alert!";
lbl["en.3"] = "OK";
lbl["en.4"] = "Please complete the data entry";
lbl["en.5"] = "> Show Shop Menu";
lbl["en.6"] = "v Hide Shop Menu";

/* DE */

lbl["de.0"] = "Bearbeiten";
lbl["de.1"] = "Abbrechen";
lbl["de.2"] = "Aarm!";
lbl["de.3"] = "OK";
lbl["de.4"] = "Bitte vervollständigen Sie die Dateneingabe";
lbl["de.5"] = "> Showen Agence Menu";
lbl["de.6"] = "v Hidden Agence Menu";

