import React, { Component } from "react";
import { Link } from "react-router-dom";
import ShowImage from '../component/showImage';



export default class Card extends Component {
  render() {
    const { id_photo, nom, ville, adr, id, active, clientelle, typecommerce } = this.props.agence;

    return (
      <div className="col-10 mx-auto col-md-6 col-lg-4 my-3">
        <div className="card" style={{ height: "100%" }}>
          <ShowImage imgID={id_photo} imgStyle={{ height: "14rem" }} className="img-card-top" />
          <div className="card-body text-capitalize m-0 p-0">
            {active === true ?
              <Link to={{
                      pathname: `/vitrine/${id}`,
                      state: { info: this.props.info }
                    }} className="card-title d-flex justify-content-center" style={{ cursor: 'pointer' }}>{nom}</Link>
            :
              <p className="card-title d-flex justify-content-center">{nom}</p>
            }
            <hr className="m-0 p-0" />
            <div className="card-title d-flex flex-row justify-content-around">
              {clientelle.map(j => (
                <span className='mr-2'><b>{j.label}</b></span>
              ))}
            </div>
            <div className="card-title d-flex flex-row  justify-content-around">
              {typecommerce.map(j => (
                <span className='mb-0 badge badge-secondary'>{j.label}</span>
              ))}
            </div>
          </div>
          <div className="card-footer">
            <h6 className="card-title">{adr} - {ville}</h6>
          </div>
        </div>
      </div>
    );
  }
}