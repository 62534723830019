export var lbl = [];

lbl["fr.0"] = "Quantité";
lbl["fr.1"] = "Du";
lbl["fr.2"] = "Au";
lbl["fr.3"] = "DESCRIPTION";
lbl["fr.4"] = "Modifier";
lbl["fr.5"] = "Vitrine";
lbl["fr.6"] = "Supprimer";
lbl["fr.7"] = "Cacher";
lbl["fr.8"] = "Caché";
lbl["fr.9"] = "Votre article a été supprimé";
lbl["fr.10"] = "Précédant";
lbl["fr.11"] = "Suivant";
lbl["fr.12"] = "> Montre Menu Magasin";
lbl["fr.13"] = "v Cache Menu Magasin";
lbl["fr.14"] = "Annonce";

/* AR */

lbl["ar.0"] = "كمية";
lbl["ar.1"] = "من";
lbl["ar.2"] = "إلى";
lbl["ar.3"] = "وصف";
lbl["ar.4"] = "تغيير";
lbl["ar.5"] = "الواجهة";
lbl["ar.6"] = "حذف";
lbl["ar.7"] = "إخفاء";
lbl["ar.8"] = "مخفي";
lbl["ar.9"] = "تم حذف السلعة";
lbl["ar.10"] = "السابق";
lbl["ar.11"] = "التالي";
lbl["ar.12"] = "أظهر قائمة المحل <";
lbl["ar.13"] = "اخف قائمة المحل v";
lbl["ar.14"] = "إعلان";

/* EN */

lbl["en.0"] = "Amount";
lbl["en.1"] = "From";
lbl["en.2"] = "To";
lbl["en.3"] = "DESCRIPTION";
lbl["en.4"] = "Edit";
lbl["en.5"] = "Vitrine";
lbl["en.6"] = "Remove";
lbl["en.7"] = "Hide";
lbl["en.8"] = "Hidden";
lbl["en.9"] = "Your article has been deleted";
lbl["en.10"] = "Previous";
lbl["en.11"] = "Next";
lbl["en.12"] = "> Show Shop Menu";
lbl["en.13"] = "v Hide Shop Menu";
lbl["en.14"] = "Annouce";

/* DE */

lbl["de.0"] = "Menge";
lbl["de.1"] = "Von";
lbl["de.2"] = "bis";
lbl["de.3"] = "Beschreibung";
lbl["de.4"] = "Bearbeiten";
lbl["de.5"] = "Vitrine";
lbl["de.6"] = "Löschen";
lbl["de.7"] = "Ausblenden";
lbl["de.8"] = "Ausblenden";
lbl["de.9"] = "Ihr Artikel wurde gelöscht";
lbl["de.10"] = "Previous";
lbl["de.11"] = "Next";
lbl["de.12"] = "> Showen Agence Menu";
lbl["de.13"] = "v Hidden Agence Menu";
lbl["de.14"] = "Annouce";

