import React, { Component } from 'react';
import './activation.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/activationIceTableau';
import * as comons from '../component/commun';



export default class ActivationIceTableau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      activation: []
    };
  }

  getActivations = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/control_agences`, dmdData).then(res => {
      this.setState({ activation: res.data });
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.9`] + err);
      toast.error(err.response.data.error + err.maessage, {
        autoClose: 3000
      });
    });
  }

  componentDidMount() {
    this.getActivations();
  }

  handleValider = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    const dmdActiv = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token,
      idAgence: e.target.value
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/valider_agence`, dmdActiv).then(res => {
      e.target.innerHTML = utils.lbl[`${(this.state.lang)}.8`];
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.9`] + err);
      e.target.disabled = false;
      toast.error(err.response.data.error + err.message, {
        autoClose: 3000
      });
    });
  }



  render() {
    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
          <div className="p-2 flex-shrink-1 bd-info">
            <div className="d-flex justify-content-center">
              <h1 className={{ textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
            </div>
            <div className="d-flex justify-content-center">
              <table className="table table-sm table-light">
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.5`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activation.map(item => (
                    <tr>
                      <td>{item.nom}</td>
                      <td>{item.ice}</td>
                      <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date_creation))}</td>
                      <td>{item.ville}</td>
                      <td>{item.tel}</td>
                      <td>{item.mail}</td>
                      <td><button className="btn btn-primary" value={item.id_agence} onClick={e => this.handleValider(e)}>{utils.lbl[`${(this.state.lang)}.7`]}</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
    )
  }
}