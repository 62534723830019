import React, { Component } from 'react';
import { faPowerOff, faUser, faHome, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter, Redirect } from 'react-router-dom';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bgImage from '../assets/images/bg.jpg'
import * as comons from '../component/commun';
import * as utils from '../lang/menuUser';
import { Navbar, Nav } from 'react-bootstrap';
import './navbar.scss';



class MenuUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      profil: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.comptes != 'undefined') ? this.props.location.state.info.comptes[0].profil : ''),
      languages: [],
      check: false,
      isNavCollapsed: true
    };
    this.state.info.lang = this.state.lang;
  }

  getLangueSymbol = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/sym_lng/0`).then(res => {
      this.setState({
        languages: res.data,
      });
    });
  }

  componentDidMount() {
    this.getLangueSymbol();
  }

  handleLanguageChange = (e) => {
    const lang = e.target.value;
    this.setState({ lang });
    this.props.changeLanguage(lang);
  }

  logout = () => {
    const compteID = {
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/deconnexion`, compteID).then(res => {
      toast.success(utils.lbl[`${(this.state.lang)}.8`], {
        position: "top-left",
        autoClose: 1000,
      });
      this.setState({ info: '' });
      this.setState({ check: !this.state.check });
    });

    localStorage.clear();
    if (typeof this.props.history !== 'undefined') {
      this.props.history.push('/');
      window.location.reload()
    }
    disableBrowserBackButton();
  }

  home = () => {
    window.location.reload()
  }

  handleNavCollapse = () => {
    this.setState({ isNavCollapsed: !this.state.isNavCollapsed });
  }


  render() {

    const { info, check, languages } = this.state
    if (check) {
      return <Redirect to={{
        pathname: "/",
        state: { info: '' }
      }} />
    }

    return (
      <>

        <Navbar style={{ backgroundImage: 'url(' + bgImage + ')', backgroundSize: 'covre' }} fixed="top" variant="dark" expand="lg" sticky="top">

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="mr-auto p-0 mb-0 mt-0">
              <Nav.Link className='m-0 p-0'>
                {this.state.profil !== '' ?
                  <marquee width="700" style={{ fontSize: '17px', marginLeft: '0px', marginRight: '0px', color: 'white' }}>
                    {utils.lbl[`${(this.state.lang)}.0`]}{" "}{info.nom}
                  </marquee>
                : ''}
              </Nav.Link>
            </Nav>

            <Nav className="mr-0">
              {this.state.profil === 'Client' ?
                <>
                  <Nav.Link>
                    <Link to={{
                            pathname: '/',
                            state: { info: this.state.info }
                          }} style={{ color: 'white' }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.1`]}
                      </div>
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link to={{
                            pathname: '/modifier-compte',
                            state: { info: this.state.info }
                          }} style={{ color: 'white' }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.2`]}
                      </div>
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link to={{
                            pathname: '/commande-consultation-client',
                            state: { info: this.state.info }
                          }} style={{ color: 'white' }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.3`]}
                      </div>
                    </Link>
                  </Nav.Link>
                </>
              : ''}

              {this.state.profil === 'Admin' ?
                <>
                  <Nav.Link>
                    <Link to={{
                            pathname: '/modifier-compte',
                            state: { info: this.state.info }
                          }} style={{ color: 'white' }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        <FontAwesomeIcon icon={faUser} style={{ color: 'white', marginRight: '10px' }} />
                        {utils.lbl[`${(this.state.lang)}.2`]}
                      </div>
                    </Link>
                  </Nav.Link>
                </>
              : ''}

              {this.state.profil === '' ?
                <>
                  <Nav.Link>
                    <Link to={{
                            pathname: "/",
                            state: { info: this.state.info }
                          }} className="d-flex justify-content-between">
                      <FontAwesomeIcon icon={faHome} style={{ color: 'white', zoom: 1.2 }} />
                    </Link>
                  </Nav.Link>
 
                  <Nav.Link>
                    <Link to={{
                            pathname: '/creation-magasin',
                            state: { info: this.state.info }
                          }} >
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.4`]}
                      </div>
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link to={{
                            pathname: '/creation-compte',
                            state: { info: this.state.info }
                          }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.5`]}
                      </div>
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link to={{
                            pathname: '/commande-consultation',
                            state: { info: this.state.info }
                          }}>
                      <div style={{ color: 'white', background: 'transparent' }}>
                        {utils.lbl[`${(this.state.lang)}.6`]}
                      </div>
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link to={{
                            pathname: '/login',
                            state: { info: this.state.info }
                          }}>
                      <div style={{ color: 'white', background: 'transparent', marginRight: '1rem' }}>
                        <FontAwesomeIcon icon={faSignInAlt} style={{ color: 'white', zoom: 1, marginRight: '3px' }} />
                        <b>{utils.lbl[`${(this.state.lang)}.7`]}</b>
                      </div>
                    </Link>
                  </Nav.Link>

                  <div style={{ marginRight: '10rem' }}>
                    {languages.map((option) => (
                      <button
                        style={{ color: 'black', backgroundColor: 'white', width: '5erm', height: '8erm' }}
                        key={option.id} value={option.lbl}
                        onClick={this.handleLanguageChange}>
                        {option.lbl}
                      </button>
                    ))}
                  </div>
                </>
              :
                <Nav.Link>
                  <Link to="" onClick={this.logout} className="sm mt-0 pt-1 p-0">
                    <FontAwesomeIcon icon={faPowerOff} style={{ height: '1.5rem', width: '4rem', color: 'white' }} />
                  </Link>
                </Nav.Link>
              }

            </Nav>

          </Navbar.Collapse>

        </Navbar>

      </>
    )
  }
}

export default withRouter(MenuUser)