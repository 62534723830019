import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { faCheckCircle, faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as utils from '../lang/cvAgence';



export default class CVAgence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'en'),
      agence: (typeof this.props.location.dataAgence == 'undefined' ? '' : this.props.location.dataAgence)
    };
    this.state.info.lang = this.state.lang;
  }

  componentDidMount = () => {
  }



  render() {
    //console.log(this.state.info)
    const { agence } = this.state

    return (
      <>
        {agence != '' ?
          <div className="card mb-2 mt-2 p-0" style={{ border: 'none', maxWidth: "720px", backgroundColor: 'transparent', textAlign: 'center' }}>
            <div className="row ">
              <div className="col-md-4 col-sm-2">
                <img src={agence.photo} className="card-img" style={{ width: '220px', height: '200px' }} alt='' />
              </div>
              <div className="col-md-8 col-lg-8 p-0">
                <div className="card-body pb-0 pt-0">
                  <div style={{ textAlign: 'center' }}><b>{agence.nom}</b></div>
                  <br />
                  <table>
                    <thead>
                      <tr className='p-0' style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.0`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.5`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.7`]}</th>
                        <th scope="col">{utils.lbl[`${(this.state.lang)}.8`]}</th>
                      </tr>
                    </thead>
                    <tbody className='p-0'>
                      {this.state.agence.horaireouvert.map((item) => (
                        <tr className='p-0 m-0' style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                          <td style={{ height: '2rem' }}>{item.hrd}:{item.mind}</td>
                          <td style={{ height: '2rem' }}>{item.hrf}:{item.minf}</td>
                          <td style={{ height: '2rem' }}>{item.lundi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.mardi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.mercredi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.jeudi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.vendredi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.samedi ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                          <td style={{ height: '2rem' }}>{item.dimanche ? <FontAwesomeIcon className='mr-2' icon={faCheckCircle} /> : ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <h6 className="card-title" style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                    <FontAwesomeIcon className='mr-2 ml-2' style={{ height: '1rem' }} icon={faMapMarkerAlt} />
                    {agence.adr} - {agence.ville}
                  </h6>
                  <h6>
                    <FontAwesomeIcon className='mr-2 ml-2' style={{ height: '1rem' }} icon={faPhone} />
                    {agence.tel} -
                    <FontAwesomeIcon className='mr-2 ml-2' style={{ height: '1rem' }} icon={faEnvelope} />
                    {agence.mail}
                  </h6>
                </div>

              </div>
            </div>
          </div>
          :
          <div className="card mb-3 mt-5 pb-0" style={{ maxWidth: "720px", backgroundColor: 'transparent', textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.9`]}</div>
        }

      </>
    )
  }
}