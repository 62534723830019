export var lbl = [];

lbl["fr.0"] = "Se connecter";
lbl["fr.1"] = "Alerte !";
lbl["fr.2"] = "Quel compte voulez-vous utiliser ?";
lbl["fr.3"] = "Client";
lbl["fr.4"] = "ou";
lbl["fr.5"] = "Commerçant";
lbl["fr.6"] = "ِChamp requis!";
lbl["fr.7"] = "Ce n'est pas un mél valide!";
lbl["fr.8"] = "Entrer votre mél";
lbl["fr.9"] = "Mél";
lbl["fr.10"] = "Annuler";
lbl["fr.11"] = "Envoyer";
lbl["fr.12"] = "Mot de passe oublié ?";

/* AR */

lbl["ar.0"] = "تسجيل الذخول";
lbl["ar.1"] = "إنذار !";
lbl["ar.2"] = "أي حساب تودّون استعمال؟";
lbl["ar.3"] = "زبون";
lbl["ar.4"] = "أو";
lbl["ar.5"] = "تاجر";
lbl["ar.6"] = "هذه المعلومة ضرورية!";
lbl["ar.7"] = "البريد الإلكتروني غير صحيح!";
lbl["ar.8"] = "أذخل بريدك الإلكتروني";
lbl["ar.9"] = "البريد الإلكتروني";
lbl["ar.10"] = "ألغ";
lbl["ar.11"] = "أرسل";
lbl["ar.12"] = "نسيت الكلمة السرية ؟";

/* EN */

lbl["en.0"] = "Log in";
lbl["en.1"] = "Alert!";
lbl["en.2"] = "Which account do you want to use ?";
lbl["en.3"] = "Customer";
lbl["en.4"] = "or";
lbl["en.5"] = "Merchant";
lbl["en.6"] = "This field is required!";
lbl["en.7"] = "This is not a valid email!";
lbl["en.8"] = "Enter your email";
lbl["en.9"] = "Email";
lbl["en.10"] = "Dismiss";
lbl["en.11"] = "Send";
lbl["en.12"] = "Forgot your password ?";

/* DE */

lbl["de.0"] = "Log in";
lbl["de.1"] = "Alarm!";
lbl["de.2"] = "Welches Konto möchten Sie verwenden ?";
lbl["de.3"] = "Kunde";
lbl["de.4"] = "oder";
lbl["de.5"] = "Händler";
lbl["de.6"] = "Dieses Feld ist Erforderlich!";
lbl["de.7"] = "Dies ist eine ungültige E-Mail!";
lbl["de.8"] = "Enter Your Mail";
lbl["de.9"] = "Mail";
lbl["de.10"] = "Dismiss";
lbl["de.11"] = "Send";
lbl["de.12"] = "Forgot your password ?";

