export var lbl = [];

lbl["fr.0"] = "Enregistrer";
lbl["fr.1"] = "Annuler";
lbl["fr.2"] = "Veuillez compléter la saisie des données";
lbl["fr.3"] = "> Montre Menu Magasin";
lbl["fr.4"] = "v Cache Menu Magasin";

/* AR */

lbl["ar.0"] = "تسجيل";
lbl["ar.1"] = "إلغاء";
lbl["ar.2"] = "المرجو إتمام إدخال المعلومات";
lbl["ar.3"] = "أظهر قائمة المحل <";
lbl["ar.4"] = "اخف قائمة المحل v";

/* EN */

lbl["en.0"] = "Save";
lbl["en.1"] = "Cancel";
lbl["en.2"] = "Please complete the data entry";
lbl["en.3"] = "> Show Shop Menu";
lbl["en.4"] = "v Hide Shop Menu";

/* DE */

lbl["de.0"] = "Speichern";
lbl["de.1"] = "Abbrechen";
lbl["de.2"] = "Bitte vervollständigen Sie die Dateneingabe";
lbl["de.3"] = "> Showen Agence Menu";
lbl["de.4"] = "v Hidden Agence Menu";

