export var lbl = [];

lbl["fr.0"] = "De";
lbl["fr.1"] = "A";
lbl["fr.2"] = "LUN";
lbl["fr.3"] = "MAR";
lbl["fr.4"] = "MER";
lbl["fr.5"] = "JEU";
lbl["fr.6"] = "VEN";
lbl["fr.7"] = "SAM";
lbl["fr.8"] = "DIM";
lbl["fr.9"] = "Aucune info sur l'agence...";

/* AR */

lbl["ar.0"] = "من";
lbl["ar.1"] = "إلى";
lbl["ar.2"] = "اثنين";
lbl["ar.3"] = "ثلاثاء";
lbl["ar.4"] = "أربعاء";
lbl["ar.5"] = "خميس";
lbl["ar.6"] = "جمعة";
lbl["ar.7"] = "سبت";
lbl["ar.8"] = "أحد";
lbl["ar.9"] = "لا توجد معلومة عن المتجر...";

/* EN */

lbl["en.0"] = "From";
lbl["en.1"] = "To";
lbl["en.2"] = "MON";
lbl["en.3"] = "TUE";
lbl["en.4"] = "WED";
lbl["en.5"] = "THU";
lbl["en.6"] = "FRI";
lbl["en.7"] = "SAT";
lbl["en.8"] = "SUN";
lbl["en.9"] = "No agency info...";

/* DE */

lbl["de.0"] = "von";
lbl["de.1"] = "bis";
lbl["de.2"] = "Mon";
lbl["de.3"] = "Die";
lbl["de.4"] = "Mitt";
lbl["de.5"] = "Donner";
lbl["de.6"] = "Frei";
lbl["de.7"] = "Sam";
lbl["de.8"] = "Sonn";
lbl["de.9"] = "Keine Angaben zur Agentur...";

