import React, { Component } from 'react';
import Avatar from 'react-avatar';
import axios from 'axios';
import { PushSpinner } from "react-spinners-kit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import * as utils from '../lang/magasinForm';
import * as comons from '../component/commun';
import * as cgv from '../lang/CGV';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default class MagasinForm extends Component {
  constructor(props) {
    super(props);
    //console.log("NOW MagasinForm.CTR = " + JSON.stringify(this.props.location.state));
    this.refreshDataAgence();
    this.initialStateHoraire();
    //this.state.info.lang = this.state.lang;
  }

  reInitCheckbox = () => {
    for (let i = 0; i < this.state.typeCommerce.length; i++) {
      let ref = 'typeCommerce' + (i + 1);
      this.refs[ref].checked = false;
    }
    for (let i = 0; i < this.state.clientelle.length; i++) {
      let ref = 'clientelle' + (i + 1);
      this.refs[ref].checked = false;
    }
    for (let i = 0; i < this.state.suiviData.length; i++) {
      let ref = 'suiviData' + (i + 1);
      this.refs[ref].checked = false;
    }
  }

  refreshDataAgence = () => {
    if (typeof this.props.location.agenceData == 'undefined') {
      this.state = {
        isLoading1: true, isLoading2: true, isLoading3: true, isLoading4: true, isLoading5: true, //Pour patienter le temps du chargement
        info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
        lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
        agenceData: "",

        id: '',
        raisonSociale: '',
        ice: '',
        site: '',
        mail: '',
        tel: '',
        adr: '',
        cp: '',
        id_ville: '',
        id_typecommerce: [],
        id_clientelle: [],
        couleur: "#ffffff",
        id_livraison: "1",
        myFile: '',
        fraisLiv: '',
        newHoraire: [],
        id_suivi: [],
        cgvContent: '',

        lundi: false,
        mardi: false,
        mercredi: false,
        jeudi: false,
        vendredi: false,
        samedi: false,
        dimanche: false,
        active: false,
        hrd: '00',
        hrf: '00',
        mind: '00',
        minf: '00',
        city: [],
        livraison: [],
        clientelle: [],
        typeCommerce: [],
        suiviData: [],
        vl: ''
      };
    }
    else {
      this.state = {
        isLoading1: true, isLoading2: true, isLoading3: true, isLoading4: true, isLoading5: true, //Pour patienter le temps du chargement
        info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') ? this.props.location.state.info : ""),
        lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') ? this.props.location.state.info.lang : "fr"),
        agenceData: this.props.location.agenceData,

        id: (typeof this.props.location.agenceData == 'undefined' ? "" : this.props.location.agenceData.id),
        raisonSociale: (typeof this.props.location.agenceData.raisonSociale == 'undefined' ? "" : this.props.location.agenceData.raisonSociale.trim()),
        ice: (typeof this.props.location.agenceData.ice == 'undefined' ? "" : this.props.location.agenceData.ice.trim()),
        site: (typeof this.props.location.agenceData.site == 'undefined' ? "" : this.props.location.agenceData.site.trim()),
        mail: (typeof this.props.location.agenceData.mail == 'undefined' ? "" : this.props.location.agenceData.mail.trim()),
        tel: (typeof this.props.location.agenceData.tel == 'undefined' ? "" : this.props.location.agenceData.tel.trim()),
        adr: (typeof this.props.location.agenceData.adr == 'undefined' ? "" : this.props.location.agenceData.adr.trim()),
        cp: (typeof this.props.location.agenceData.cp == 'undefined' ? "" : this.props.location.agenceData.cp),
        id_ville: (typeof this.props.location.agenceData.id_ville == 'undefined' ? "" : this.props.location.agenceData.id_ville),
        id_typecommerce: (typeof this.props.location.agenceData.id_typecommerce == 'undefined' ? [] : this.props.location.agenceData.id_typecommerce),
        id_clientelle: (typeof this.props.location.agenceData.id_clientelle == 'undefined' ? [] : this.props.location.agenceData.id_clientelle),
        couleur: (typeof this.props.location.agenceData.color == 'undefined' ? "" : this.props.location.agenceData.color.trim()),
        id_livraison: (typeof this.props.location.agenceData.id_livraison == 'undefined' ? "" : this.props.location.agenceData.id_livraison),
        myFile: (typeof this.props.location.agenceData.myFile == 'undefined' ? "" : this.props.location.agenceData.myFile),
        fraisLiv: (typeof this.props.location.agenceData.frais_livraison == 'undefined' ? "" : this.props.location.agenceData.frais_livraison),
        newHoraire: (typeof this.props.location.agenceData.newHoraire == 'undefined' ? [] : this.props.location.agenceData.newHoraire),
        id_suivi: (typeof this.props.location.agenceData.id_suivi == 'undefined' ? [] : this.props.location.agenceData.id_suivi),
        cgvContent: (typeof this.props.location.agenceData.cgv == 'undefined' ? "" : this.props.location.agenceData.cgv),

        lundi: false,
        mardi: false,
        mercredi: false,
        jeudi: false,
        vendredi: false,
        samedi: false,
        dimanche: false,
        active: false,
        hrd: '00',
        hrf: '00',
        mind: '00',
        minf: '00',
        city: [],
        livraison: [],
        clientelle: [],
        typeCommerce: [],
        suiviData: [],
        vl: ''
      };
      this.reInitCheckbox();
    }
  }

  getLivraison = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/livraison/${this.state.lang}`).then(res => {
      this.setState({
        livraison: res.data
      });
      this.setState({ isLoading1: false }); //Informer de la fin du chargement
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getClientelle = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/clientelle/${this.state.lang}`).then(res => {
      this.setState({
        clientelle: res.data
      });
      this.setState({ isLoading2: false }); //Informer de la fin du chargement
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getTypeCommerce = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/typeCommerce/${this.state.lang}`).then(res => {
      this.setState({
        typeCommerce: res.data
      });
      this.setState({ isLoading3: false }); //Informer de la fin du chargement
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getSuiviCommande = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/commande_codemode/${this.state.lang}`).then(res => {
      this.setState({
        suiviData: res.data
      });
      this.setState({ isLoading4: false }); //Informer de la fin du chargement
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getVille = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/ville/${this.state.lang}`).then(res => {
      this.setState({
        city: res.data
      });
      this.setState({ isLoading5: false }); //Informer de la fin du chargement
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.id != this.state.id) {
      this.refreshDataAgence();
      this.initialStateHoraire();
    }
  }

  componentDidMount() {
    this.getLivraison();
    this.getClientelle();
    this.getTypeCommerce();
    this.getVille();
    this.getSuiviCommande();
    if (this.state.id != "") {
      var cgvcnt = "";
      for (let i = 0; i <= 22; i++) {
        cgvcnt += cgv.lbl[`${(this.state.lang)}.${i}`] + "\n";
      }
      this.setState({ cgvContent: cgvcnt });
    }
  }

  handleChecked = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  }

  handleFileChange = evt => {
    var tgt = evt.target || window.event.srcElement,
      files = tgt.files;
    if (FileReader && files && files.length) {
      var file = evt.target.files[0];
      var fr = new FileReader();
      fr.readAsDataURL(file)

      fr.onload = () => {
        //console.log("File size = ", fr.result.Int16Array);
        this.setState({
          myFile: fr.result
        })
      };
      fr.onerror = function () {
        console.log(fr.error);
      };
    }
  }

  fileUpload = () => {
    console.log(this.state.myFile);
  }

  handleTypeCommerce = (e, tc) => {
    const id_typecommerce = this.state.id_typecommerce;

    if (e.target.checked) {
      id_typecommerce.push(tc.id);
    }
    else {
      const index = id_typecommerce.findIndex((ch) => ch == tc.id);
      id_typecommerce.splice(index, 1);
    }
    this.setState({ id_typecommerce });
  }

  handleClientelle = (e, c) => {
    const id_clientelle = this.state.id_clientelle;

    if (e.target.checked) {
      id_clientelle.push(c.id);
    }
    else {
      const index = id_clientelle.findIndex((ch) => ch == c.id);
      id_clientelle.splice(index, 1);
    }
    this.setState({ id_clientelle });
  }

  handleLivraison = e => {
    this.setState({
      id_livraison: e.target.value
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onChangeVille = (e) => {
    this.state.id_ville = '';
    for (let i = 0; i < this.state.city.length; i++) {
      if (this.state.city[i].lbl == e.target.value) {
        this.setState({ id_ville: this.state.city[i].id });
        break;
      }
    }
  }

  handleSuivi = (e, s) => {
    const id_suivi = this.state.id_suivi;

    if (e.target.checked) {
      id_suivi.push(s.id);
    }
    else {
      const index = id_suivi.findIndex((ch) => ch == s.id);
      id_suivi.splice(index, 1);
    }
    this.setState({ id_suivi });
  }

  initialStateHoraire = () => {
    this.setState({
      lundi: false,
      mardi: false,
      mercredi: false,
      jeudi: false,
      vendredi: false,
      samedi: false,
      dimanche: false,
      active: false,
      hrd: '00',
      hrf: '00',
      mind: '00',
      minf: '00'
    });
  }

  ajouterHoraire = () => {
    var newHoraireElem = {
      id: this.state.newHoraire.length,
      lundi: this.state.lundi,
      mardi: this.state.mardi,
      mercredi: this.state.mercredi,
      jeudi: this.state.jeudi,
      vendredi: this.state.vendredi,
      samedi: this.state.samedi,
      dimanche: this.state.dimanche,
      hrd: this.state.hrd,
      hrf: this.state.hrf,
      mind: this.state.mind,
      minf: this.state.minf,
      active: this.state.active
    };
    let horaireouvert = this.state.newHoraire;
    horaireouvert.push(newHoraireElem);
    this.setState({
      newHoraire: horaireouvert
    });

    this.initialStateHoraire();
  }

  handleActiveHoraire = (e, s) => {
    let horaireouvert = this.state.newHoraire;
    let i = horaireouvert.findIndex(item => item.id == s.id)
    horaireouvert[i].active = e.target.checked;
    this.setState({
      newHoraire: horaireouvert
    });
  }

  handleDeleteHoraire = (id) => {
    let horaireouvert = this.state.newHoraire;
    let i = horaireouvert.findIndex(item => item.id == id);
    horaireouvert.splice(i, 1);
    for (let j = 0; j < horaireouvert.length; j++) {
      horaireouvert[j].id = j;
    }
    this.setState({
      newHoraire: horaireouvert
    });
  }

  tableHoraireHeader = () => {
    return (
      <tr>
        <th>{utils.lbl[`${(this.state.lang)}.17`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.18`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.19`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.20`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.21`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.22`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.23`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.24`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.25`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.26`]}</th>
        <th>{utils.lbl[`${(this.state.lang)}.27`]}</th>
      </tr>
    )
  }

  checkValidity = (fieldName, fieldPattern, fieldMsgErr) => {

  }



  render() {
    //console.log(this.props)
    //console.log(this.state)
    this.state.city.find(element => {
      if (element.id == this.state.id_ville) {
        this.state.vl = element.lbl
      };
    });
    const { isLoading1, isLoading2, isLoading3, isLoading4, isLoading5 } = this.state;
    const { raisonSociale, ice, site, mail, tel, adr, cp, city, livraison, clientelle, typeCommerce, suiviData, fraisLiv } = this.state;
    const arr = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    const minute = ['00', '15', '30', '45']

    if ((typeof this.props.location.agenceData != 'undefined') &&
      (this.props.location.agenceData != '') &&
      (this.state.id != this.props.location.agenceData.id)) {
      this.setState({
        id: this.props.location.agenceData.id
      });
    }

    if (isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={(isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5)} />
      </div>
    }

    return (
      <>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-column justify-content-center'>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.0`]}</span>
                </div>
                <input onChange={this.onChange} value={raisonSociale} name="raisonSociale" type="text" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('raisonSociale', "[A-Za-z][A-Za-z0-9' -]+", utils.lbl[`${(this.state.lang)}.31`])}
                  required pattern="[A-Za-z][A-Za-z0-9' -]+" />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.1`]}</span>
                </div>
                <input onChange={this.onChange} value={ice} name="ice" type="text" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('ice', "[0-9]{15,20}", utils.lbl[`${(this.state.lang)}.31`])}
                  required pattern="[0-9]{15,20}" maxLength="15" disabled={this.state.agenceData !== ''} />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.4`]}</span>
                </div>
                <input onChange={this.onChange} value={tel} name="tel" type="tel" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" required />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                </div>
                <input onChange={this.onChange} value={mail} name="mail" type="email" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" aria-invalid="false"
                  required disabled={this.state.agenceData !== ''} />
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-column justify-content-center'>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                </div>
                <input onChange={this.onChange} value={adr} name="adr" type="text" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" required />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.6`]}</span>
                </div>
                <input onChange={this.onChange} value={cp} name="cp" type="number" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" aria-required="true" aria-invalid="false"
                  min="10000" max="99999" required />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01" id="inputGroup-sizing-sm">
                    {utils.lbl[`${(this.state.lang)}.7`]}
                  </label>
                </div>
                <input type="text" list="data" name="nom_ville"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                  aria-required="true" aria-invalid="false" defaultValue={this.state.vl}
                  className="form-control" onChange={this.onChangeVille} />
                <datalist id="data" name="id_ville" required>
                  {city.map((item, key) => (
                    <option value={item.lbl} key={item.id} id={item.id} />
                  ))}
                </datalist>
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                </div>
                <input onChange={this.onChange} value={site} name="site" type="url" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-light" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text mr-5" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.14`]}</span>
                </div>
                <input type="color" name="couleur" value={this.state.couleur} onChange={this.onChange} />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.12`]}</span>
                </div>
                <select onChange={this.onChange} value={this.state.id_livraison} name='id_livraison' className="custom-select form-control border" aria-required="true" required>
                  <option value="">{utils.lbl[`${(this.state.lang)}.32`]}</option>
                  {livraison.map(item => (
                    <option key={item.id} value={item.id}>{item.lbl}</option>
                  ))}
                </select>
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.13`]}</span>
                </div>
                <input onChange={this.onChange} value={fraisLiv} name="fraisLiv" type="number" className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" min="0" />
              </div>
              <hr className="bg-light" />
              <div className="container-fluid">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.8`]}</span>
                  </div>
                </div>
                <div className="form-group input-group">
                  <div className="col">
                    {typeCommerce.map(item => (
                      <div className="form-check">
                        <label className="form-check-label">
                          <input type="checkbox" className="mr-2"
                            ref={`typeCommerce${item.id}`}
                            name="typeCommerceId"
                            checked={this.state.id_typecommerce.find((ch) => ch == item.id)}
                            onChange={(e) => this.handleTypeCommerce(e, item)} />
                          {item.lbl}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.10`]}</span>
                </div>
              </div>
              <Avatar googleId="118096717852922241760" src={this.state.myFile} size="70" round={true} />
              {/* <img src={pic} alt="Avatar" class="avatar"/> */}
              <input type="file" onChange={this.handleFileChange} className="form-control-file border" />
              <button className="btn btn-primary" onClick={this.fileUpload}>
                <FontAwesomeIcon icon={faUpload} className="mr-2" />
                {utils.lbl[`${(this.state.lang)}.11`]}
              </button>
              <hr className="bg-light" />
              <div className="container-fluid">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.9`]}</span>
                  </div>
                </div>
                <div className="form-group input-group">
                  <div className="col">
                    {clientelle.map(item => (
                      <div className="form-check">
                        <label className="form-check-label">
                          <input type="checkbox" className="mr-2"
                            ref={`clientelle${item.id}`}
                            name="clientelleId"
                            checked={this.state.id_clientelle.find((ch) => ch == item.id)}
                            onChange={(e) => this.handleClientelle(e, item)} />
                          {item.lbl}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr className="bg-light" />
              <div className="container-fluid">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.15`]}</span>
                  </div>
                </div>
                <div className="form-group input-group">
                  <div className="col">
                    {suiviData.map(item => (
                      <div className="form-check">
                        <label className="form-check-label">
                          <input type="checkbox" className="mr-2"
                            ref={`suiviData${item.id}`}
                            name="suiviId"
                            checked={this.state.id_suivi.find((ch) => ch == item.id)}
                            onChange={(e) => this.handleSuivi(e, item)} />
                          {item.lbl}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-light" />
        {/* ------------------------------- TABLEAU HORAIRES ------------------------- */}
        <div className="container-fluid">
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.16`]}</span>
            </div>
          </div>
          <div className="form-group input-group">
            <div className="row">
              <div className="container-fluid" style={{ display: 'grid' }}>
                <table className="table table-dark table-hover table-striped table-inverse table-responsive">
                  <thead className="thead-inverse">
                    {this.tableHoraireHeader()}
                  </thead>
                  <tbody>
                    <tr className='p-0'>
                      <td style={{ height: '0.5rem' }}>
                        <div className="d-flex flex-fill">
                          <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group d-flex flex-row" >
                            <select className="form-control" name="hrd" value={this.state.hrd} onChange={this.onChange}>
                              {arr.map((item, index) =>
                                <option key={index}>{item}</option>
                              )}
                            </select>
                          </div> :
                          <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group mr-2">
                            <select className="form-control" name="mind" value={this.state.mind} onChange={this.onChange}>
                              {minute.map((item, index) =>
                                <option key={index}>{item}</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="d-flex flex-fill" style={{ height: '0.5rem' }}>
                        <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group ">
                          <select className="form-control" name="hrf" value={this.state.hrf} onChange={this.onChange}>
                            {arr.map((item, index) =>
                              <option key={index}>{item}</option>
                            )}
                          </select>
                        </div> :
                        <div style={{ display: 'inline-block', width: 'max-content' }} svclassName="form-group">
                          <select className="form-control" name="minf" value={this.state.minf} onChange={this.onChange}>
                            {minute.map((item, index) =>
                              <option key={index}>{item}</option>
                            )}
                          </select>
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check d-inline">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="lundi" checked={this.state.lundi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="mardi" checked={this.state.mardi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="mercredi" checked={this.state.mercredi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="jeudi" checked={this.state.jeudi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="vendredi" checked={this.state.vendredi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="samedi" checked={this.state.samedi} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="dimanche" checked={this.state.dimanche} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" onChange={this.handleChecked} name="active" checked={this.state.active} />
                        </div>
                      </td>
                      <td style={{ height: '0.5rem' }}>
                        <button onClick={this.ajouterHoraire} className="btn mt-2 m-0 btn-primary">{utils.lbl[`${(this.state.lang)}.28`]}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* ***************************************** NEW LINE ********************************************/}
              <div className="container-fluid" style={{ display: 'grid' }}>
                <table className="table table-dark table-hover table-striped table-inverse table-responsive">
                  <thead>
                    {this.tableHoraireHeader()}
                  </thead>
                  {this.state.newHoraire.map((itemH) => (
                    <tbody>
                      <tr>
                        <td style={{ height: '0.5rem' }}>
                          <div className='d-flex flex-fill'>
                            <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group">
                              <select className="form-control" name="hrd" value={itemH.hrd}>
                                <option key={itemH.id}>{itemH.hrd}</option>
                              </select>
                            </div> :
                            <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group">
                              <select className="form-control" name="mind" value={itemH.mind}>
                                <option key={itemH.id}>{itemH.mind}</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td className='d-flex flex-fill' style={{ height: '0.5rem' }}>
                          <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group">
                            <select className="form-control" name="hrf" value={itemH.hrf}>
                              <option key={itemH.id}>{itemH.hrf}</option>
                            </select>
                          </div> :
                          <div style={{ display: 'inline-block', width: 'max-content' }} className="form-group">
                            <select className="form-control" name="minf" value={itemH.minf}>
                              <option key={itemH.id}>{itemH.minf}</option>
                            </select>
                          </div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check d-inline"><input type="checkbox" className="form-check-input" checked={itemH.lundi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.mardi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.mercredi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.jeudi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.vendredi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.samedi} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.dimanche} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div className="form-check"><input type="checkbox" className="form-check-input" checked={itemH.active} onChange={(e) => this.handleActiveHoraire(e, itemH)} /></div>
                        </td>
                        <td style={{ height: '0.5rem' }}>
                          <div>
                            <button onClick={() => this.handleDeleteHoraire(itemH.id)} className="btn btn-light mt-0 btn-sm" aria-pressed="true">{utils.lbl[`${(this.state.lang)}.29`]}</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-light" />
        {/* ------------------------------- PROPOSITION CGV ------------------------- */}
        <div className="container-fluid">
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                {utils.lbl[`${(this.state.lang)}.30`]}
              </span>
            </div>
          </div>
          <div className="form-group input-group">
            <textarea onChange={this.onChange} value={this.state.cgvContent} name="cgvContent" type="text" rows="7" placeholder="" className="form-control" />
          </div>
        </div>

      </>
    )
  }
}