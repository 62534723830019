export var lbl = [];

lbl["fr.0"] = "Nom";
lbl["fr.1"] = "Prénoms";
lbl["fr.2"] = "Date de naissance";
lbl["fr.3"] = "Mél";
lbl["fr.4"] = "Login=Mél";
lbl["fr.5"] = "Password";
lbl["fr.6"] = "Tél";
lbl["fr.7"] = "Adresse";
lbl["fr.8"] = "Ville";
lbl["fr.9"] = "Code Postal";
lbl["fr.10"] = "Langue préférée";
lbl["fr.11"] = "Profession";
lbl["fr.12"] = "Suivi des commandes par :";
lbl["fr.13"] = "Champ non valide";
lbl["fr.14"] = "Veuillez choisir";

/* AR */

lbl["ar.0"] = "الإسم";
lbl["ar.1"] = "اللقب";
lbl["ar.2"] = "تاريخ الازدياد";
lbl["ar.3"] = "بريد إلكتروني";
lbl["ar.4"] = "تعريف الدخول=بريد إلكتروني";
lbl["ar.5"] = "الكلمة السرية";
lbl["ar.6"] = "هاتف";
lbl["ar.7"] = "عنوان";
lbl["ar.8"] = "مدينة";
lbl["ar.9"] = "رمز بريدي";
lbl["ar.10"] = "اللغة المفضلة";
lbl["ar.11"] = "مهنة";
lbl["ar.12"] = "تتبع الطلبات ب :";
lbl["ar.13"] = "خطأ في المعلومة";
lbl["ar.14"] = "المرجو الاختيار";

/* EN */

lbl["en.0"] = "First name";
lbl["en.1"] = "Last name";
lbl["en.2"] = "Date of birth";
lbl["en.3"] = "Email";
lbl["en.4"] = "Login=Email";
lbl["en.5"] = "Password";
lbl["en.6"] = "Phone";
lbl["en.7"] = "Address";
lbl["en.8"] = "City";
lbl["en.9"] = "Code Postal";
lbl["en.10"] = "Prefered language";
lbl["en.11"] = "Profession";
lbl["en.12"] = "Order tracking by:";
lbl["en.13"] = "Invalid field";
lbl["en.14"] = "Please select";

/* DE */

lbl["de.0"] = "Vorname";
lbl["de.1"] = "Nachname";
lbl["de.2"] = "Geburtsdatum";
lbl["de.3"] = "Email";
lbl["de.4"] = "Login=Email";
lbl["de.5"] = "Password";
lbl["de.6"] = "Phone";
lbl["de.7"] = "Address";
lbl["de.8"] = "Stadt";
lbl["de.9"] = "Postleitzahl";
lbl["de.10"] = "Gewünschte Sprache";
lbl["de.11"] = "Beruf";
lbl["de.12"] = "Verfolgung von Aufträgen durch :";
lbl["de.13"] = "Invalid field";
lbl["de.14"] = "Please select";

