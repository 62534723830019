import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './register.css';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import RegisterImg from '../assets/register.png';
import CompteForm from './compteForm';
import * as utils from '../lang/compteCreation';
import * as comons from '../component/commun';
import * as cgu from '../lang/CGU';



class CompteCreation extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      mail: '',
      codeConfirm: '',
      ModalShow: false,
      ModalShow1: false,
      codemsg: '',
      id_personnel: '',
      redirection: false,
      token: '',
      //msgCode: '',
      dsButton: true,
      vs: false,
      checkCondition: false
    };
    this.state.info.lang = this.state.lang;
  }

  handleSubmit = e => {
    const form = this.formRef.current;

    e.preventDefault();

    //Désactivation du bouton
    this.setState({ dsButton: true });
    //Vérification de la saisie des données demandées
    if (form.state.id_titre_pers == '' || form.state.nom == '' || form.state.prenoms == '' || form.state.adress == '' ||
      form.state.id_ville == '' || form.state.cp == '' || form.state.id_profession == '' || form.state.datedenaissance == '' ||
      form.state.mail == '' || form.state.tel == '' || form.state.id_suivi == '' || form.state.id_lang == '' || 
      this.state.checkCondition == false || form.state.password == '') {
      toast.warning(utils.lbl[`${(this.state.lang)}.10`], {
        autoClose: 4000
      });
      this.setState({ dsButton: false });
    }
    else {
      const user = {
        id_titre_pers: form.state.id_titre_pers,
        nom: form.state.nom,
        prenoms: form.state.prenoms,
        adress: form.state.adress,
        id_ville: form.state.id_ville,
        cp: form.state.cp,
        // id_profession: form.state.id_profession,
        datedenaissance: form.state.datedenaissance,
        mail: form.state.mail,
        tel: form.state.tel,
        id_suivi: form.state.id_suivi,
        id_lang: form.state.id_lang,
        login: form.state.mail,
        password: form.state.password
      };

      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/create_personnel`, user, {
        withCredentials: true
      }).then(res => {
        if (res.data[0].codemsg === 0) {
          this.setState({
            ModalShow: true,
            //info: res.data[0],
            id_personnel: res.data[0].id_personnel,
            // token: res.data[0].token,
            msg: res.data[0].msg,
            id_titre_pers: '',
            nom: '',
            prenoms: '',
            adress: '',
            id_ville: '',
            cp: '',
            // id_profession: '',
            datedenaissance: '',
            tel: '',
            id_suivi: '',
            id_lang: ''
          });
        } else {
          toast.warning(res.data[0].msg, {
            autoClose: 4000
          });
          this.setState({ dsButton: false });
        }
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
        this.setState({ dsButton: false });
      })
    }
  }

  Modal = () => {
    this.setState({
      ModalShow: !this.state.ModalShow,
      dsButton: false
    });
  }

  Modal1 = () => {
    this.setState({
      ModalShow1: !this.state.ModalShow1
    });
  }

  codeConfirm = e => {
    const cody = {
      code: this.state.codeConfirm,
      idPersonnel: this.state.id_personnel
    };

    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/confirmation_create`, cody).then(res => {
      if (res.data[0].codemsg === 0) {
        this.setState({
          //msgCode: res.data[0].msg,
          info: res.data[0],
          id_personnel: res.data[0].id_personnel,
          token: res.data[0].token,
          ModalShow: false,
          ModalShow1: !this.state.ModalShow1,
          redirection: true,
          code: '',
          nom: '',
          prenoms: '',
          id_ville: '',
          datedenaissance: '',
          adress: '',
          cp: '',
          tel: '',
          mail: '',
          login: '',
          password: '',
          id_profession: '',
          id_suivi: '',
          id_lang: '',
          id_titre_pers: ''
        })
        disableBrowserBackButton();
      } else {
        toast.error(res.data[0].msg, {
          autoClose: 4000
        })
      }
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handlecheck = (e) => {
    this.setState({
      checkCondition: e.target.checked
    });
    if (e.target.checked === true) {
      this.setState({ dsButton: false });
    } else {
      this.setState({ dsButton: true });
    }
  }

  conditionsAccepted = () => {
    this.setState({
      vs: !this.state.vs,
      checkCondition: true,
      dsButton: false
    });
  }

  modalConditions = () => {
    if (!this.state.vs) {
      var cgucnt = "";
      for (let i = 2; i <= 20; i++) {
        cgucnt += cgu.lbl[`${(this.state.lang)}.${i}`] + "\n";
      }
    }
    this.setState({
      vs: !this.state.vs,
      cguContent: cgucnt 
    });
  }



  render() {
    const form = this.formRef.current;
    //console.log(this.state)
    //console.log(form)

    return (
      <>

        <div className="container register">
          <div className="row">
            <div className="col-md-3 register-left">
              <div className="d-flex flex-column justify-content-center">
                <div className="col">
                  <Link to={{
                          pathname: '/',
                          state: { info: this.state.info }
                        }} className='p-0'>
                    <FontAwesomeIcon icon={faHome} style={{ color: 'white', zoom: 2.5, marginTop: '2.5px', textAlign: 'center' }} />
                  </Link>
                </div>
                <div className="col">
                  <img src={RegisterImg} style={{ width: '6rem', height: '9rem' }} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-9 register-right">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="register" role="tabpanel" aria-labelledby="register-tab">
                  <h3 className="register-heading">{utils.lbl[`${(this.state.lang)}.0`]}</h3>
                  <div className="row register-form">
                    <CompteForm location={this.props.location} ref={this.formRef} />
                    <div className="container-sm">
                      <div className="row">
                        <div className="col custom-control custom-radio d-flex justify-content-row">
                          <label for="agreeConditions">
                            <input type="checkbox" id="agreeConditions" onChange={this.handlecheck} className="mr-2" checked={this.state.checkCondition} />
                            {utils.lbl[`${(this.state.lang)}.11`]}
                            <Link style={{ cursor: 'pointer', marginLeft: '15px' }} onClick={this.modalConditions}>{utils.lbl[`${(this.state.lang)}.12`]}</Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="register-bottom">
                      <input type="button" value={utils.lbl[`${(this.state.lang)}.6`]} onClick={this.handleSubmit} className="btn btn-primary"
                             disabled={this.state.dsButton} />
                      <Link to={{
                              pathname: '/',
                              state: { info: this.state.info }
                            }} type="button" className="btn btn-danger ml-3" >
                        {utils.lbl[`${(this.state.lang)}.5`]}
                      </Link>
                    </div>
                  </div>
                </div>
                {/*----------------------------------------- login ---------------------*/}
              </div>
            </div>
          </div>
        </div>
        {form != null ?
          <Modal visible={this.state.ModalShow} >
            <div className="modal-header">
              <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.1`]}</h5>
            </div>
            <div className="modal-body pl-5">
              <p>{utils.lbl[`${(this.state.lang)}.2`]}</p>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <br />
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                </div>
                <input type="text" name='codeConfirm' value={this.state.codeConfirm} onChange={this.handleChange} className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.4`]}</span>
                </div>
                <input type="email" value={form.state.mail} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
              </div>
              <hr />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.Modal}>
                {utils.lbl[`${(this.state.lang)}.5`]}
              </button>
              <button type="button" className="btn btn-primary" onClick={this.codeConfirm}>
                {utils.lbl[`${(this.state.lang)}.6`]}
              </button>
            </div>
          </Modal> : ''}

        <Modal visible={this.state.ModalShow1} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.1`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <p>{utils.lbl[`${(this.state.lang)}.7`]}</p>
          </div>
          <div className="modal-footer">
            <Link to={{
                    pathname: '/',
                    state: { info: this.state.info }
                  }} type="button" className="btn btn-primary">
              {utils.lbl[`${(this.state.lang)}.8`]}
            </Link>
          </div>
        </Modal>
        {/* Conditions Générales d'Utilisation */}
        <Modal visible={this.state.vs}>
          <div className="modal-header">
            <h5 className="modal-title">{cgu.lbl[`${(this.state.lang)}.0`]}</h5>
            <h7 className="modal-title">{cgu.lbl[`${(this.state.lang)}.1`]}</h7>
          </div>
          <div className="modal-body pl-5">
            <textarea type="text" name="cguContent" value={this.state.cguContent} className="form-control" placeholder="" rows="15" />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.conditionsAccepted}>
              {utils.lbl[`${(this.state.lang)}.13`]}
            </button>
            <button type="button" className="btn btn-primary" onClick={this.modalConditions}>
              {utils.lbl[`${(this.state.lang)}.5`]}
            </button>
          </div>
        </Modal>

      </>
    )
  }
}

export default withRouter(CompteCreation)