import axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap4-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowImage from '../component/showImage';
import MenuUser from '../component/menuUser';
import * as utils from '../lang/commandeConsultationClient';
import * as comons from '../component/commun';



class CommandeConsultationClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      datas: [],
      tb: 1,
      values: [],
      bo: false,
      modalShow: false,
      idCommande: ''
    };
  }

  tb = e => {
    this.setState({
      tb: e.target.value,
    });
    this.getValue();
  }

  modal = (e) => {
    //console.log(e)
    this.setState({
      modalShow: !this.state.modalShow,
      idCommande: e.target.id
    });
  }

  firstFunction = () => {
    return this.state.datas.map((item) => {
      return (<tr key={item.id_commande}>
        <td>{item.agence}</td>
        <td>{item.num_commande}</td>
        <td>{item.livraison === 1 ? 'Non' : 'Oui'}<br />{item.livraison === 1 ? '' : item.frais}</td>
        <td>{item.montant}</td>
        <td>
          <ul className='list item-list'>
            {item.panier.map((it) => (
              <li className='item'>{it.q}</li>
            ))}
          </ul>
        </td>
        <td>
          <ul className='list item-list'>
            {item.panier.map((it) => (
              <li className='item'>{it.annonce}</li>
            ))}
          </ul>
        </td>
        <td>
          {item.panier.map(it => (
            <div className='img-wrapper hover-zoom'>
              <ShowImage imgID={it.id_photo} imgStyle={{ width: '50px', height: '50px' }} />
            </div>
          ))}
        </td>
        <td>{item.opelbl}</td>
        <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date_operation))}</td>
        <td>{item.stalbl}</td>
        <td>
          {/* item.status === 'Terminé' && item.operation === 'Commande livrée' ? '' : '' */}
          {/* item.status === 'Terminé' && item.operation === 'Commande annulée' ? '' : '' */}
          {item.staid === 2 && item.opeid === 5 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
          {item.staid === 2 && item.opeid === 4 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
          {item.staid === 3 && item.opeid === 3 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
          {item.staid === 3 && item.opeid === 2 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
          {item.staid === 3 && item.opeid === 1 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
          {item.staid === 2 && item.opeid === 3 ?
            <button id={item.id_commande} className='btn btn-sm btn-primary mt-0' onClick={this.modal}>{utils.lbl[`${(this.state.lang)}.19`]}</button>
            : ''}
        </td>
      </tr>)
    });
  }

  handleAnnuler = () => {
    var dmdData = {
      idCommande: this.state.idCommande,
      numf: this.state.tb,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/client/traiter_commande`, dmdData).then(res => {
      this.setState({
        modalShow: false
      });
      this.getValue()
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getValue = () => { //TODO modification retour
    var dmdData = {
      numf: this.state.tb,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token,
      lng: this.state.lang
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/client/commandes`, dmdData).then(res => {
      this.setState({
        datas: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    this.getValue();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.tb != this.state.tb) {
      this.getValue();
    }
  }



  render() {
    //console.warn(this.state)
    const rendringTables = () => {
      return <this.firstFunction />;
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid">
          <div className="row">
            <div className='col'>
              <table className="table table-sm table-light table-hover table-striped table-inverse table-responsive">
                <thead>
                  <tr scope="colgroup" className="table-active">
                    <th colspan="11">
                      <div className="d-flex justify-content-center">
                        <div className="d-flex">
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="1" className={this.state.tb == 1 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.0`]}</button>
                          </div>
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="2" className={this.state.tb == 2 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.1`]}</button>
                          </div>
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="3" className={this.state.tb == 3 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.2`]}</button>
                          </div>
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="4" className={this.state.tb == 4 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.3`]}</button>
                          </div>
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="5" className={this.state.tb == 5 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.4`]}</button>
                          </div>
                          <div className="p-2 flex-fill">
                            <button onClick={this.tb} type="button" value="6" className={this.state.tb == 6 ? "btn btn-secondary btn-sm" : "btn btn-primary btn-sm"}>{utils.lbl[`${(this.state.lang)}.5`]}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.7`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.8`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.9`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.10`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.11`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.12`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.13`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.14`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.15`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.16`]}</th>
                  </tr>
                </thead>
                <tbody>
                  {rendringTables()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal visible={this.state.modalShow} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.17`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <p>{utils.lbl[`${(this.state.lang)}.18`]}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.modal}>
              {utils.lbl[`${(this.state.lang)}.19`]}</button>
            <button type="button" className="btn btn-primary"
              onClick={this.handleAnnuler}>{utils.lbl[`${(this.state.lang)}.20`]}</button>
          </div>
        </Modal>

      </>
    )
  }
}

export default withRouter(CommandeConsultationClient)