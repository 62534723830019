export var lbl = [];

lbl["fr.0"] = "Conditions générales de vente";
lbl["fr.1"] = "Clause n° 1 : Objet";
lbl["fr.2"] = "Les conditions générales de vente décrites ci-après détaillent les droits et obligations de la société ... (dénomination sociale) et de son client dans le cadre de la vente des marchandises présentées sur le site commerçant de la société. Toute prestation accomplie par la société ... (dénomination sociale) implique donc l'adhésion sans réserve de l'acheteur aux présentes conditions générales de vente.";
lbl["fr.3"] = "Clause n° 2 : Prix";
lbl["fr.4"] = "Les prix des marchandises vendues sont ceux en vigueur au jour de la prise de commande. Ils sont libellés dans la monnaies du pays et calculés TTC. Ils seront majorés des frais de transport applicables au jour de la commande s'il y en a. La société ... (dénomination sociale) s'accorde le droit de modifier ses tarifs à tout moment. Toutefois, elle s'engage à facturer les marchandises commandées aux prix indiqués lors de l'enregistrement de la commande.";
lbl["fr.5"] = "Clause n° 3 : Rabais et ristournes";
lbl["fr.6"] = "Les tarifs proposés comprennent les rabais et ristournes que la société ... (dénomination sociale) serait amenée à octroyer compte tenu de ses résultats ou de la prise en charge par l'acheteur de certaines prestations.";
lbl["fr.7"] = "Clause n° 4 : Escompte";
lbl["fr.8"] = "Aucun escompte ne sera consenti en cas de paiement anticipé.";
lbl["fr.9"] = "Clause n° 5 : Modalités de paiement";
lbl["fr.10"] = "Le règlement des commandes s'effectue soit par espèce, soit par chèque soit soit par carte bancaire. Le solde devant être payé à réception des marchandises.";
lbl["fr.11"] = "Clause n° 6 : Retard de paiement";
lbl["fr.12"] = "En cas de défaut de paiement total ou partiel des marchandises livrées au jour de la réception, la société ... (dénomination sociale) se réserve le droit de ne pas délivrer la commande.";
lbl["fr.13"] = "Clause n° 7 : Clause résolutoire";
lbl["fr.14"] = "Si dans les quinze jours qui suivent la mise en oeuvre de la clause ' Retard de paiement ', l'acheteur ne s'est pas acquitté des sommes restant dues, la vente sera résolue de plein droit et pourra ouvrir droit à l'allocation de dommages et intérêts au profit de la société ... (dénomination sociale).";
lbl["fr.15"] = "Clause n° 8 : Clause de réserve de propriété";
lbl["fr.16"] = "La société ... (dénomination sociale) conserve la propriété des biens vendus jusqu'au paiement intégral du prix, en principal et en accessoires. À ce titre, si l'acheteur fait l'objet d'un redressement ou d'une liquidation judiciaire, la société ... (dénomination sociale) se réserve le droit de revendiquer, dans le cadre de la procédure collective, les marchandises vendues et restées impayées.";
lbl["fr.17"] = "Clause n° 9 : Livraison";
lbl["fr.18"] = "La livraison est effectuée soit par la remise directe de la marchandise à l'acheteur, soit par l'envoi d'un avis de mise à disposition en magasin à l'attention de l'acheteur, soit au lieu indiqué par l'acheteur sur le bon de commande. Le délai de livraison indiqué lors de l'enregistrement de la commande n'est donné qu'à titre indicatif et n'est aucunement garanti. Par voie de conséquence, tout retard raisonnable dans la livraison des produits ne pourra pas donner lieu au profit de l'acheteur à l'allocation de dommages et intérêts ou à l'annulation de la commande. Le risque du transport est supporté en totalité par l'acheteur. En cas de marchandises manquantes ou détériorées lors du transport, l'acheteur devra formuler toutes les réserves nécessaires sur le bon de commande à réception desdites marchandises. Ces réserves devront être, en outre, confirmées par écrit dans les cinq jours suivant la livraison, par courrier recommandé AR.";
lbl["fr.19"] = "Clause n° 10 : Force majeure";
lbl["fr.20"] = "La responsabilité de la société ... (dénomination sociale) ne pourra pas être mise en oeuvre si la non-exécution ou le retard dans l'exécution de l'une de ses obligations décrites dans les présentes conditions générales de vente découle d'un cas de force majeure.";
lbl["fr.21"] = "Clause n° 11 : Tribunal compétent";
lbl["fr.22"] = "Tout litige relatif à l'interprétation et à l'exécution des présentes conditions générales de vente est soumis au droit du pays de la société vendeuse. À défaut de résolution amiable, le litige sera porté devant le Tribunal de commerce du lieu du siège social.";

/* AR */

lbl["ar.0"] = "الشروط العامة للبيع";
lbl["ar.1"] = "البند رقم 1: الغرض";
lbl["ar.2"] = "توضح الشروط العامة للبيع الموضحة أدناه بالتفصيل حقوق والتزامات الشركة ... (اسم الشركة) وعملائها في إطار بيع البضائع المعروضة على الموقع التجاري للشركة. أي خدمة تقدمها الشركة ... (اسم الشركة) تعني بالتالي قبول المشتري دون تحفظ لشروط البيع العامة هذه.";
lbl["ar.3"] = "البند رقم 2: السعر";
lbl["ar.4"] = "أسعار البضائع المباعة هي تلك السارية في يوم اتخاذ الأمر. وهي مقومة بعملات الدولة وتحسب شاملاً الضرائب. ستتم زيادتها من خلال تكاليف النقل المطبقة في يوم الطلب ، إن وجدت. تمنح الشركة ... (اسم الشركة) لنفسها الحق في تعديل أسعارها في أي وقت. ومع ذلك ، فإنه يتعهد بفاتورة البضائع المطلوبة بالأسعار الموضحة عند تسجيل الأمر";
lbl["ar.5"] = "البند رقم 3: الخصومات والتخفيضات";
lbl["ar.6"] = "تشمل الأسعار المقترحة الخصومات والتخفيضات التي ستكون الشركة ... (اسم الشركة) مطلوبة لمنحها في ضوء نتائجها أو افتراض المشتري لخدمات معينة.";
lbl["ar.7"] = "البند رقم 4: الخصم";
lbl["ar.8"] = "لن يتم منح أي خصم في حالة السداد المبكر";
lbl["ar.9"] = "البند رقم 5: شروط الدفع";
lbl["ar.10"] = "يتم سداد الطلبات إما نقدًا أو بشيك أو ببطاقة ائتمان. يتم دفع الرصيد المتبقي عند استلام البضاعة";
lbl["ar.11"] = "البند 6: التأخر في السداد";
lbl["ar.12"] = "في حالة عدم السداد الكلي أو الجزئي للبضائع المسلمة في يوم الاستلام ، تحتفظ الشركة ... (اسم الشركة) بالحق في عدم تسليم الطلب";
lbl["ar.13"] = "البند رقم 7: شرط الإنهاء";
lbl["ar.14"] = "إذا لم يسدد المشتري المبالغ المتبقية المستحقة خلال الخمسة عشر يومًا التالية لتنفيذ شرط (السداد المتأخر) ، فسيتم إنهاء البيع تلقائيًا وقد يؤدي إلى الحق في تخصيص تعويضات لصالح الشركة ... (اسم الشركة)";
lbl["ar.15"] = "البند رقم 8: الاحتفاظ بحق الملكية";
lbl["ar.16"] = "تحتفظ الشركة ... (اسم الشركة) بملكية البضائع المباعة حتى سداد الثمن بالكامل ، من حيث الأصل وفي الملحقات. على هذا النحو ، إذا كان المشتري خاضعًا لإعادة التنظيم أو التصفية القضائية ، تحتفظ الشركة ... (اسم الشركة) بالحق في المطالبة ، في إطار الإجراء الجماعي ، بالبضائع المباعة. وبقيت غير مدفوعة الأجر.";
lbl["ar.17"] = "البند رقم 9: التسليم";
lbl["ar.18"] = "يتم التسليم إما عن طريق التسليم المباشر للبضائع إلى المشتري ، أو عن طريق إرسال إشعار بالتوافر في المتجر لعناية المشتري ،، إما عن طريق إرسال إشعار بالتوافر في المتجر لعناية المشتري ، أو في المكان الذي يشير إليه المشتري في نموذج الطلب. وقت التسليم المُشار إليه أثناء تسجيل الطلب يُعطى فقط كمؤشر ولا يتم ضمانه بأي حال من الأحوال. وبالتالي ، فإن أي تأخير معقول في تسليم المنتجات لن يكون قادرًا على تحقيق ربح للمشتري لتخصيص الأضرار أو إلى إلغاء الطلب. يتحمل المشتري مخاطر النقل بالكامل. في حالة فقدان البضائع أو تلفها أثناء النقل ، يجب على المشتري إجراء جميع التحفظات اللازمة على نموذج الطلب عند استلام البضائع المذكورة. يجب أيضًا تأكيد هذه الحجوزات كتابيًا في غضون خمسة أيام من التسليم ، عن طريق البريد المسجل.";
lbl["ar.19"] = "البند رقم 10: القوة القاهرة";
lbl["ar.20"] = "لا يمكن تنفيذ مسؤولية الشركة (اسم الشركة) إذا كان عدم الأداء أو التأخير في أداء أحد التزاماتها الموصوفة في هذه الشروط العامة للبيع ناتجًا عن حالة إجبارية كبرى";
lbl["ar.21"] = "البند 11: المحكمة المختصة";
lbl["ar.22"] = "يخضع أي نزاع يتعلق بتفسير وتنفيذ هذه الشروط العامة للبيع لقانون بلد الشركة المباعة. في حالة عدم وجود حل ودي ، يتم عرض النزاع على المحكمة التجارية في مكان المكتب المسجل";

/* EN */

lbl["en.0"] = "General Conditions of Sale";
lbl["en.1"] = "Clause n° 1 : Objet";
lbl["en.2"] = "The general conditions of sale described below detail the rights and obligations of the company ... (company name) and of its customer within the framework of the sale of the goods presented on the commercial site of the company. Any service provided by the company ... (company name) therefore implies the buyer's unreserved acceptance of these general conditions of sale.";
lbl["en.3"] = "Clause n ° 2: Price";
lbl["en.4"] = "The prices of the goods sold are those in effect on the day the order is taken. They are denominated in the currencies of the country and calculated inclusive of tax. They will be increased by the transport costs applicable on the day of the order, if any. The company ... (corporate name) grants itself the right to modify its prices at any time. However, it undertakes to invoice the goods ordered at the prices indicated when the order is registered.";
lbl["en.5"] = "Clause n ° 3: Discounts and rebates";
lbl["en.6"] = "The proposed prices include the discounts and rebates that the company ... (corporate name) would be required to grant given its results or the assumption by the buyer of certain services.";
lbl["en.7"] = "Clause n ° 4: Discount";
lbl["en.8"] = "No discount will be granted in the event of early payment.";
lbl["en.9"] = "Clause n ° 5: Terms of payment";
lbl["en.10"] = "The payment of orders is made either by cash, or by check or by credit card. The balance to be paid upon receipt of the goods.";
lbl["en.11"] = "Clause n ° 6: Late payment";
lbl["en.12"] = "In the event of total or partial non-payment of the goods delivered on the day of receipt, the company ... (corporate name) reserves the right not to deliver the order.";
lbl["en.13"] = "Clause n ° 7: Termination clause";
lbl["en.14"] = "If within the fifteen days following the implementation of the 'Late payment' clause, the buyer has not paid the sums remaining due, the sale will be automatically terminated and may give rise to the right to the allocation of damages for the benefit of the company ... (company name).";
lbl["en.15"] = "Clause n ° 8: Retention of title clause";
lbl["en.16"] = "The company ... (corporate name) retains ownership of the goods sold until full payment of the price, in principal and in accessories. As such, if the buyer is the subject of reorganization or judicial liquidation, the company ... (corporate name) reserves the right to claim, within the framework of the collective procedure, the goods sold. and remained unpaid.";
lbl["en.17"] = "Clause n ° 9: Delivery";
lbl["en.18"] = "Delivery is made either by direct delivery of the goods to the buyer, or by sending a notice of availability in store for the attention of the buyer, or at the place indicated by the buyer on purchase order. The delivery time indicated during the registration of the order is given only as an indication and is in no way guaranteed. Consequently, any reasonable delay in the delivery of the products will not be able to give rise to the profit of the purchaser to the allocation of damages or to the cancellation of the order. The risk of transport is borne in full by the buyer. In the event of missing or damaged goods during transport, the buyer must make all the necessary reservations on the order form upon receipt of said goods. These reservations must also be confirmed in writing within five days of delivery, by registered post.";
lbl["en.19"] = "Clause n ° 10: Force majeure";
lbl["en.20"] = "The responsibility of the company ... (company name) cannot be implemented if the non-performance or the delay in the performance of one of its obligations described in these general conditions of sale results from a force majeure.";
lbl["en.21"] = "Clause n ° 11: Competent court";
lbl["en.22"] = "Any dispute relating to the interpretation and execution of these general conditions of sale is subject to the law of the country of the selling company. In the absence of an amicable resolution, the dispute will be brought before the Commercial Court of the place of the registered office.";

/* DE */

lbl["de.0"] = "Allgemeine Verkaufsbedingungen";
lbl["de.1"] = "Klausel 1: Zweck";
lbl["de.2"] = "In den nachstehend beschriebenen allgemeinen Verkaufsbedingungen sind die Rechte und Pflichten des Unternehmens ... (Firmenname) und seines Kunden im Rahmen des Verkaufs der auf dem Handelsgelände des Unternehmens präsentierten Waren aufgeführt. Jede von der Firma erbrachte Dienstleistung ... (Firmenname) impliziert daher die vorbehaltlose Annahme dieser allgemeinen Verkaufsbedingungen durch den Käufer.";
lbl["de.3"] = "Klausel Nr. 2: Preis";
lbl["de.4"] = "Die Preise der verkauften Waren gelten am Tag der Bestellung. Sie lauten auf die Währungen des Landes und werden einschließlich Steuern berechnet. Sie werden gegebenenfalls um die am Tag der Bestellung anfallenden Transportkosten erhöht. Das Unternehmen ... (Firmenname) gewährt sich das Recht, seine Preise jederzeit zu ändern. Es verpflichtet sich jedoch, die bestellte Ware zu den bei der Registrierung der Bestellung angegebenen Preisen in Rechnung zu stellen.";
lbl["de.5"] = "Klausel Nr. 3: Rabatte und Rabatte";
lbl["de.6"] = "Die vorgeschlagenen Preise enthalten die Rabatte und Rabatte, die das Unternehmen (Firmenname) aufgrund seiner Ergebnisse oder der Annahme bestimmter Dienstleistungen durch den Käufer gewähren müsste.";
lbl["de.7"] = "Klausel Nr. 4: Rabatt";
lbl["de.8"] = "Bei vorzeitiger Zahlung wird kein Rabatt gewährt.";
lbl["de.9"] = "Ziffer Nr. 5: Zahlungsbedingungen";
lbl["de.10"] = "Die Zahlung der Bestellungen erfolgt entweder in bar, per Scheck oder per Kreditkarte. Der Restbetrag ist nach Erhalt der Ware zu zahlen.";
lbl["de.11"] = "Klausel Nr. 6: Zahlungsverzug";
lbl["de.12"] = "Bei vollständiger oder teilweiser Nichtzahlung der am Tag des Eingangs gelieferten Ware behält sich das Unternehmen ... (Firmenname) das Recht vor, die Bestellung nicht zu liefern.";
lbl["de.13"] = "Klausel Nr. 7: Kündigungsklausel";
lbl["de.14"] = "Wenn der Käufer innerhalb von fünfzehn Tagen nach Umsetzung der Klausel über verspätete Zahlung die verbleibenden fälligen Beträge nicht bezahlt hat, wird der Verkauf automatisch beendet und kann das Recht auf Schadensersatz zugunsten des Unternehmens begründen ... (Name der Firma).";
lbl["de.15"] = "Klausel Nr. 8: Beibehaltung der Titelklausel";
lbl["de.16"] = "Das Unternehmen ... (Firmenname) behält sich das Eigentum an den verkauften Waren bis zur vollständigen Bezahlung des Preises vor, sowohl im Auftrag als auch im Zubehör. Wenn der Käufer Gegenstand einer Umstrukturierung oder gerichtlichen Liquidation ist, behält sich das Unternehmen (Firmenname) das Recht vor, die verkauften Waren im Rahmen des Sammelverfahrens zu beanspruchen. und blieb unbezahlt.";
lbl["de.17"] = "Klausel Nr. 9: Lieferung";
lbl["de.18"] = "Die Lieferung erfolgt entweder durch direkte Lieferung der Ware an den Käufer oder durch Versenden einer Verfügbarkeitsmitteilung im Geschäft an den Käufer oder an den vom Käufer auf der Bestellung angegebenen Ort. Die bei der Registrierung der Bestellung angegebene Lieferzeit dient nur als Angabe und ist in keiner Weise garantiert. Infolgedessen kann eine angemessene Verzögerung bei der Lieferung der Produkte nicht zum Gewinn des Käufers bei der Aufteilung des Schadens oder bei der Stornierung der Bestellung führen. Das Transportrisiko trägt der Käufer in vollem Umfang. Bei fehlenden oder beschädigten Waren während des Transports muss der Käufer nach Erhalt der Waren alle erforderlichen Reservierungen auf dem Bestellformular vornehmen. Diese Reservierungen müssen auch schriftlich innerhalb von fünf Tagen nach Lieferung per Einschreiben bestätigt werden.";
lbl["de.19"] = "Klausel Nr. 10: Höhere Gewalt";
lbl["de.20"] = "Die Verantwortung des Unternehmens ... (Firmenname) kann nicht umgesetzt werden, wenn die Nichterfüllung oder Verzögerung der Erfüllung einer seiner in diesen allgemeinen Verkaufsbedingungen beschriebenen Verpflichtungen auf höherer Gewalt beruht.";
lbl["de.21"] = "Klausel Nr. 11: Zuständiges Gericht";
lbl["de.22"] = "Alle Streitigkeiten im Zusammenhang mit der Auslegung und Ausführung dieser allgemeinen Verkaufsbedingungen unterliegen dem Recht des Landes der verkaufenden Gesellschaft. In Ermangelung einer gütlichen Beilegung wird die Streitigkeit vor das Handelsgericht am Sitz des Sitzes gebracht.";

