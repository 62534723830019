import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap4-modal';
import './register.css';
import axios from 'axios';
import { isEmail } from "validator";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loginImg from '../assets/login-icon-3047.png';
import * as utils from '../lang/login';
import * as comons from '../component/commun';



class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    //const token = localStorage.getItem("token");
    //let logedIn = (token === null ? false : true);

    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      login: '',
      pass: '',
      logedIn: false,
      CurrentUserClient: '',
      isLoged: true,
      loginIn: false,
      showChoice: false,
      token: '',
      forgotPass: false,
      verfmail: ''
    };
    this.state.info.lang = this.state.lang;
  }

  required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {utils.lbl[`${(this.state.lang)}.6`]}
        </div>
      );
    }
  };

  email = value => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {utils.lbl[`${(this.state.lang)}.7`]}
        </div>
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this._isMounted = true
    const { login, pass, lang } = this.state
    if (this._isMounted) {
      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/authenticate`, { login, pass, lang },
        {
          headers: { 'Content-Type': 'application/json' }
        },
        { withCredentials: true },
      ).then((res) => {
        //console.log(response)
        if (res.data.codemsg === 0) {
          this.setState({
            info: res.data,
            token: res.data.token,
            logedIn: true
          });
          localStorage.setItem("token", res.data.token);
          return res.data;
        } 
        else {
          toast.error(res.data.msg, {
            autoClose: 4000
          });
        }
      }).catch(err => {
        toast.error(((typeof err.response != 'undefined' && typeof err.response.data != 'undefined' && typeof err.response.data.error != 'undefined') ? err.response.data.error : err.message), {
          autoClose: 4000
        });
      });
    }
  }

  forgotPass = () => {
    this.setState({ forgotPass: !this.state.forgotPass });
  }

  dismiss = () => {
    this.setState({ forgotPass: !this.state.forgotPass });
  }

  sendPass = () => {
    const { verfmail, lang } = this.state
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/forgot_pass`, { verfmail, lang }).then(res => {
      console.log(res.data)
      if (res.data[0].codemsg === 0) {
        // execution de script
        this.dismiss();
        toast.success(res.data[0].msg, {
          autoClose: 4000
        })
      } else if (res.data[0].codemsg === 1) {
        toast.error(res.data[0].msg, {
          autoClose: 4000
        })
      }
    }).catch(err => {
      toast.error(err.response.error, {
        autoClose: 4000
      })
    })
  }



  render() {
    let { email, pass, info, CurrentUserClient, token, logedIn } = this.state;

    // console.log(this.state.verfmail)
    //console.warn(info.profil)
    //console.warn('loged : ' + logedIn)

    //  const { profil } = CurrentUser.comptes[0].profil

    if (logedIn === true && token != null) {
      if (info.comptes.length == 1) {
        if (info.comptes[0].profil === 'Client') {
          return (
            <div>
              <Redirect to={{
                pathname: '/',
                state: { info: info }
              }} />
              {/* <UserIndex  token={token}/>  */}
            </div>
          )
        } else if (info.comptes[0].profil === 'Admin') {
          return (
            <div>
              <Redirect to={{
                pathname: '/magasin-index/' + info.comptes[0].id_agence,
                state: { info: info }
              }} />
            </div>
          )
        } else if (info.comptes[0].profil === 'Root') {
          return (
            <div>
              <Redirect to={{
                pathname: '/admin-index',
                state: { info: info }
              }} />
            </div>
          )
        }
      } else {
        for (let i = 0; i < info.comptes.length; i++) {
          if (info.comptes[i].profil === 'Client') {
            CurrentUserClient = {
              id_personnel: info.id_personnel,
              nom: info.nom,
              token: info.token,
              comptes: []
            };
            CurrentUserClient.comptes[0] = info.comptes[i];
            info.comptes.splice(i, 1);
            this.state.showChoice = true;
            break;
          }
        }
        if (!this.state.showChoice) {
          return (
            <div>
              <Redirect to={{
                pathname: `/magasin-index/${info.comptes[0].id_agence}`,
                state: { info: info }
              }} />
            </div>
          )
        } else {
          return (
            <Modal visible={this.state.showChoice} >
              <div className="modal-header">
                <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.1`]}</h5>
              </div>
              <div className="modal-body pl-5">
                <p>{utils.lbl[`${(this.state.lang)}.2`]}</p>
              </div>
              <div className="modal-footer">
                <Link to={{
                  pathname: '/',
                  state: { info: CurrentUserClient }
                }} type="button" className="btn btn-primary">
                  {utils.lbl[`${(this.state.lang)}.3`]}
                </Link> {utils.lbl[`${(this.state.lang)}.4`]}
                <Link to={{
                  pathname: `/magasin-index/${info.comptes[0].id_agence}`,
                  state: { info: info }
                }} type="button" className="btn btn-primary">
                  {utils.lbl[`${(this.state.lang)}.5`]}
                </Link>
              </div>
            </Modal>
          )
        }
      }
    } else {
      toast.warning(this.state.info.msg, {
        position: "top-right",
        autoClose: 3000,
      });
    }

    return (
      <>

        <div className="container register">
          <div className="row">
            <div className="col-md-3 register-left">
              <div className="d-flex flex-column justify-content-center">
                <div className="col">
                  <Link to={{
                          pathname: '/',
                          state: { info: info }
                        }} className='p-0'>
                    <FontAwesomeIcon icon={faHome} style={{ color: 'white', zoom: 2.5, marginTop: '2.5px', textAlign: 'center' }} />
                  </Link>
                </div>
                <div className="col">
                  <img src={loginImg} alt="" style={{ width: '6rem', height: '9rem', textAlign: 'center' }} />
                </div>
              </div>
            </div>
            <div className="col-md-9 register-right">
              <div className="tab-pane fade show">
                <form onSubmit={this.handleSubmit} ref={this.form}>
                  <div className="row register-form" >
                    <div className="col">
                      <div className="form-group">
                        <input style={{ width: '100%' }} type="email" className="form-control" placeholder="Login *"
                          validations={[this.required, this.email]} name="login" onChange={this.handleChange} value={this.state.login} />
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control" name="pass" onChange={this.handleChange}
                          validations={[this.required]} placeholder="Password *" value={this.state.pass} />
                      </div>
                      <div className="form-group">
                        <button className="btnRegister" disabled={email == '' || pass == ''}>
                          <span>{utils.lbl[`${(this.state.lang)}.0`]}</span>
                        </button>
                      </div>
                      {/* <input type="submit" disabled={loading} class="btnRegister" onClick={this.handleLogin} value="Login" /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <a onClick={this.forgotPass} className='d-flex flex-end justify-content-end' style={{ color: 'white', cursor: 'pointer' }}>
            {utils.lbl[`${(this.state.lang)}.12`]}
          </a>
        </div>

        <Modal visible={this.state.forgotPass} >
          <div className="modal-header">
            <h5 className="modal-title"> {utils.lbl[`${(this.state.lang)}.8`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.9`]}</span>
              </div>
              <input type='email' name='verfmail' className='form-control' onChange={this.handleChange} />
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={this.dismiss} type="button" className="btn btn-secondary">
              {utils.lbl[`${(this.state.lang)}.10`]}
            </button>
            <button onClick={this.sendPass} type="button" className="btn btn-primary">
              {utils.lbl[`${(this.state.lang)}.11`]}
            </button>
          </div>
        </Modal>

      </>
    )
  }
}

export default withRouter(Login);