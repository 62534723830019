export var lbl = [];

lbl["fr.0"] = "PU : ";
lbl["fr.1"] = "x";
lbl["fr.2"] = "Montant total : ";
lbl["fr.3"] = "Nom : ";
lbl["fr.4"] = "Mél : ";
lbl["fr.5"] = "Tél : ";
lbl["fr.6"] = "Frais de livraison inclus : ";
lbl["fr.7"] = "Adresse : ";
lbl["fr.8"] = "Ville : ";
lbl["fr.9"] = "Pays : ";
lbl["fr.10"] = "Date";
lbl["fr.11"] = "Opération";
lbl["fr.12"] = "Statut";
lbl["fr.13"] = "Valider La commande";
lbl["fr.14"] = "Annuler La commande";
lbl["fr.15"] = "A préparer";
lbl["fr.16"] = "A livrer";
lbl["fr.17"] = "Refuser La commande";
lbl["fr.18"] = "> Montre Menu Magasin";
lbl["fr.19"] = "v Cache Menu Magasin";
lbl["fr.20"] = "Retour";

/* AR */

lbl["ar.0"] = "ثمن : ";
lbl["ar.1"] = "x";
lbl["ar.2"] = "حاصل إجمالي : ";
lbl["ar.3"] = "اسم : ";
lbl["ar.4"] = "بريد إلكتروني : ";
lbl["ar.5"] = "هاتف : ";
lbl["ar.6"] = "تكاليف التوصيل مدرجة : ";
lbl["ar.7"] = "عنوان : ";
lbl["ar.8"] = "مدينة : ";
lbl["ar.9"] = "بلد : ";
lbl["ar.10"] = "تاريخ";
lbl["ar.11"] = "عملية";
lbl["ar.12"] = "وضعية";
lbl["ar.13"] = "تأكيد";
lbl["ar.14"] = "إلغاء";
lbl["ar.15"] = "للتحضير";
lbl["ar.16"] = "للتوصيل";
lbl["ar.17"] = "رفض";
lbl["ar.18"] = "أظهر قائمة المحل <";
lbl["ar.19"] = "اخف قائمة المحل v";
lbl["ar.20"] = "العودة";

/* EN */

lbl["en.0"] = "UP : ";
lbl["en.1"] = "x";
lbl["en.2"] = "Total amount : ";
lbl["en.3"] = "Name : ";
lbl["en.4"] = "Email : ";
lbl["en.5"] = "Phone : ";
lbl["en.6"] = "Shipping cost included : ";
lbl["en.7"] = "Address : ";
lbl["en.8"] = "City : ";
lbl["en.9"] = "Country : ";
lbl["en.10"] = "Date";
lbl["en.11"] = "Operation";
lbl["en.12"] = "Status";
lbl["en.13"] = "Validate";
lbl["en.14"] = "Cancel";
lbl["en.15"] = "To prepare";
lbl["en.16"] = "To deliver";
lbl["en.17"] = "Refuse";
lbl["en.18"] = "> Show Shop Menu";
lbl["en.19"] = "v Hide Shop Menu";
lbl["en.20"] = "Go back";

/* DE */

lbl["de.0"] = "UP : ";
lbl["de.1"] = "x";
lbl["de.2"] = "Menge : ";
lbl["de.3"] = "Name : ";
lbl["de.4"] = "Email : ";
lbl["de.5"] = "Telefon : ";
lbl["de.6"] = "Versandkosten : ";
lbl["de.7"] = "Addresse : ";
lbl["de.8"] = "Stadt : ";
lbl["de.9"] = "Land : ";
lbl["de.10"] = "Datiert";
lbl["de.11"] = "Betrieb";
lbl["de.12"] = "Status";
lbl["de.13"] = "Bestätigen";
lbl["de.14"] = "Abbrechen";
lbl["de.15"] = "Wird Vorbereitung";
lbl["de.16"] = "Wird geliefer";
lbl["de.17"] = "Abgelehnt";
lbl["de.18"] = "> Showen Agence Menu";
lbl["de.19"] = "v Hidden Agence Menu";
lbl["de.20"] = "Go back";

