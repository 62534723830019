import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as comons from '../component/commun';



class ShowImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgID: this.props.imgID,
      imgStyle: this.props.imgStyle,
      imgBuffer: ''
    };
  }

  getImage = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/photo/${this.state.imgID}`).then(res => {
      this.setState({
        imgBuffer: res.data
      });
    });
  }

  componentDidMount() {
    this.getImage();
  }



  render() {
    return (
      <>

        <img src={this.state.imgBuffer} style={this.state.imgStyle} alt='' />

      </>
    )
  }
}

export default withRouter(ShowImage)