import React, { Component } from 'react';
import './vitine.css';
import Search from '../component/Search';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PushSpinner } from 'react-spinners-kit';
import MenuUser from '../component/menuUser';
import CVAgence from '../component/cvAgence';
import VitrinePanier from '../component/vitrinePanier';
import * as utils from '../lang/vitrineConsultation';
import * as comons from '../component/commun';



class VitrineConsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading1: true, isLoading2: true, isLoading3: true, isLoading4: true, //Pour patienter le temps du chargement
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'en'),
      idAgence: this.props.match.params.idAgence,

      product: [],
      clientelle: [],
      typeChekClientell: [],
      articleCat: [],
      typeChekCategorie: [],
      color: '#ffffff',
      devise: '...',
      search: '',
      panier: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.panier != 'undefined') && (this.props.location.state.panier != '') ? this.props.location.state.panier : []),
      panier_copy: [],
      showVitrine: false,
      modalShowPaAc: 0
    };
    this.state.info.lang = this.state.lang;
  }

  getCVAgence = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cv_agence/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.props.location.dataAgence = res.data;
      for (let i = 0; i < res.data.droits.length; i++) {
        if (res.data.droits[i].idm == 2) {
          this.setState({
            showVitrine: res.data.droits[i].acces,
            color: res.data.couleur,
            devise: res.data.devise,
            isLoading4: false //Informer de la fin du chargement
          });
          break;
        }
      }
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  handleClientelle = e => {
    // current array of options
    const typeChekClientell = this.state.typeChekClientell
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      typeChekClientell.push(+e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = typeChekClientell.indexOf(+e.target.value)
      typeChekClientell.splice(index, 1)
    }

    this.setState({
      typeChekClientell
    });
  }

  handleCategorie = e => {
    // current array of options
    const typeChekCategorie = this.state.typeChekCategorie
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      typeChekCategorie.push(+e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = typeChekCategorie.indexOf(+e.target.value)
      typeChekCategorie.splice(index, 1)
    }

    this.setState({
      typeChekCategorie
    });
  }

  getClientelle = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/clientelle/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({
        clientelle: res.data,
        isLoading3: false //Informer de la fin du chargement
      });
      this.state.clientelle.forEach(element => {
        this.state.typeChekClientell.push(element.id);
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getArticleCat = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/articlecat/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({
        articleCat: res.data,
        isLoading2: false //Informer de la fin du chargement
      });
      this.state.articleCat.forEach(element => {
        this.state.typeChekCategorie.push(element.id);
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getArticles = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/articles/client/${this.state.idAgence}/1/20`).then(res => {
      this.setState({
        product: res.data,
        isLoading1: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  getDataToShow = () => {
    this.getClientelle();
    this.getArticleCat();
  }

  componentDidMount = () => {
    this.getCVAgence();
    this.getDataToShow();
    this.getArticles();
  }

  componentDidUpdate = () => {
  }

  handleChange = e => {
    this.setState({
      search: e.target.value
    });
  }

  allArticles = () => {
    this.state.resultIDs = [];
    return (this.state.product.map((article) => {
      //this.state.product.filter(article => article.Annonce.toLocaleLowerCase().includes(this.state.search)).map((article) => {
      const { idArticle, img, annonce, quantite, vente, prixPromo, dateDebutPromo, dateFinPromo, idCategorie, idClientelle } = article;

      let clientArticleOk = false;
      this.state.typeChekClientell.forEach(el1 => {
        if (idClientelle.indexOf(el1) !== -1) {
          clientArticleOk = true;
        }
      });
      if (clientArticleOk && this.state.typeChekCategorie.indexOf(idCategorie) !== -1 && annonce.includes(this.state.search)) {
        this.state.resultIDs.push(article.idArticle);
        return (
          <div key={idArticle} className="col-10 mx-auto col-md-6 col-lg-2 my-3">
          {/* <div key={idArticle} className="max-auto col-lg-4 col-md-6 col-sm-12 col-xs-12 my-3"> */}
            <div className="card border border-info mb-3 p-0" style={{ width: "100%", height: "100%" }}>
              <Link to={{
                pathname: `/vitrine-detail/${this.state.idAgence}/${idArticle}`,
                state: { info: this.state.info, panier: this.state.panier },
                dataAgence: this.props.location.dataAgence,
                resultIDs: this.state.resultIDs
              }} type="button" className="btn btn-sm mt-0">
                <img style={{ width: "14 rem", height: "12 rem" }} src={img} className="card-img-top m-0" alt="..." />
                <div className="card-footer text-muted text-capitalize p-0">
                  <div className='d-flex d-row justify-content-around'>
                    <h6 style={{ color: 'black' }} className="card-title mt-2">{annonce}</h6>
                    <h6 style={{ color: 'black' }} className="p-0 bd-highlight mt-2">{utils.lbl[`${(this.state.lang)}.2`]}<b>{quantite}</b></h6>
                  </div>
                  <ul className="list-group list-group-flush p-0 ">
                    <li className="list-group-item d-flex d-row justify-content-around">
                      <h6 style={{ color: 'black' }}>{utils.lbl[`${(this.state.lang)}.3`]}<b>{vente}</b> {this.state.devise}</h6>
                    </li>
                    {prixPromo != null || prixPromo != undefined ?
                      <>
                        <li className="list-group-item p-0 m-0">
                          <h5 style={{ color: 'green' }}>{utils.lbl[`${(this.state.lang)}.4`]}<b>{prixPromo}</b> {this.state.devise}</h5>
                          <h6 style={{ color: 'green' }}>{utils.lbl[`${(this.state.lang)}.5`]} <b>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dateDebutPromo))}</b> {utils.lbl[`${(this.state.lang)}.6`]} <b>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dateFinPromo))}</b></h6>
                        </li>
                      </>
                      : ''}
                  </ul>
                </div>
              </Link>
            </div>
          </div>
        )
      }
    }));
  }

  showBasket = () => {
    this.state.panier_copy = [];
    this.state.panier.forEach(el => {
      var cmdc = {
        id_article: el.id_article,
        annonce: el.annonce,
        foto: el.foto,
        quantite: el.quantite,
        idLiv: el.idLiv,
        frLiv: el.frLiv,
        prix: el.prix
      };
      this.state.panier_copy.push(cmdc);
    });
    this.setState({
      modalShowPaAc: 2
    });
  }

  changeLanguage = (lang) => {
    this.setState({ lang });
    this.state.lang = lang;
    this.state.info.lang = lang;
    this.getDataToShow();
  }

  changePanier = (panier) => {
    this.setState({ panier });
    //this.state.info.lang = lang;
  }

  changeModal = (modalShowPaAc) => {
    this.setState({ modalShowPaAc });
    //this.state.info.lang = lang;
  }



  render() {
    //console.warn(this.state.product)
    //console.warn(this.state)
    //console.warn(this.props)

    const { isLoading1, isLoading2, isLoading3, isLoading4, clientelle, articleCat, idAgence } = this.state

    if (idAgence !== this.props.match.params.idAgence) {
      this.setState({
        idAgence: this.props.match.params.idAgence
      });
    }

    if (isLoading1 || isLoading2 || isLoading3 || isLoading4) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading1 || isLoading2 || isLoading3 || isLoading4} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} changeLanguage={this.changeLanguage.bind(this)} lang={this.state.lang} />
        {this.state.showVitrine ?
          <>
            <div className='container-fluid' style={{ backgroundColor: this.state.color, color: (this.state.color > "#888888" ? "#000000" : "#ffffff") }}>
              <div className='row d-flex justify-content-center'>
                <CVAgence location={this.props.location} />
                {this.state.panier.length > 0 ?
                  <div style={{ marginRight: '10vh', marginTop: '2vh', cursor: 'pointer' }}>
                    <FontAwesomeIcon style={{ width: '5rem', height: '5rem' }} onClick={this.showBasket} icon={faCartPlus} />
                    <span class="badge badge-light" style={{ width: '2rem', height: '2rem' }}><div className='h5'>{this.state.panier.length}</div></span>
                  </div>
                  : ''}
              </div>
              <hr className="my-3" />
              <div className='row'>
                <div className='col-lg-9 col-md-9 col-sm-6 mb-3'>
                  {/*********************** Affichage de catégorie article ***********************/}
                  <div className="card" style={{ backgroundColor: 'transparent' }}>
                    <div className="card-header">
                      <h5 className="modal-title d-flex justify-content-center">{utils.lbl[`${(this.state.lang)}.0`]}</h5>
                    </div>
                    <div className="card-body text-capitalize">
                      <div className="row align-self-stretch">
                        {articleCat.map(item => (
                          <label className="container2 ml-2">
                            <input type="checkbox" key={item.id} value={item.id} checked={this.state.typeChekCategorie.find((ch) => ch === item.id)}
                                   onChange={this.handleCategorie} /><h6>{item.lbl}</h6>
                            <span className="checkmark"></span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-6'>
                  {/*********************** Affichage de la clientelle ***********************/}
                  <div className="card" style={{ backgroundColor: 'transparent' }}>
                    <div className="card-header">
                      <h5 className="modal-title d-flex justify-content-center">{utils.lbl[`${(this.state.lang)}.1`]}</h5>
                    </div>
                    <div className="card-body text-capitalize">
                      <div className="row align-self-stretch">
                        {clientelle.map(item => (
                          <label className="container2 ml-2">
                            <input type="checkbox" key={item.id} value={item.id} checked={this.state.typeChekClientell.find((ch) => ch === item.id)}
                                   onChange={this.handleClientelle} /><h6>{item.lbl}</h6>
                            <span className="checkmark"></span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center'>
                <Search search={this.state.search} handleChange={this.handleChange} />
              </div>
              {/*********************** Affichage des articles de la vitrine ***********************/}
              <div className='row'>
                {this.allArticles()}
              </div>
            </div>

            <VitrinePanier location={this.props.location} changeModal={this.changeModal.bind(this)} modalShowPaAc={this.state.modalShowPaAc} changePanier={this.changePanier.bind(this)} panier={this.state.panier} panier_copy={this.state.panier_copy} />
          </>
          :
          <div className='row d-flex justify-content-center'>
            <h2>{utils.lbl[`${(this.state.lang)}.7`]}</h2>
          </div>
        }

      </>
    )
  }
}

export default withRouter(VitrineConsultation)