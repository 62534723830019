export var lbl = [];

lbl["fr.0"] = "Demande d'annulation d'une commande";
lbl["fr.1"] = "Numéro de commande";
lbl["fr.2"] = "Mél renseigné";
lbl["fr.3"] = "Ou Tél renseigné";
lbl["fr.4"] = "Veuillez saisir le code de validation qui vous a été envoyé par tél ou mél :";
lbl["fr.5"] = "Code";
lbl["fr.6"] = "Confirmer l'annulation";

/* AR */

lbl["ar.0"] = "إلغاء طلب";
lbl["ar.1"] = "رقم الطلب";
lbl["ar.2"] = "البريد الإلكتروني المدخل";
lbl["ar.3"] = "أو الهاتف المُدخل";
lbl["ar.4"] = "الرجاء إدخال رمز التأكيد الذي أرسل إليكم برسالة هاتفية أو إلكترونية :";
lbl["ar.5"] = "الرمز";
lbl["ar.6"] = "تأكيد الإلغاء";

/* EN */

lbl["en.0"] = "Request to cancel an order";
lbl["en.1"] = "Order number";
lbl["en.2"] = "Email entered";
lbl["en.3"] = "Or Phone provided";
lbl["en.4"] = "Please enter the validation code that was sent to you by phone or email:";
lbl["en.5"] = "Code";
lbl["en.6"] = "Confirm cancellation";

/* DE */

lbl["de.0"] = "Anfrage zum Stornieren einer Bestellung";
lbl["de.1"] = "Besstellnummer";
lbl["de.2"] = "E-Mail eingegeben";
lbl["de.3"] = "Oder Tél eingegeben";
lbl["de.4"] = "Bitte geben Sie den Validierungscode ein,der Ihnen per Telefon oder E-Mail zugeschickt wurde :";
lbl["de.5"] = "Code";
lbl["de.6"] = "Stornierung bestätigen";

