import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import disableBrowserBackButton from 'disable-browser-back-navigation';
import axios from 'axios';
import * as utils from '../lang/demandeAnnulationCommandeFin';
import * as comons from '../component/commun';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class DemandeAnnulationCommandeFin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      id: this.props.match.params.id,
      tele: this.props.match.params.tele,
      numCmd: this.props.match.params.numCommande,
      infoConfirme: this.props.location.info,
      motif: ''
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleMsg = () => {
    const ob = {
      numCmd: this.state.info.numCmd,
      id: this.props.location.info.id,
      reason: this.state.motif,
      lang: this.state.lang
    }

    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/visiteur/annulation_commande/reason`, ob).then(res => {
      // console.log(res)
      this.setState({
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  theFirst = () => {
    return (
      <>
        <div className="jumbotron d-flex flex-column justify-content-center" style={{ backgroundColor: '#080350' }}>
          <h2 className='d-flex justify-content-center' style={{ color: 'white' }}>{this.state.infoConfirme.msg}</h2>
          <Link to={{
            pathname: '/',
            state: { info: this.state.info }
          }} className='btn btn-primary d-flex justify-content-end' style={{ color: 'white' }} onClick={this.goOut}>{utils.lbl[`${(this.state.lang)}.0`]}</Link>
        </div>
      </>)
  }

  theSeconde = () => {
    return (
      <>
        <div className="jumbotron d-flex flex-column justify-content-center" style={{ backgroundColor: '#080350' }}>
          <h2 className='d-flex justify-content-center' style={{ color: 'white' }}>{this.state.infoConfirme.msg}</h2>
          <textarea placeholder='Le Motif' type='text' name='motif' onChange={this.handleChange} value={this.state.motif}></textarea>
          <button className='btn btn-secondary' onClick={this.handleMsg}>{utils.lbl[`${(this.state.lang)}.1`]}</button>
          <Link to={{
            pathname: '/',
            state: { info: this.state.info }
          }} className='btn btn-primary d-flex justify-content-end' style={{ color: 'white' }} onClick={this.goOut}>{utils.lbl[`${(this.state.lang)}.0`]}</Link>
        </div>
      </>)
  }

  theThird = () => {
    return (
      <>
        <div className="jumbotron d-flex flex-column justify-content-center" style={{ backgroundColor: '#080350' }}>
          <h2 className='d-flex justify-content-center' style={{ color: 'white' }}>{this.state.infoConfirme.msg}</h2>
          <Link to={{
            pathname: '/commande-consultation-client',
            state: { info: this.state.info }
          }} className='btn btn-primary d-flex justify-content-end' style={{ color: 'white' }}>{utils.lbl[`${(this.state.lang)}.2`]}</Link>
          <Link to={{
            pathname: '/',
            state: { info: this.state.info }
          }} className='btn btn-primary d-flex justify-content-end' style={{ color: 'white' }} onClick={this.goOut}>{utils.lbl[`${(this.state.lang)}.0`]}</Link>
        </div>
      </>)
  }

  goOut = (e) => {
    return disableBrowserBackButton()
  }



  render() {
    //console.warn(this.props)
    //console.warn(this.state)
    const { infoConfirme } = this.state
    const displayMsg = () => {
      if (infoConfirme.casRetour === 1) {
        return <this.theFirst />
      } else if (infoConfirme.casRetour === 2) {
        return <this.theSeconde />
      } else if (infoConfirme.casRetour === 3) {
        return <this.theThird />
      }
    }

    return (
      <>

        {displayMsg()}

      </>
    )
  }
}