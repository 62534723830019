import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/commandeTraitement';
import * as comons from '../component/commun';



class CommandeTraitement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      id_commande: this.props.match.params.idCommande,
      idAgence: this.props.match.params.idAgence,
      dataProduct: [],
      dataArticle: [],
      firstButton: '',
      secodeButton: '',
      devise: '...',
      direc: false,
      hd: false
    };
    this.state.info.lang = this.state.lang;
  }

  updateInfos = () => {
    for (let i = 0; i < this.state.info.comptes.length; i++) {
      if ((this.state.info.comptes[i].id_agence == this.state.idAgence)) {
        this.state.color = this.state.info.comptes[i].couleur_agence;
        this.state.devise = this.state.info.comptes[i].devise;
        break;
      }
    }
  }

  handleData = () => {
    var dmdData = {
      idCommande: this.state.id_commande,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token,
      lng: this.state.lang
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commercant/suivi_commande`, dmdData).then(res => {
      this.setState({
        dataProduct: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleArticleData = () => {
    var dmdData = {
      idCommande: this.state.id_commande,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token,
      lng: this.state.lang
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commercant/suivi_detail_commande`, dmdData).then(res => {
      this.setState({
        dataArticle: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount = () => {
    this.handleData();
    this.handleArticleData();
    this.updateInfos();
  }

  handleAction = () => {
    var dmdData = {
      action: 1,
      idCommande: this.state.id_commande,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commercant/traiter_commande`, dmdData).then(res => {
      this.setState({
        direc: !this.state.direc
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleAnnuler = () => {
    var dmdData = {
      action: 2,
      idCommande: this.state.id_commande,
      idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commercant/traiter_commande`, dmdData).then(res => {
      this.setState({
        direc: !this.state.direc
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getPhoto = (idf) => { //TODO appeler la fonction pour afficher la foto
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/photo/` + idf).then(res => {
      this.setState({
        idf: res.data[0]
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  changeLanguage = (lang) => {
    this.setState({ lang });
    this.state.info.lang = lang;
  }



  render() {
    //console.log(this.state)

    const { dataProduct, dataArticle, direc } = this.state
    let last1 = dataProduct.slice(-1);

    if (direc) {
      return <Redirect to={{
        pathname: '/commande-consultation-commercant/' + this.state.idAgence,
        state: { info: this.state.info }
      }} />
    }

    return (
      <>

        <MenuUser location={this.props.location} changeLanguage={this.changeLanguage.bind(this)} lang={this.state.lang} />
        <div className="container-fluid">
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.18`] : utils.lbl[`${(this.state.lang)}.19`]}
          </span>
          <div className="row">
            <div className={this.state.hd ? "col-12 p-0" : "col-lg-2 col-md-3 col-sm-4 p-0"} hidden={this.state.hd}>
              <MenuAgence hidden={true} location={this.props.location} />
            </div>
            <div className='col'>
              <div className="d-flex bd-highlight">
                <div className="p-2 w-100 bd-highlight">
                  <div className="d-flex justify-content-center">
                    <div className="container">
                      <div className="row">
                        {dataArticle.map(i => (
                          <>
                            <div className="col-lg-6">
                              <table className="table table-sm">
                                <tbody>
                                  {i.articles.map(j => (
                                    <tr>
                                      <td>
                                        <div>{i.photos.map((value) => {
                                          if (value.id == j.id_photo) {
                                            return (
                                              <img className="img-thumbnail rounded-circle" src={value.img} alt="" />
                                            )
                                          }
                                        })}</div>
                                      </td>
                                      <td>
                                        <b>{j.annonce}</b>
                                      </td>
                                      <td>
                                        <span class="badge badge-dark mr-1">{utils.lbl[`${(this.state.lang)}.1`]}</span>
                                        <b>{j.q}</b>
                                      </td>
                                      <td>
                                        <span class="badge badge-dark mr-1">{utils.lbl[`${(this.state.lang)}.0`]}</span>
                                        <b>{j.prix}</b>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="col-lg-6">
                              <ul className="list-group list-group-flush justify-content-center">
                                {i.id_livraison !== 1 ?
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.6`]}</span>
                                    <b>{i.frais_livraison} {this.state.devise}</b>
                                  </li>
                                :
                                ''}
                                <li className="list-group-item">
                                  <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                                  <b>{i.montant} {this.state.devise}</b>
                                </li>
                              </ul>
                              {i.id_livraison === 1 ?
                                <ul className="list-group list-group-flush justify-content-center">
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                                    <b>{i.nom_client}</b></li>
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.4`]}</span>
                                    <b>{i.mail}</b></li>
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                                    <b>{i.tel}</b></li>
                                </ul>
                                :
                                <ul className="list-group list-group-flush justify-content-center">
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                                    <b>{i.nom_client}</b></li>
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.7`]}</span>
                                    <b>{i.adr}</b></li>
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.8`]}</span>
                                    <b>{i.ville}</b></li>
                                  <li className="list-group-item">
                                    <span class="badge badge-dark mr-3">{utils.lbl[`${(this.state.lang)}.9`]}</span>
                                    <b>{i.pays}</b></li>
                                </ul>
                              }
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <table className="table table-sm">
                    <thead className="thead-dark">
                      <tr>
                        <th>{utils.lbl[`${(this.state.lang)}.10`]}</th>
                        <th>{utils.lbl[`${(this.state.lang)}.11`]}</th>
                        <th>{utils.lbl[`${(this.state.lang)}.12`]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataProduct.map(e => (
                        <tr>
                          <td style={{ height: '0.5rem' }}>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(e.date))}</td>
                          <td style={{ height: '0.5rem' }}>{e.opelbl}</td>
                          <td style={{ height: '0.5rem' }}>{e.stalbl}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="col">
                  <div className="d-flex flex-row justify-content-center">
{/* 
                  <div className='p-0 mt-5 d-flex justify-content-center ml-5 mb-5'>
                    {last1.map(t => t.status === 'Terminé' && t.operation === 'Commande préparée' ?
                      <>
                      <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">Valider</button>
                      <button type='button' onClick={this.handleAnnuler} className='btn btn-secondary btn-sm mt-0 mb-0'>Annuler</button>
                    </> 
                      : '')}*/}
                    {last1.map(t => t.staid === 3 && t.opeid === 1 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.13`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.14`]}</button>
                        </div>
                      </>
                      : '')}
                    {last1.map(t => t.staid === 3 && t.opeid === 2 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.15`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.14`]}</button>
                        </div>
                      </>
                      : '')}
                    {last1.map(t => t.staid === 2 && t.opeid === 3 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.13`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.14`]}</button>
                        </div>
                      </>
                      : '')}
                    {last1.map(t => t.staid === 3 && t.opeid === 3 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.16`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.14`]}</button>
                        </div>
                      </>
                      : '')}
                    {last1.map(t => t.staid === 2 && t.opeid === 4 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.13`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.14`]}</button>
                        </div>
                      </>
                      : '')}
                    {last1.map(t => t.staid === 2 && t.opeid === 5 ?
                      <>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAction} className="btn btn-primary btn-sm mr-5 mt-0 mb-0">{utils.lbl[`${(this.state.lang)}.13`]}</button>
                        </div>
                        <div className="row mr-3">
                          <button type='button' onClick={this.handleAnnuler} className='btn btn-danger btn-sm mt-0 mb-0'>{utils.lbl[`${(this.state.lang)}.17`]}</button>
                        </div>
                      </>
                      : '')}
                      <div className="row">
                        <button onClick={() => this.props.history.goBack()} className='btn btn-secondary mt-0 mb-0 ml-5'>{utils.lbl[`${(this.state.lang)}.20`]}</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(CommandeTraitement)