import React, { Component } from 'react';
import { PushSpinner } from "react-spinners-kit";
import './activation.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import CVAgence from '../component/cvAgence';
import * as utils from '../lang/activation';
import * as comons from '../component/commun';
import Moment from 'moment'



export default class Activation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      activation: '',
      dateDebut: Moment(Date()).format('YYYY-MM-DD'),
      dateFin: '',
      ds: false
    };
  }

  getActivation = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token,
      idAgence: this.props.match.params.idAgence,
      idModule: this.props.match.params.idModule
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/read_droit_module`, dmdData).then(res => {
      this.setState({ activation: res.data });
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.9`] + err);
      toast.error(err.response.data.error + err.message, {
        autoClose: 3000
      });
    });
  }

  getCVAgence = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cv_agence/${this.props.match.params.idAgence}/${this.state.lang}`).then(res => {
      this.props.location.dataAgence = res.data;
      this.setState({ isLoading: false }); //Informer de la fin du chargement
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.9`] + err);
      toast.error(err.response.data.error, err.message, {
        autoClose: 3000
      });
    });
  }


  componentDidMount = () => {
    this.getActivation();
    this.getCVAgence();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleActiver = e => {
    e.preventDefault();
    this.setState({ ds: true });
    // e.target.disabled = true;
    const dmdActiv = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token,
      idAgence: this.props.match.params.idAgence,
      idModule: this.props.match.params.idModule,
      dateDebut: this.state.dateDebut,
      dateFin: this.state.dateFin
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/create_droit_module`, dmdActiv).then(res => {
      // e.target.innerHTML = utils.lbl[`${(this.state.lang)}.8`];
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.9`] + err);
      // e.target.disabled = false;
      this.setState({ ds: false });
      toast.error(err.message + err.response.data.error, {
        autoClose: 3000
      });
    });
  }



  render() {
    const { isLoading } = this.state;

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
          <div className="p-2 flex-shrink-1 bd-info">
            <div className="d-flex justify-content-center">
              <h1 className={{ textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
            </div>
            <div className="d-flex justify-content-center">
              <table className="table table-sm table-light">
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.5`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.activation.nom}</td>
                    <td>{this.state.activation.ice}</td>
                    <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(this.state.activation.date_creation))}</td>
                    <td>{this.state.activation.nom_module}</td>
                    <td>
                      <input type="date" name='dateDebut' value={this.state.dateDebut} onChange={this.handleChange} className="form-control mb-2 mr-sm-2" autoComplete='false' />
                    </td>
                    <td>
                      <input type="date" name='dateFin' value={this.state.dateFin} onChange={this.handleChange} className="form-control mb-2 mr-sm-2" autoComplete='false' />
                    </td>
                    <td>
                      <button className="btn btn-primary" disabled={this.state.ds} onClick={this.handleActiver}>
                        {this.state.ds ? utils.lbl[`${(this.state.lang)}.8`] : utils.lbl[`${(this.state.lang)}.7`]}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <CVAgence location={this.props.location} />
          </div>
        </div>

      </>
    )
  }
}