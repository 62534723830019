export var lbl = [];

lbl["fr.0"] = "Annonce :";
lbl["fr.1"] = "Prix :";
lbl["fr.2"] = "SERVICE :";
lbl["fr.3"] = "FRAIS :";
lbl["fr.4"] = "DESCRIPTION :";
lbl["fr.5"] = "Achat unitaire";
lbl["fr.6"] = "Ajout au panier";
lbl["fr.7"] = "Vitrine"; // Modif
lbl["fr.8"] = "Alerte !";
lbl["fr.9"] = "Quantité";
lbl["fr.10"] = "Montant";
lbl["fr.11"] = "Frais de livraison";
lbl["fr.12"] = "Annuler";
lbl["fr.13"] = "Ajouter";
lbl["fr.14"] = "... ?";
lbl["fr.15"] = ""; // Modif
lbl["fr.16"] = ""; // Modif
lbl["fr.17"] = "Commande unitaire d'article";
lbl["fr.18"] = "Nom";
lbl["fr.19"] = "Adresse";
lbl["fr.20"] = "Pays";
lbl["fr.21"] = "Sélectionner un pays";
lbl["fr.22"] = "Ville";
lbl["fr.23"] = "Sélectionner une ville";
lbl["fr.24"] = "Mél";
lbl["fr.25"] = "Ou Tél";
lbl["fr.26"] = "Je reconnais avoir lu, compris et accepté ";
lbl["fr.27"] = "les conditions générales de vente (CGV)";
lbl["fr.28"] = "Pour la notification du suivi de votre commande";
lbl["fr.29"] = "Commander";
lbl["fr.30"] = "Votre commande a été passée avec succès";
lbl["fr.31"] = "Accepter";
lbl["fr.32"] = "Accès non autorisé";

/* AR */

lbl["ar.0"] = "إعلان :";
lbl["ar.1"] = "ثمن :";
lbl["ar.2"] = "خدمة :";
lbl["ar.3"] = "تكاليف :";
lbl["ar.4"] = "وصف :";
lbl["ar.5"] = "شراء منفرد";
lbl["ar.6"] = "إضافة للسلة";
lbl["ar.7"] = "الواجهة";
lbl["ar.8"] = "إنذار !";
lbl["ar.9"] = "كمية";
lbl["ar.10"] = "حاصل"; // مجموع
lbl["ar.11"] = "تكاليف التوصيل";
lbl["ar.12"] = "إلغاء";
lbl["ar.13"] = "إضافة";
lbl["ar.14"] = "... ?";
lbl["ar.15"] = ""; // Modif
lbl["ar.16"] = ""; // Modif
lbl["ar.17"] = "طلب انفرادي";
lbl["ar.18"] = "اسم";
lbl["ar.19"] = "عنوان";
lbl["ar.20"] = "بلد";
lbl["ar.21"] = "حدد بلدا";
lbl["ar.22"] = "مدينة";
lbl["ar.23"] = "حدد مدينة";
lbl["ar.24"] = "بريد إلكتروني";
lbl["ar.25"] = "أو هاتف";
lbl["ar.26"] = "أقر أني اطلعت و فهمت و وافقت ";
lbl["ar.27"] = "على الشروط العامة للبيع (ش. ع. ب.)";
lbl["ar.28"] = "لتتبع طلبكم";
lbl["ar.29"] = "طلب";
lbl["ar.30"] = "تم بنجاح تسجيل طلبكم";
lbl["ar.31"] = "أوافق";
lbl["ar.32"] = "ولوج غير مسموح";

/* EN */

lbl["en.0"] = "Advert:";
lbl["en.1"] = "Price:";
lbl["en.2"] = "SERVICE:";
lbl["en.3"] = "FEES:";
lbl["en.4"] = "DESCRIPTION:";
lbl["en.5"] = "Buy directly";
lbl["en.6"] = "Add to basket";
lbl["en.7"] = "Vitrine";
lbl["en.8"] = "Alert!";
lbl["en.9"] = "Quantity";
lbl["en.10"] = "Amount";
lbl["en.11"] = "Shipping cost";
lbl["en.12"] = "Cancel";
lbl["en.13"] = "َAdd";
lbl["en.14"] = "... ?";
lbl["en.15"] = ""; // Modif
lbl["en.16"] = ""; // Modif
lbl["en.17"] = "Item unit order";
lbl["en.18"] = "Name";
lbl["en.19"] = "Address";
lbl["en.20"] = "Countrys";
lbl["en.21"] = "Select a country";
lbl["en.22"] = "City";
lbl["en.23"] = "Select a city";
lbl["en.24"] = "Email";
lbl["en.25"] = "Or Phone";
lbl["en.26"] = "I acknowledge having read, understood and accepted ";
lbl["en.27"] = "the general conditions of sale (GCS)";
lbl["en.28"] = "For the notification of the follow-up of your order";
lbl["en.29"] = "Order";
lbl["en.30"] = "Your order has been successfully placed";
lbl["en.31"] = "Accept";
lbl["en.32"] = "Unauthorized access";

/* DE */

lbl["de.0"] = "Anzeige:";
lbl["de.1"] = "Preis:";
lbl["de.2"] = "ART:";
lbl["de.3"] = "Kosten:";
lbl["de.4"] = "BESCHREIBUNG:";
lbl["de.5"] = "Kaufen";
lbl["de.6"] = "+ Basket";
lbl["de.7"] = "GEH ZURÜCK";
lbl["de.8"] = "Alarm!";
lbl["de.9"] = "Menge";
lbl["de.10"] = "Menge";
lbl["de.11"] = "Versandkosten";
lbl["de.12"] = "Abbrechen";
lbl["de.13"] = "َAdd";
lbl["de.14"] = "... ?";
lbl["de.15"] = ""; // Modif
lbl["de.16"] = ""; // Modif
lbl["de.17"] = "Artikeleinheit bestellen";
lbl["de.18"] = "Nom";
lbl["de.19"] = "Adresse";
lbl["de.20"] = "Land";
lbl["de.21"] = "wähle ein Land";
lbl["de.22"] = "Stadt";
lbl["de.23"] = "Wähle eine Stadt aus";
lbl["de.24"] = "email";
lbl["de.25"] = "Oder Tel";
lbl["de.26"] = "Ich bestätige, gelesen, verstanden und akzeptiert zu haben ";
lbl["de.27"] = "die allgemeinen Verkaufsbedingungen (GCS)";
lbl["de.28"] = "Für die Benachrichtigung über die Nachverfolgung Ihrer Bestellung";
lbl["de.29"] = "bestellen";
lbl["de.30"] = "Ihre Bestellung wurde erfolgreich aufgegeben";
lbl["de.31"] = "Akzeptieren";
lbl["de.32"] = "Unauthorized access";

