import React from 'react';
import CreationArticle from './Pages/articleCreation';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from '../src/Pages/login';
import VitrineConsultation from './Pages/VitrineConsultation';
import CreationMagasin from './Pages/magasinCreation';
import NouveauMagasin from './Pages/magasinNouveau';
import AdminIndex from './Pages/adminIndex';
import Activation from './Pages/activation';
import Desactivation from './Pages/desactivation';
import CreationCompte from './Pages/compteCreation';
import Home from './Pages/home';
import '../src/component/navbar.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
  //  withRouter
} from "react-router-dom";
import CommandeConsultationCommercant from './Pages/commandeConsultationCommercant';
import commandeConsultation from './Pages/commandeConsultation';
import MagasinIndex from './Pages/magasinIndex';
import CommandeConsultationClient from './Pages/commandeConsultationClient';
import ModifierMagasin from './Pages/magasinModification';
import ModifierArticle from './Pages/articleModification';
import Default from './Pages/default';
import CommandeTraitement from './Pages/commandeTraitement';
import DemandeAnnulationCommande from './Pages/demandeAnnulationCommande';
import ModifierCompte from './Pages/compteModification';
import ArticleCommercant from './Pages/articleCommercant';
import ArticleCommercantDetail from './Pages/articleCommercantDetail';
import VitrineConsultationDetail from './Pages/vitrineConsultationDetail';
import ActivationModuleTableau from './Pages/activationModuleTableau';
import ActivationIceTableau from './Pages/activationIceTableau';
import DemandeAnnulationCommandeFin from './Pages/demandeAnnulationCommandeFin';
import AgencePublicite from './Pages/agencePublicite';



function App() {


  return (
    <div className="App">

      <Router >

        <Switch> <Route exact path="/" component={Home} />
          {/* <ProtectedRoutes exact path="/login" component={login} isLoged={isLoged}/> */}

          <Route path="/reload" component={null} key="reload" />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/login/:id?" component={UserIndex} /> */}

          <Route exact path="/creation-article/:idAgence?" component={CreationArticle} />
          {/* <Route path="/creation-article/:id?" component={ArticleCommercantDetail} /> */}
          <Route exact path='/article-commercant/:idAgence?' component={ArticleCommercant} />
          <Route exact path='/article-commercant-detail/:idArticle?/:idAgence?' component={ArticleCommercantDetail} />
          <Route exact path='/modifier-article/:idArticle?/:idAgence?' component={ModifierArticle} />

          <Route exact path="/vitrine/:idAgence?" component={VitrineConsultation} />
          <Route exact path="/vitrine-detail/:idAgence?/:idArticle?" component={VitrineConsultationDetail} />

          <Route exact path='/magasin-index/:idAgence?' component={MagasinIndex} />
          <Route exact path="/creation-magasin" component={CreationMagasin} />
          <Route exact path="/nouveau-magasin" component={NouveauMagasin} />
          <Route exact path='/modifier-magasin/:idAgence?' component={ModifierMagasin} />

          <Route exact path='/admin-index' component={AdminIndex} />
          <Route exact path='/activation/:idAgence?/:idModule?' component={Activation} />
          <Route exact path='/desactivation/:idAgence?/:idModule?' component={Desactivation} />
          <Route exact path='/activation-module-tableau' component={ActivationModuleTableau} />
          <Route exact path='/activation-ice-tableau' component={ActivationIceTableau} />
          <Route exact path='/agence-publicite' component={AgencePublicite} />

          <Route exact path='/creation-compte' component={CreationCompte} />
          <Route exact path='/modifier-compte' component={ModifierCompte} />

          <Route exact path='/commande-consultation' component={commandeConsultation} />
          <Route exact path='/commande-consultation-client' component={CommandeConsultationClient} />
          <Route exact path='/commande-consultation-commercant/:idAgence?' component={CommandeConsultationCommercant} />
          <Route exact path='/commande-traitement/:idCommande?/:idAgence?' component={CommandeTraitement} />
          <Route exact path='/commande-consultation-client/:id?/:numCommande?/:tele?/:email?' component={DemandeAnnulationCommande} />
          <Route exact path='/demande-annulation-commande-fin/:id?/:numCommande?/:tele?/:email?' component={DemandeAnnulationCommandeFin} />

          <Route exact component={Default} />

        </Switch>

      </Router>

    </div>
  );
}

export default App;