export var lbl = [];

lbl["fr.0"] = "Agences Publicite";
lbl["fr.1"] = "Periode";
lbl["fr.2"] = "Ville";
lbl["fr.3"] = "Pays";
lbl["fr.4"] = "Agence Name";
lbl["fr.5"] = "ERREUR : ";
lbl["fr.6"] = "Ajouter";
lbl["fr.7"] = "Supprimer";
lbl["fr.8"] = "Modifier";

/* AR */

lbl["ar.0"] = " تتبع إشهرات المتاجر";
lbl["ar.1"] = "المدة الزمنية";
lbl["ar.2"] = "المدينة";
lbl["ar.3"] = "الدولة";
lbl["ar.4"] = "اسم المتجر";
lbl["ar.5"] = "خطأ : ";
lbl["ar.6"] = "إضافة";

/* EN */

lbl["en.0"] = "FOLLOWED";
lbl["en.1"] = "DEnomination";
lbl["en.2"] = "Client Number";
lbl["en.3"] = "Creation Date";
lbl["en.4"] = "Creation Date";
lbl["en.5"] = "ERROR : ";
lbl["en.28"] = "Add";
lbl["en.7"] = "Delete : ";
lbl["en.8"] = "Update";

/* DE */

lbl["de.0"] = "Zu kasse";
lbl["de.1"] = "Name";
lbl["de.2"] = "Kundennummer";
lbl["de.3"] = "Erstelldatum";
lbl["de.4"] = "Erstelldatum";
lbl["de.5"] = "ERROR : ";
lbl["de.6"] = "Hinzufügen";

