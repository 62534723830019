export var lbl = [];

lbl["fr.0"] = "Nouvel article";
lbl["fr.1"] = "Vitrine";
lbl["fr.2"] = "Commandes";
lbl["fr.3"] = "Paramètres Magasin";
lbl["fr.4"] = "Accueil";
lbl["fr.5"] = "Suivi Activation Modules";
lbl["fr.6"] = "Suivi Vérification ICE";
lbl["fr.7"] = "Nouveau magasin";
lbl["fr.8"] = "Suivi agence Pub";

/* AR */

lbl["ar.0"] = "سلعة جديدة";
lbl["ar.1"] = "واجهة المتجر";
lbl["ar.2"] = "الطلبات";
lbl["ar.3"] = "إعدادات المتجر";
lbl["ar.4"] = "الاستقبال";
lbl["ar.5"] = "تتبع تفعيل الوحدات";
lbl["ar.6"] = "تتبع مراقبة ICE";
lbl["ar.7"] = "متجر جديد";
lbl["ar.8"] = "Suivi agence Pub";

/* EN */

lbl["en.0"] = "New article";
lbl["en.1"] = "Showcase";
lbl["en.2"] = "Orders";
lbl["en.3"] = "shop settings";
lbl["en.4"] = "Home";
lbl["en.5"] = "Monitoring Activation Modules";
lbl["en.6"] = "ICE Verification Tracking";
lbl["en.7"] = "New shop";
lbl["en.8"] = "Suivi agence Pub";

/* DE */

lbl["de.0"] = "Neuer Artiklel";
lbl["de.1"] = "Schaufenster";
lbl["de.2"] = "Bestellung";
lbl["de.3"] = "Shop Einstellungen";
lbl["de.4"] = "Startseite";
lbl["de.5"] = "Nachfolge-Aktivierungsmodule";
lbl["de.6"] = "Fortsetzung Verifikation kundennummer";
lbl["de.7"] = "Neuer Shop";
lbl["de.8"] = "Suivi agence Pub";

