export var lbl = [];

lbl["fr.0"] = "Catégorie article";
lbl["fr.1"] = "Clientelle";
lbl["fr.2"] = "Restant : ";
lbl["fr.3"] = "Vente : ";
lbl["fr.4"] = "Promo : ";
lbl["fr.5"] = "du ";
lbl["fr.6"] = "au ";
lbl["fr.7"] = "Accès non autorisé";

/* AR */

lbl["ar.0"] = "نوع السلعة";
lbl["ar.1"] = "الزبون";
lbl["ar.2"] = "متبقي : ";
lbl["ar.3"] = "بيع : ";
lbl["ar.4"] = "تخفيض : ";
lbl["ar.5"] = "من ";
lbl["ar.6"] = "إلى ";
lbl["ar.7"] = "ولوج غير مسموح";

/* EN */

lbl["en.0"] = "Article Category";
lbl["en.1"] = "Customer";
lbl["en.2"] = "Remain: ";
lbl["en.3"] = "Sell: ";
lbl["en.4"] = "Promo: ";
lbl["en.5"] = "from ";
lbl["en.6"] = "to ";
lbl["en.7"] = "Unauthorized access";

/* DE */

lbl["de.0"] = "Artikelkategorie";
lbl["de.1"] = "Kunden";
lbl["de.2"] = "Bleiben: ";
lbl["de.3"] = "Verkaufen: ";
lbl["de.4"] = "Promo: ";
lbl["de.5"] = "ab ";
lbl["de.6"] = "bis ";
lbl["de.7"] = "Unauthorized access";

