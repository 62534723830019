import React, { Component } from 'react'

export default class Default extends Component {
    render() {
        return (
            <>
             <h1 style={{color:'black',fontSize:'7rem'}}>MAKAYN WALOU HNA</h1>   
            </>
        )
    }
}
