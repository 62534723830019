import Modal from 'react-bootstrap4-modal';

export var srvb_url_local = "localhost";
var srvb_url_reso = "www.e-tajyr.com";
var srvb_por_local = "3001";
var srvb_por_reso = "4443";
var srvb_ptl_local = "http";
var srvb_ptl_reso = "https";
var srvf_por_local = "3000";
var srvf_por_reso = "443";


export const srv_url = srvb_url_reso;
export const srv_por = srvb_por_reso;
export const srv_ptl = srvb_ptl_reso;
export const srvf_por = srvf_por_reso;


export var showMsg = (msg) => {
  return (
    <>
      <Modal visible={true}>
        <div className="modal-header">
          <h5 className="modal-title">Alerte !</h5>
        </div>
        <div className="modal-footer">
          <h4>{msg}</h4>
          <button type="button" className="btn btn-primary">OK</button>
        </div>
      </Modal>
    </>
  )
}
