import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Search extends Component {
  render() {
    const { handleChange, search } = this.props; // handleSubmit
    return (
      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto col-md-8 mt-3 text-center">

            <form className="mt-3">
              <div className="input-group">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Tshirt..."
                  value={search}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <button
                    disabled
                    // type="submit"
                    className="input-group-text bg-primary text-white"
                  // onClick={handleSubmit}
                  >
                    <FontAwesomeIcon icon={faSearch} />

                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}