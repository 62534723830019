import React, { Component } from 'react';
import './vitine.css';
import Search from '../component/Search';
import { Link, withRouter } from 'react-router-dom';
import { PushSpinner } from 'react-spinners-kit';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/articleCommercant';
import * as comons from '../component/commun';



class ArticleCommercant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      idAgence: this.props.match.params.idAgence,
      id: this.props.location.state.info.id_personnel,
      // product: data.products,
      product: [],
      clientelle: [],
      typeChekClientell: [],
      articleCat: [],
      typeChekCategorie: [],
      color: '#ffffff',
      devise: '...',
      search: '',
      pas: '',
      pos: '',
      hd: false
      // currentPage: 1,
      // postsPerPage: 5
    };
  }

  updateInfos = () => {
    for (let i = 0; i < this.state.info.comptes.length; i++) {
      if ((this.state.info.comptes[i].id_agence == this.state.idAgence)) {
        this.state.color = this.state.info.comptes[i].couleur_agence;
        this.state.devise = this.state.info.comptes[i].devise;
        break;
      }
    }
  }

  handleClientelle = e => {
    // current array of options
    const typeChekClientell = this.state.typeChekClientell
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      typeChekClientell.push(+e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = typeChekClientell.indexOf(+e.target.value)
      typeChekClientell.splice(index, 1)
    }

    this.setState({
      typeChekClientell
    });
  }

  handleCategorie = e => {
    // current array of options
    const typeChekCategorie = this.state.typeChekCategorie
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      typeChekCategorie.push(+e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = typeChekCategorie.indexOf(+e.target.value)
      typeChekCategorie.splice(index, 1)
    }

    this.setState({
      typeChekCategorie
    });
  }

  getClientelle = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/clientelle/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({ clientelle: res.data });
      this.state.clientelle.forEach(element => {
        this.state.typeChekClientell.push(element.id);
      });
    }).catch(err => {
      toast.error(err.message, err.response.data.error, {
        autoClose: 3000
      });
    });
  }

  getArticleCat = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/articlecat/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({ articleCat: res.data });

      this.state.articleCat.forEach(element => {
        this.state.typeChekCategorie.push(element.id);
      });
    }).catch(err => {
      toast.error(err.message, err.response.data.error, {
        autoClose: 3000
      });
    });
  }

  getArticles = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/articles/commercant`,
      {
        pas: 20,
        pos: 1,
        idAgence: this.state.idAgence,
        token: this.state.info.token,
        idPersonnel: this.state.id
      }).then(res => {
        this.setState({ product: res.data });
        this.setState({ isLoading: false }); //Informer de la fin du chargement
      }).catch(err => {
        toast.error(err.message, err.response.data.error, {
          autoClose: 3000
        });
      });
  }

  componentDidMount = () => {
    this.getClientelle();
    this.getArticleCat();
    this.getArticles();
    this.updateInfos();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.idAgence !== this.state.idAgence) {
      this.getClientelle();
      this.getArticleCat();
      this.getArticles();
      this.updateInfos();
    }
  }

  handleChange = e => {
    this.setState({
      search: e.target.value
    });
  }

  allArticles = () => {
    this.state.resultIDs = [];
    return (this.state.product.map((article) => {
      //this.state.product.filter(article => article.Annonce.toLocaleLowerCase().includes(this.state.search)).map((article) => {
      const { idArticle, img, annonce, quantite, achat, vente, prixPromo, dateDebutPromo, dateFinPromo, idCategorie, idClientelle } = article;

      let clientArticleOk = false;
      this.state.typeChekClientell.forEach(el1 => {
        if (idClientelle.indexOf(el1) != -1) {
          clientArticleOk = true;
        }
      });
      if (clientArticleOk && this.state.typeChekCategorie.indexOf(idCategorie) != -1 && annonce.toLocaleLowerCase().includes(this.state.search)) {
        this.state.resultIDs.push(article.idArticle);
        return (

            <div id={idArticle} className="col-10 mx-auto col-md-6 col-lg-2 my-3">
              <div className="card border border-info mb-3 p-0" style={{ width: "100%", height: "100%" }}>
                <Link to={{
                  pathname: `/article-commercant-detail/${idArticle}/${this.state.idAgence}`,
                  state: { info: this.state.info },
                  resultIDs: this.state.resultIDs
                }} type="button" className="btn btn-sm mt-0">
                  <div className="card-header">
                    {annonce}
                  </div>
                  <img style={{ width: "14 rem", height: "12 rem" }} src={img} className="card-img-top m-0" alt="..." />
                  <div className="card-footer text-muted text-capitalize p-0">
                    <div className='d-flex d-row justify-content-around'>
                      <h6 style={{ color: 'black' }} className="p-0 bd-highlight mt-2">{utils.lbl[`${(this.state.lang)}.2`]}<b>{quantite}</b></h6>
                    </div>
                    <ul className="list-group list-group-flush p-0 ">
                      <li className="list-group-item flex-column justify-content-between p-0">
                        <h6 style={{ color: 'black' }}>
                          {utils.lbl[`${(this.state.lang)}.7`]}<b>{achat}</b> {this.state.devise}
                        </h6>
                        <h6 style={{ color: 'black' }}>
                          {utils.lbl[`${(this.state.lang)}.3`]}<b>{vente}</b> {this.state.devise}
                        </h6>
                      </li>
                      {prixPromo != null || prixPromo != undefined ?
                        <>
                          <div li className="list-group-item p-0 m-0">
                            <h5 style={{ color: 'green' }}>{utils.lbl[`${(this.state.lang)}.4`]}<b>{prixPromo}</b> {this.state.devise}</h5>
                            <h6 style={{ color: 'green' }}>{utils.lbl[`${(this.state.lang)}.5`]} <b>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dateDebutPromo))}</b> {utils.lbl[`${(this.state.lang)}.6`]} <b>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(dateFinPromo))}</b></h6>
                          </div>
                        </>
                        : ''}
                    </ul>
                  </div>
                </Link>
              </div>
            </div>

        );
      }
    }));
  }



  render() {
    //const { postsPerPage, currentPage, product } = this.state
    // const lastPost = currentPage * postsPerPage
    // const firstPost = lastPost - postsPerPage
    // const currentPosts = product.slice(firstPost, lastPost)
    // const pageNumbers = [];
    // for (let i = 1; i <= Math.ceil(product.length / postsPerPage); i++) {
    //   pageNumbers.push(i)
    // }

    // const paginate = pageNum => this.setState({ currentPage: pageNumbers })
    // const nextPage = () => this.setState({ currentPage: currentPage + 1 })
    // const prevPage = () => this.setState({ currentPage: currentPage - 1 })

    // console.warn(this.state.product)
    // console.warn(this.state)

    const { isLoading, clientelle, articleCat, idAgence } = this.state
    if (idAgence !== this.props.match.params.idAgence) {
      this.setState({
        idAgence: this.props.match.params.idAgence
      })
    }

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid" style={{ backgroundColor: this.state.color, color: (this.state.color > "#888888" ? "#000000" : "#ffffff")  }}>
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.8`] : utils.lbl[`${(this.state.lang)}.9`]}
          </span>
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4 mb-2 p-0" hidden={this.state.hd}>
              <MenuAgence location={this.props.location} />
            </div>
            <div className={this.state.hd ? 'col-12 p-0' : 'col-lg-10 col-md-9 col-sm-8 p-0'}>
              <div className="container-fluid">
                <div className="row">
                  <div className='col-lg-9 col-md-9 col-sm-6 mb-2'>
                    {/*********************** Affichage de type de commerce ***********************/}
                    <div className="card" style={{ backgroundColor: 'transparent' }}>
                      <div className="card-header">
                        <h5 className="modal-title d-flex justify-content-center">{utils.lbl[`${(this.state.lang)}.0`]}</h5>
                      </div>
                      <div className="card-body text-capitalize">
                        <div className="row align-self-stretch">
                          {articleCat.map(item => (
                            <label key={item.id} className="container2 ml-2">
                              <input type="checkbox" value={item.id} onChange={this.handleCategorie} key={item.id} 
                                checked={this.state.typeChekCategorie.find((ch) => ch == item.id)} />
                              <h6>{item.lbl}</h6>
                              <span className="checkmark"></span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-3 col-sm-6'>
                    {/*********************** Affichage de la clientelle ***********************/}
                    <div className="card" style={{ backgroundColor: 'transparent' }}>
                      <div className="card-header">
                        <h5 className="modal-title d-flex justify-content-center">{utils.lbl[`${(this.state.lang)}.1`]}</h5>
                      </div>
                      <div className="card-body text-capitalize">
                        <div className="row align-self-stretch">
                          {clientelle.map(item => (
                            <label key={item.id} className="container2 ml-2">
                              <input type="checkbox" value={item.id} onChange={this.handleClientelle} key={item.id} 
                                checked={this.state.typeChekClientell.find((ch) => ch == item.id)} /><h6>{item.lbl}</h6>
                              <span className="checkmark"></span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Search search={this.state.search} handleChange={this.handleChange} />
              <div className="container">
                <div className="row">
                  {this.allArticles()}
                </div>
                {/* < div >
                  <nav aria-label="Page navigation example">
                    <ul className="pagination d-flex justify-content-around">
                      <li className="page-item">
                        <a className="page-link" onClick={() => prevPage()} >Previous</a>
                      </li>
                      {pageNumbers.map(e => (
                        <li classNAme="page-item" key={e}>
                          <a onClick={() => paginate(e)} href="#" classNAme='page-link'> {e}</a>
                        </li>

                      ))}
                      <li className="page-item">
                        <a className="page-link" onClick={() => nextPage()}>Next</a>
                      </li>
                    </ul>
                  </nav>
                </div > */}
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(ArticleCommercant)