export var lbl = [];

lbl["fr.0"] = "Contenu du panier";
lbl["fr.1"] = "Annuler";
lbl["fr.2"] = "Sauvegarder";
lbl["fr.3"] = "Commander";
lbl["fr.4"] = "Passer la commande";
lbl["fr.5"] = "Quantité";
lbl["fr.6"] = "Montant";
lbl["fr.7"] = "Frais de livraison";
lbl["fr.8"] = "Nom";
lbl["fr.9"] = "Adresse";
lbl["fr.10"] = "Pays";
lbl["fr.11"] = "Sélectionner un pays";
lbl["fr.12"] = "Ville";
lbl["fr.13"] = "Sélectionner une ville";
lbl["fr.14"] = "Mél";
lbl["fr.15"] = "Ou Tél";
lbl["fr.16"] = "Je reconnais avoir lu, compris et accepté ";
lbl["fr.17"] = "les conditions générales de vente (CGV)";
lbl["fr.18"] = "Confirmer";
lbl["fr.19"] = "Contrôle";
lbl["fr.20"] = "Code confirmation";
lbl["fr.21"] = "Vérifier";
lbl["fr.22"] = "Alerte !";
lbl["fr.23"] = "Retour à la vitrine";
lbl["fr.24"] = "Votre commande a été passée avec succès";
lbl["fr.25"] = "Pour la notification du suivi de votre commande";
lbl["fr.26"] = "Accepter";
lbl["fr.27"] = "Fermer";
lbl["fr.28"] = "Total";

/* AR */

lbl["ar.0"] = "محتوى السلة";
lbl["ar.1"] = "إلغاء";
lbl["ar.2"] = "حفظ";
lbl["ar.3"] = "أطلب";
lbl["ar.4"] = "إجراء الطلب";
lbl["ar.5"] = "كمية";
lbl["ar.6"] = "حاصل";
lbl["ar.7"] = "تكاليف التوصيل";
lbl["ar.8"] = "اسم";
lbl["ar.9"] = "عنوان";
lbl["ar.10"] = "بلد";
lbl["ar.11"] = "حدد بلدا";
lbl["ar.12"] = "مدينة";
lbl["ar.13"] = "حدد مدينة";
lbl["ar.14"] = "بريد إلكتروني";
lbl["ar.15"] = "أو هاتف";
lbl["ar.16"] = "أقر أني اطلعت و فهمت و وافقت ";
lbl["ar.17"] = "على الشروط العامة للبيع (ش. ع. ب.)";
lbl["ar.18"] = "أؤكّد";
lbl["ar.19"] = "مراقبة";
lbl["ar.20"] = "رمز التأكيد";
lbl["ar.21"] = "التحقق";
lbl["ar.22"] = "إنذار !";
lbl["ar.23"] = "العودة إلى الواجهة";
lbl["ar.24"] = "تم بنجاح تسجيل طلبكم";
lbl["ar.25"] = "لتتبع طلبكم";
lbl["ar.26"] = "أوافق";
lbl["ar.27"] = "إغلاق";
lbl["ar.28"] = "مجموع";

/* EN */

lbl["en.0"] = "Basket contents";
lbl["en.1"] = "Cancel";
lbl["en.2"] = "Save";
lbl["en.3"] = "Order";
lbl["en.4"] = "To order";
lbl["en.5"] = "Amount";
lbl["en.6"] = "Amount";
lbl["en.7"] = "Shipping cost";
lbl["en.8"] = "Name";
lbl["en.9"] = "Address";
lbl["en.10"] = "Country";
lbl["en.11"] = "Select a country";
lbl["en.12"] = "City";
lbl["en.13"] = "Select city";
lbl["en.14"] = "Email";
lbl["en.15"] = "Or Phone";
lbl["en.16"] = "I acknowledge having read, understood and accepted ";
lbl["en.17"] = "the general conditions of sale (GCS)";
lbl["en.18"] = "To confirm";
lbl["en.19"] = "Control";
lbl["en.20"] = "Confirmation code";
lbl["en.21"] = "Check";
lbl["en.22"] = "Alert !";
lbl["en.23"] = "Back to the showcase";
lbl["en.24"] = "Your order has been successfully placed";
lbl["en.25"] = "For the notification of the follow-up of your order";
lbl["en.26"] = "Accept";
lbl["en.27"] = "Close";
lbl["en.28"] = "Total";

/* DE */

lbl["de.0"] = "Korbinhalt";
lbl["de.1"] = "Stornieren";
lbl["de.2"] = "Speichern";
lbl["de.3"] = "Bestellen";
lbl["de.4"] = "Bestellen";
lbl["de.5"] = "Menge";
lbl["de.6"] = "Menge";
lbl["de.7"] = "Versandkosten";
lbl["de.8"] = "Name";
lbl["de.9"] = "Adresse";
lbl["de.10"] = "Land";
lbl["de.11"] = "Wähle ein Land";
lbl["de.12"] = "Stadt";
lbl["de.13"] = "Wähle eine Stadt aus";
lbl["de.14"] = "Email";
lbl["de.15"] = "Or Phone";
lbl["de.16"] = "Ich bestätige, gelesen, verstanden und akzeptiert zu haben ";
lbl["de.17"] = "die allgemeinen Verkaufsbedingungen";
lbl["de.18"] = "Bestätigen";
lbl["de.19"] = "Steuerung";
lbl["de.20"] = "Bestätigungscode";
lbl["de.21"] = "Prüfen";
lbl["de.22"] = "Alerte !";
lbl["de.23"] = "Zurück zum Schaufenster";
lbl["de.24"] = "Ihre Bestellung wurde erfolgreich aufgegeben";
lbl["de.25"] = "Für die Benachrichtigung über die Nachverfolgung Ihrer Bestellung";
lbl["de.26"] = "Akzeptieren";
lbl["de.27"] = "Schließen";
lbl["de.28"] = "Total";

