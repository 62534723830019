export var lbl = [];

lbl["fr.0"] = "ACTIVATION";
lbl["fr.1"] = "Dénomination";
lbl["fr.2"] = "Identifiant société national";
lbl["fr.3"] = "Date création";
lbl["fr.4"] = "Module";
lbl["fr.5"] = "Début activation";
lbl["fr.6"] = "Fin activation";
lbl["fr.7"] = "Activer";
lbl["fr.8"] = "Activé";
lbl["fr.9"] = "ERREUR : ";

/* AR */

lbl["ar.0"] = "تفعيل";
lbl["ar.1"] = "اسم المتجر";
lbl["ar.2"] = "رقم الشركة الوطني";
lbl["ar.3"] = "تاريخ الإنشاء";
lbl["ar.4"] = "وحدة";
lbl["ar.5"] = "بداية التفعيل";
lbl["ar.6"] = "نهاية التفعيل";
lbl["ar.7"] = "تفعيل";
lbl["ar.8"] = "مفعّل";
lbl["ar.9"] = "خطأ : ";

/* EN */

lbl["en.0"] = "ACTIVATION";
lbl["en.1"] = "Denomination";
lbl["en.2"] = "Client number";
lbl["en.3"] = "Creation Date";
lbl["en.4"] = "Module";
lbl["en.5"] = "Start of activation";
lbl["en.6"] = "End of activation";
lbl["en.7"] = "Activate";
lbl["en.8"] = "Enabled";
lbl["en.9"] = "ERROR : ";

/* DE */

lbl["de.0"] = "Aktivierung";
lbl["de.1"] = "Name";
lbl["de.2"] = "Kundennummer";
lbl["de.3"] = "Erselldatum";
lbl["de.4"] = "Modul";
lbl["de.5"] = "Beginn der Aktivierung";
lbl["de.6"] = "Ende der Aktivierung";
lbl["de.7"] = "Activieren";
lbl["de.8"] = "Activieren";
lbl["de.9"] = "ERROR : ";

