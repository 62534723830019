import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faLongArrowAltLeft, faTrash, faStepForward, faStepBackward, faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/articleCommercantDetail';
import * as comons from '../component/commun';

toast.configure()

class ArticleCommercantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      id_article: this.props.match.params.idArticle,
      idAgence: this.props.match.params.idAgence,
      id: this.props.location.state.info.id_personnel,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      dataUri: '',
      idealFacingMode: null,
      clientelle: [],
      typeCommerce: [],
      myArticle: [{ prices: [], cat_article: [], clientel: [] }],
      color: '#ffffff',
      devise: '...',
      resultIDs: typeof this.props.location.resultIDs != 'undefined' ? this.props.location.resultIDs : [],
      btnCacher: ''
    };
    this.state.btnCacher = utils.lbl[`${(this.state.lang)}.7`];
    this.updateInfos();
    this.showArticle(0);
  }

  updateInfos = () => {
    for (let i = 0; i < this.state.info.comptes.length; i++) {
      if ((this.state.info.comptes[i].id_agence == this.state.idAgence)) {
        this.state.color = this.state.info.comptes[i].couleur_agence;
        this.state.devise = this.state.info.comptes[i].devise;
        break;
      }
    }
  }

  showArticle = (pas) => {
    this.state.btnPrevOk = true;
    this.state.btnNextOk = true;
    var newIndx = this.state.resultIDs.indexOf("" + this.state.id_article) + parseInt(pas);
    if (0 <= newIndx && newIndx < this.state.resultIDs.length) {
      this.state.id_article = this.state.resultIDs[newIndx];
      this.getArticle();
    }
    else {
      this.getArticle();
    }

    if (this.state.resultIDs.length == 1) {
      this.state.btnPrevOk = true;
      this.state.btnNextOk = true;
    }
    else if (newIndx <= 0) {
      this.state.btnPrevOk = true;
      this.state.btnNextOk = false;
    }
    else if (newIndx == this.state.resultIDs.length - 1) {
      this.state.btnPrevOk = false;
      this.state.btnNextOk = true;
    }
    else {
      this.state.btnPrevOk = false;
      this.state.btnNextOk = false;
    }
  }

  componentDidMount() {
    this.getClientelle();
    this.getTypeCommerce();
  }

  getArticle = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/article/commercant`,
      {
        idArticle: this.state.id_article,
        token: this.state.info.token,
        idPersonnel: this.state.id,
        lng: this.state.lang
      }).then(res => {
        this.setState({
          myArticle: res.data
        });
      }).catch(err => {
        toast.error(err.message, err.response.data.error, {
          autoClose: 3000
        });
      });
  }

  getClientelle = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/clientelle/${this.state.lang}`).then(res => {
      this.setState({
        clientelle: res.data
      });
    }).catch(err => {
      toast.error(err.message, err.response.data.error, {
        autoClose: 3000
      });
    });
  }

  getTypeCommerce = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/typeCommerce/${this.state.lang}`).then(res => {
      this.setState({
        typeCommerce: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  handleDeleteArticle = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/delete_article`,
      {
        idArticle: this.state.id_article,
        token: this.state.info.token,
        idPersonnel: this.state.id
      }).then(res => {
        toast.success(utils.lbl[`${(this.state.lang)}.9`], { autoClose: 1200 })
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
      });
  }

  handleCacher = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/cacher_article`, {
      idArticle: this.state.id_article,
      token: this.state.info.token,
      idPersonnel: this.state.id
    }).then(res => {
      if (this.state.btnCacher == utils.lbl[`${(this.state.lang)}.8`]) {
        this.setState({
          btnCacher: utils.lbl[`${(this.state.lang)}.7`]
        });
      } else if (this.state.btnCacher == utils.lbl[`${(this.state.lang)}.7`]) {
        this.setState({ btnCacher: utils.lbl[`${(this.state.lang)}.8`] });
      }

    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  goback = () => {
    this.props.history.goBack()
  }



  render() {

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="container-fluid" style={{ backgroundColor: this.state.color }}>
          <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.12`] : utils.lbl[`${(this.state.lang)}.13`]}
          </span>
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4 p-0" hidden={this.state.hd}>
              <MenuAgence location={this.props.location} />
            </div>
            <div className='col-lg-9 col-md-8 col-sm-7 d-flex flex-wrap justify-content-center'>
              <div className="card text-center mt-3" >
                <div className="bg-dark d-flex flex-row justify-content-between">
                  <label className="d-flex flex-row">
                    <h9 style={{ color: 'white' }}> {this.state.myArticle[0].cat_article}</h9>
                  </label>
                  <div className="d-flex flex-row justify-content-end">
                    <h9 style={{ color: 'white' }}>{this.state.myArticle[0].clientel}</h9>
                  </div>
                </div>
                <div className="card-body p-0">
                  <img src={this.state.myArticle[0].photo} className="card-img"
                    // style={{ width: '80vh', height: '45vh' }}
                    style={{ minHeight: '13rem', minWidth: '13rem' }}
                    alt="" />
                </div>
                <div className="audio-wrap">
                  <audio src={this.state.myArticle[0].audio} controls="controls"></audio>
                </div>
                <div className="card-footer bg-dark">
                  <li className="list-group-item bg-dark m-0 p-0">
                    <h9><u>{utils.lbl[`${(this.state.lang)}.14`]}</u> : {this.state.myArticle[0].annonce}</h9>
                  </li>
                  <li className="list-group-item bg-dark m-0 p-0">
                    <h9><u>{utils.lbl[`${(this.state.lang)}.0`]}</u> : {this.state.myArticle[0].quantite}</h9>
                  </li>
                  {this.state.myArticle[0].prices.map(item => (
                    <>
                      <li className="list-group-item bg-dark p-0 m-0">
                        <h9><u>{item.typeprix}</u> : {item.prix} {this.state.devise} {(item.date_fin != "" ? ' - ' + utils.lbl[`${(this.state.lang)}.1`] + ' ' + new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(item.date_debut)) + ' ' + utils.lbl[`${(this.state.lang)}.2`] + ' ' + new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(item.date_fin)) : "")}</h9>
                      </li>
                    </>
                  ))}
                </div>
                <div className="card-footer bg-dark p-0">
                  <div className="container p-0">
                    <div className="row">
                      <div className='col'>
                        <h9 name="description" className='form-controle'>
                          <label className='mt-0'><u>{utils.lbl[`${(this.state.lang)}.3`]}</u></label> : {this.state.myArticle[0].description}
                        </h9>
                      </div>
                    </div>
                    <div className="row ">
                      <div className='col d-flex flex-wrap justify-content-center m-0'>
                        {/********************** Bouton Modifier article **********************/}
                        <Link to={{
                          pathname: '/modifier-article/' + this.state.id_article + '/' + this.state.idAgence,
                          state: { info: this.state.info }
                        }} type="button" className="btn btn-sm btn-outline-primary mr-2">
                          <FontAwesomeIcon className='mr-2' icon={faEdit} />
                          {utils.lbl[`${(this.state.lang)}.4`]}
                        </Link>
                        {/********************** Bouton Cacher article **********************/}
                        <button onClick={this.handleCacher} type="button"
                          className="btn btn-sm btn-outline-secondary mr-2">
                          <FontAwesomeIcon className='mr-2' icon={faEyeSlash} />
                          {this.state.btnCacher}
                        </button>
                        {/********************** Bouton Supprimer article **********************/}
                        <Link to={{
                          pathname: '/article-commercant/' + this.state.idAgence,
                          state: { info: this.state.info }
                        }} type="button" className="btn btn-sm btn-outline-danger" onClick={this.handleDeleteArticle}>
                          <FontAwesomeIcon className='mr-2' icon={faTrash} />
                          {utils.lbl[`${(this.state.lang)}.6`]}
                        </Link>
                      </div>
                    </div>
                    <div className="row ">
                      <div className='col d-flex flex-wrap justify-content-center m-0'>
                        {/********************** Bouton Précédent **********************/}
                        <button onClick={() => this.showArticle(-1)} type="button" data-toggle="tooltip"
                          data-placement="bottom" title={utils.lbl[`${(this.state.lang)}.10`]}
                          className="btn btn-sm btn-secondary mr-3" disabled={this.state.btnPrevOk}>
                          <FontAwesomeIcon className='mr-2' icon={faStepBackward} />
                        </button>
                        {/********************** Bouton Retour vitrine **********************/}
                        <Link to={{
                                pathname: '/article-commercant/' + this.state.idAgence,
                                state: { info: this.state.info }
                              }} type="button" className='btn btn-sm btn-secondary' style={{ color: 'white' }}>
                          {/* <FontAwesomeIcon className='mr-2' icon={faLongArrowAltLeft} /> */}
                          {utils.lbl[`${(this.state.lang)}.5`]}
                        </Link>
                        {/********************** Bouton Suivant **********************/}
                        <button onClick={() => this.showArticle(1)} type="button" data-toggle="tooltip" data-placement="bottom"
                          title={utils.lbl[`${(this.state.lang)}.11`]} className="btn btn-sm btn-secondary ml-3" disabled={this.state.btnNextOk}>
                          <FontAwesomeIcon className='ml-2' icon={faStepForward} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(ArticleCommercantDetail)