export var lbl = [];

lbl["fr.0"]="Code article";
lbl["fr.1"]="Annonce";
lbl["fr.2"]="Prix Vente";
lbl["fr.3"]="Quantité";
lbl["fr.4"]="Prix achat";
lbl["fr.5"]="Rec";
lbl["fr.6"]="Stop";
lbl["fr.7"]="Capture";
lbl["fr.8"]="Promotion";
lbl["fr.9"]="Prix";
lbl["fr.10"]="Début";
lbl["fr.11"]="Fin";
lbl["fr.12"]="Prix";
lbl["fr.13"]="Début";
lbl["fr.14"]="Fin";
lbl["fr.15"]="Statut";
lbl["fr.16"]="To...";
lbl["fr.17"]="Terminer le ";
lbl["fr.18"]="Catégorie";
lbl["fr.19"]="Clientelle";
lbl["fr.20"]="Del";
lbl["fr.21"]="Description audio";
lbl["fr.22"]="Photo publiée du produit";
lbl["fr.23"]="Photo";
lbl["fr.24"]="Effacer";
lbl["fr.25"]="Audio publié du produit";

/* AR */

lbl["ar.0"]="رمز السلعة";
lbl["ar.1"]="إعلان";
lbl["ar.2"]="ثمن البيع";
lbl["ar.3"]="كمية";
lbl["ar.4"]="ثمن الشراء";
lbl["ar.5"]="سجّل";
lbl["ar.6"]="أوقف";
lbl["ar.7"]="تصوير";
lbl["ar.8"]="تخفيض";
lbl["ar.9"]="ثمن";
lbl["ar.10"]="بداية";
lbl["ar.11"]="نهاية";
lbl["ar.12"]="ثمن";
lbl["ar.13"]="بداية";
lbl["ar.14"]="نهاية";
lbl["ar.15"]="حالة";
lbl["ar.16"]="إلى...";
lbl["ar.17"]="إنهاء في ";
lbl["ar.18"]="نوع السلعة";
lbl["ar.19"]="الزبون";
lbl["ar.20"]="حذف";
lbl["ar.21"]="وصف صوتي";
lbl["ar.22"]="الصورة المنشورة للمنتج";
lbl["ar.23"]="صورة";
lbl["ar.24"]="محو";
lbl["ar.25"]="التسجيل المنشور للمنتج";

/* EN */

lbl["en.0"] = "Article code";
lbl["en.1"] = "Advert";
lbl["en.2"] = "Sell price";
lbl["en.3"] = "Quantity";
lbl["en.4"] = "Buy price";
lbl["en.5"] = "ٌRec";
lbl["en.6"] = "Stop";
lbl["en.7"] = "Shot";
lbl["en.8"] = "Promotion";
lbl["en.9"] = "Price";
lbl["en.10"] = "Start";
lbl["en.11"] = "End";
lbl["en.12"] = "Price";
lbl["en.13"] = "Start";
lbl["en.14"] = "End";
lbl["en.15"] = "Status";
lbl["en.16"] = "To...";
lbl["en.17"] = "Ending at ";
lbl["en.18"] = "Article category";
lbl["en.19"] = "Clientele";
lbl["en.20"] = "Del";
lbl["en.21"]="Audio description";
lbl["en.22"]="Published image of product";
lbl["en.23"]="Image";
lbl["en.24"]="Erase";
lbl["en.25"]="Published audio of product";

/* DE */

lbl["de.0"] = "Artikel code";
lbl["de.1"] = "Advert";
lbl["de.2"] = "Verkaufspreis";
lbl["de.3"] = "Menge";
lbl["de.4"] = "Kaufpreis";
lbl["de.5"] = "Rec";
lbl["de.6"] = "Stop";
lbl["de.7"] = "Shot";
lbl["de.8"] = "Promo";
lbl["de.9"] = "Preis";
lbl["de.10"] = "Start";
lbl["de.11"] = "Ende";
lbl["de.12"] = "Preis";
lbl["de.13"] = "Starten";
lbl["de.14"] = "End";
lbl["de.15"] = "Status";
lbl["de.16"] = "To...";
lbl["de.17"] = "Endet bei ";
lbl["de.18"] = "Artikelkategorie";
lbl["de.19"] = "Klientel";
lbl["de.20"] = "Del";
lbl["de.21"]="Audio description";
lbl["de.22"]="Published image of product";
lbl["de.23"]="Image";
lbl["de.24"]="Erase";
lbl["de.25"]="Published audio of product";

