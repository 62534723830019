import React, { Component } from 'react';
import axios from 'axios';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as utils from '../lang/compteForm';
import * as comons from '../component/commun';
import Moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class CompteForm extends Component {
  atob = require('atob');
  togglePassword = document.querySelector('#togglePassword');
  password = document.querySelector('#password');

  constructor(props) {
    super(props);
    if (typeof this.props.location.infoUser != 'undefined') {
      this.state = {
        info: this.props.location.state.info,
        lang: this.props.location.state.info.lang,

        id_titre_pers: (typeof this.props.location.infoUser.id_titrepers == 'undefined' ? "" : this.props.location.infoUser.id_titrepers),
        nom: (typeof this.props.location.infoUser.nom == 'undefined' ? "" : this.props.location.infoUser.nom),
        prenoms: (typeof this.props.location.infoUser.prenoms == 'undefined' ? "" : this.props.location.infoUser.prenoms),
        adress: (typeof this.props.location.infoUser.adr == 'undefined' ? "" : this.props.location.infoUser.adr),
        id_ville: (typeof this.props.location.infoUser.id_ville == 'undefined' ? "" : this.props.location.infoUser.id_ville),
        cp: (typeof this.props.location.infoUser.cp == 'undefined' ? "" : this.props.location.infoUser.cp),
        // id_profession: (typeof this.props.location.infoUser.id_profession == 'undefined' ? "" : this.props.location.infoUser.id_profession),
        datedenaissance: (typeof this.props.location.infoUser.datedenaissance == 'undefined' ? "" : this.props.location.infoUser.datedenaissance),
        mail: (typeof this.props.location.infoUser.mail == 'undefined' ? "" : this.props.location.infoUser.mail),
        tel: (typeof this.props.location.infoUser.tel == 'undefined' ? "" : this.props.location.infoUser.tel),
        id_suivi: (typeof this.props.location.infoUser.id_suivi == 'undefined' ? "" : this.props.location.infoUser.id_suivi),
        id_lang: (typeof this.props.location.infoUser.id_lang == 'undefined' ? "" : this.props.location.infoUser.id_lang),
        login: (typeof this.props.location.infoUser.login == 'undefined' ? "" : this.props.location.infoUser.login),
        password: atob(this.props.location.infoUser.password),

        profil: this.props.location.state.info.comptes[0].profil,

        disableField: true,
        titres: [],
        suiviData: [],
        city: [],
        languages: [],
        job: [],
        hidden: true,
      };
    }
    else {
      this.state = {
        info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
        lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),

        id_titre_pers: '',
        nom: '',
        prenoms: '',
        adress: '',
        id_ville: '',
        cp: '',
        // id_profession: '',
        datedenaissance: '',
        mail: '',
        tel: '',
        id_suivi: [],
        id_lang: '',
        login: '',
        password: '',

        profil: 'Client',

        disableField: false,
        titres: [],
        suiviData: [],
        city: [],
        languages: [],
        job: [],
        vl: ''
      };
    }
    this.state.info.lang = this.state.lang;
  }

  getSuiviCommande = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/commande_codemode/${this.state.lang}`).then(res => {
      //console.warn(res.data)
      this.setState({
        suiviData: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getProfession = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/profession/${this.state.lang}`).then(res => {
      //console.warn(res.data)
      this.setState({
        job: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getVille = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/ville/${this.state.lang}`).then(res => {
      //console.warn(res.data)
      this.setState({
        city: res.data
      });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      })
    })
  }

  getTitrePers = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/titre_pers/${this.state.lang}`).then(res => {
      //console.warn(res.data)
      this.setState({
        titres: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getLangue = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/langue/${this.state.lang}`).then(res => {
      //console.warn(res.data)
      this.setState({
        languages: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    //this.getProfession();
    this.getSuiviCommande();
    this.getVille();
    this.getTitrePers();
    this.getLangue();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSuivi = (e, s) => {
    const id_suivi = this.state.id_suivi;

    if (e.target.checked) {
      id_suivi.push(parseInt(s.id));
    }
    else {
      const index = id_suivi.findIndex((ch) => ch === s.id);
      id_suivi.splice(index, 1);
    }
    this.setState({ id_suivi });
  }

  handleCategorie = e => {
    this.setState({
      id_titre_pers: e.target.value
    });
  }

  togglePassword = (e) => {
    // toggle the type attribute
    const type = this.password.getAttribute('type') === 'password' ? 'text' : 'password';
    this.password.setAttribute('type', type);
    // toggle the eye slash icon
    this.classList.toggle('fa-eye-slash');
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }

  handleDate = e => {
    const dt1 = e.target.value
    const system = Moment(Date()).format('YYYY-MM-DD')

    if (dt1 >= system) {
      this.setState({
        datedenaissance: system
      });
    } else if (dt1 < system) {
      this.setState({
        datedenaissance: dt1
      });
    }
  }

  onChangeVille = (e) => {
    this.state.id_ville = '';
    for (let i = 0; i < this.state.city.length; i++) {
      if (this.state.city[i].lbl == e.target.value) {
        this.setState({ id_ville: this.state.city[i].id });
        break;
      }
    }
  }

  checkValidity = (fieldName, fieldPattern, fieldMsgErr) => {

  }



  render() {
    // console.log(this.props)
    // console.log(this.state)
    const { titres, city, languages, suiviData } = this.state

    this.state.city.find(element => {
      if (element.id == this.state.id_ville) {
        this.state.vl = element.lbl
      };
    });

    return (
      <>

        <div className="container-sm">
          <div className="row">
            <div className="col custom-control custom-radio d-flex justify-content-row">
              {titres.map(item => (
                <label style={{ color: 'black' }} className="ml-3">
                  <div>
                    <input type="radio" id={`titre${item.id}`} name="titreId" value={item.id} checked={this.state.id_titre_pers == item.id}
                      onChange={this.handleCategorie} style={{ zoom: 1.5 }} />
                    {item.lbl}
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-column justify-content-center'>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.0`]}</span>
                </div>
                <input type="text" name='nom' value={this.state.nom} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('nom', "[A-Za-z][A-Za-z' -]+", utils.lbl[`${(this.state.lang)}.13`])}
                  required pattern="[A-Za-z][A-Za-z' -]+" autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.1`]}</span>
                </div>
                <input type="text" name='prenoms' value={this.state.prenoms} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('prenoms', "[A-Za-z][A-Za-z' -]+", utils.lbl[`${(this.state.lang)}.13`])}
                  required pattern="[A-Za-z][A-Za-z' -]+" autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                </div>
                <input type="date" name='datedenaissance' value={this.state.datedenaissance} onChange={this.handleDate} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                </div>
                <input type="email" name='mail' value={this.state.mail} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  required disabled={this.state.disableField} autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.4`]}</span>
                </div>
                <input type="email" name='login' value={this.state.mail} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                </div>
                <input type={this.state.hidden ? 'password' : 'text'} name='password' value={this.state.password} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('password', "", utils.lbl[`${(this.state.lang)}.13`])}
                  required autoComplete='false' />
                <FontAwesomeIcon onClick={this.toggleShow} icon={this.state.hidden ? faEyeSlash : faEye} style={{
                  color: 'black', marginTop: '8px',
                  marginLeft: '5px'
                }} />
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-column justify-content-center'>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.6`]}</span>
                </div>
                <input type="tel" name='tel' value={this.state.tel} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('tel', "", utils.lbl[`${(this.state.lang)}.13`])}
                  required minLength="10" maxLength="15" autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.7`]}</span>
                </div>
                <input type="text" name='adress' value={this.state.adress} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  onBlur={this.checkValidity('adress', "", utils.lbl[`${(this.state.lang)}.13`])}
                  required autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.8`]}</span>
                </div>
                <input type="text" name="nom_ville" onChange={this.onChangeVille}
                  defaultValue={
                    this.state.vl
                  }

                  // value={this.state.nom_ville}
                  className="form-control" list="data"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  placeholder={utils.lbl[`${(this.state.lang)}.14`]} />
                <datalist id="data" name='id_ville' value={this.state.id_ville} required>
                  {city.map((option) => (
                    <option key={option.id} value={option.lbl} id={option.id} />
                  ))}
                </datalist>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.9`]}</span>
                </div>
                <input type="number" name='cp' value={this.state.cp} onChange={this.handleChange} className="form-control"
                  aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                  required min="10000" max="99999" autoComplete='false' />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.10`]}</span>
                </div>
                <select name='id_lang' value={this.state.id_lang} onChange={this.handleChange} className="form-control" required>
                  <option value="">{utils.lbl[`${(this.state.lang)}.14`]}</option>
                  {languages.map((option) => (
                    <option key={option.id} value={option.id}>{option.lbl}</option>
                  ))}
                </select>
              </div>
              {this.state.profil == 'Client' ?
                <>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.12`]}</span>
                    </div>
                    <div className="row form-group input-group">
                      {suiviData.map(item => (
                        <div className="form-check">
                          <label style={{ color: 'black' }} className="form-check-label">
                            <input type="checkbox" name="suiviId" className="mr-2" id={`suivi${item.id}`}
                              checked={this.state.id_suivi.find((ch) => ch === item.id)}
                              onChange={(e) => this.handleSuivi(e, item)} style={{ zoom: 1 }} />
                            {item.lbl}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
                : ''}
            </div>
          </div>
        </div>

      </>
    )
  }
}