export var lbl = [];

lbl["fr.0"] = "Tous";
lbl["fr.1"] = "Type de commerce";
lbl["fr.2"] = "Clientelle";
lbl["fr.3"] = "Choisir une ville";
lbl["fr.4"] = "ٌRecheche...";
lbl["fr.5"] = "Aucun résultat trouvé";
lbl["fr.6"] = "Choisir un pays";

/* AR */

lbl["ar.0"] = "الكل";
lbl["ar.1"] = "قطاع التجارة";
lbl["ar.2"] = "الزبون";
lbl["ar.3"] = "اختر مدينة";
lbl["ar.4"] = "...بحث";
lbl["ar.5"] = "لم يعثر على نتيجة للبحث";
lbl["ar.6"] = "اختر بلدا";

/* EN */

lbl["en.0"] = "All";
lbl["en.1"] = "Type of business";
lbl["en.2"] = "targeted clients";
lbl["en.3"] = "Choose city";
lbl["en.4"] = "Search...";
lbl["en.5"] = "No result found";
lbl["en.6"] = "Choose state";

/* DE */

lbl["de.0"] = "All";
lbl["de.1"] = "Art des Geschäfts";
lbl["de.2"] = "Kunden";
lbl["de.3"] = "Choose city";
lbl["de.4"] = "Search...";
lbl["de.5"] = "No result found";
lbl["de.6"] = "Choose state";

