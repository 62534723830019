export var lbl = [];

lbl["fr.0"] = "Raison sociale";
lbl["fr.1"] = "Identifiant société national";
lbl["fr.2"] = "Site Web";
lbl["fr.3"] = "Adresse";
lbl["fr.4"] = "Tél";
lbl["fr.5"] = "Mél";
lbl["fr.6"] = "Code postal";
lbl["fr.7"] = "Ville";
lbl["fr.8"] = "Type de commerce";
lbl["fr.9"] = "Clientelle";
lbl["fr.10"] = "Photo de présentation";
lbl["fr.11"] = "Upload";
lbl["fr.12"] = "Service de livraison";
lbl["fr.13"] = "Frais de livraison";
lbl["fr.14"] = "Couleur de Vitrine";
lbl["fr.15"] = "Suivi des commandes par";
lbl["fr.16"] = "Horaires d'ouverture";
lbl["fr.17"] = "De";
lbl["fr.18"] = "A";
lbl["fr.19"] = "Lundi";
lbl["fr.20"] = "Mardi";
lbl["fr.21"] = "Mercredi";
lbl["fr.22"] = "Jeudi";
lbl["fr.23"] = "Vendredi";
lbl["fr.24"] = "Samedi";
lbl["fr.25"] = "Dimanche";
lbl["fr.26"] = "Active";
lbl["fr.27"] = "#";
lbl["fr.28"] = "Ajouter";
lbl["fr.29"] = "Supprimer";
lbl["fr.30"] = "Conditions Générales de Vente";
lbl["fr.31"] = "Champ non valide";
lbl["fr.32"] = "Veuillez choisir";

/* AR */

lbl["ar.0"] = "اسم المتجر";
lbl["ar.1"] = "رقم الشركة الوطني";
lbl["ar.2"] = "موقع إلكتروني";
lbl["ar.3"] = "عنوان";
lbl["ar.4"] = "هاتف";
lbl["ar.5"] = "بريد إلكتروني";
lbl["ar.6"] = "رمز بريدي";
lbl["ar.7"] = "مدينة";
lbl["ar.8"] = "قطاع التجارة";
lbl["ar.9"] = "الزبون";
lbl["ar.10"] = "ادخل صورة للمحل";
lbl["ar.11"] = "إرسال الصورة";
lbl["ar.12"] = "خدمة التوصيل";
lbl["ar.13"] = "تكاليف التوصيل";
lbl["ar.14"] = "لون واجهة العرض";
lbl["ar.15"] = "تتبع الطلبات ب";
lbl["ar.16"] = "أوقات العمل";
lbl["ar.17"] = "من";
lbl["ar.18"] = "إلى";
lbl["ar.19"] = "الاثنين";
lbl["ar.20"] = "الثلاثاء";
lbl["ar.21"] = "الأربعاء";
lbl["ar.22"] = "الخميس";
lbl["ar.23"] = "الجمعة";
lbl["ar.24"] = "السبت";
lbl["ar.25"] = "الأحد";
lbl["ar.26"] = "مفعل";
lbl["ar.27"] = "#";
lbl["ar.28"] = "إضافة";
lbl["ar.29"] = "حذف";
lbl["ar.30"] = "الشروط العامة للبيع";
lbl["ar.31"] = "خطأ في المعلومة";
lbl["ar.32"] = "المرجو الاختيار";

/* EN */

lbl["en.0"] = "Social reason";
lbl["en.1"] = "Client Number";
lbl["en.2"] = "Web Site";
lbl["en.3"] = "Address";
lbl["en.4"] = "Mobile";
lbl["en.5"] = "Email";
lbl["en.6"] = "Postal codel";
lbl["en.7"] = "City";
lbl["en.8"] = "Type of business";
lbl["en.9"] = "Clientele";
lbl["en.10"] = "Fill in the presentation photo";
lbl["en.11"] = "Upload";
lbl["en.12"] = "Delivery";
lbl["en.13"] = "Shipping cost";
lbl["en.14"] = "Showcase color";
lbl["en.15"] = "Order tracking by :";
lbl["en.16"] = "Opening time :";
lbl["en.17"] = "From";
lbl["en.18"] = "To";
lbl["en.19"] = "Monday";
lbl["en.20"] = "Tuesday";
lbl["en.21"] = "Wednesday";
lbl["en.22"] = "Thursday";
lbl["en.23"] = "Friday";
lbl["en.24"] = "Saturday";
lbl["en.25"] = "Sunday";
lbl["en.26"] = "Actif";
lbl["en.27"] = "#";
lbl["en.28"] = "Add";
lbl["en.29"] = "Delete";
lbl["en.30"] = "General Conditions of Sale";
lbl["en.31"] = "Invalid field";
lbl["en.32"] = "Please select";

/* DE */

lbl["de.0"] = "Firmenname";
lbl["de.1"] = "Kundennummer";
lbl["de.2"] = "Seite";
lbl["de.3"] = "Adresse";
lbl["de.4"] = "Telefonnummer";
lbl["de.5"] = "Email";
lbl["de.6"] = "Postleitzahl";
lbl["de.7"] = "Stadt";
lbl["de.8"] = "Art des Geschäft";
lbl["de.9"] = "Kunden";
lbl["de.10"] = "Füllen Sie das Präsentationsfoto aus";
lbl["de.11"] = "Hochladen";
lbl["de.12"] = "Lieferung";
lbl["de.13"] = "Versandkosten";
lbl["de.14"] = "Schaufenster Farbe";
lbl["de.15"] = "Suivi des commandes par :";
lbl["de.16"] = "Arbeitszeit :";
lbl["de.17"] = "Von";
lbl["de.18"] = "Bis";
lbl["de.19"] = "Montag";
lbl["de.20"] = "Dienstag";
lbl["de.21"] = "Mittwoch";
lbl["de.22"] = "Donnerstag";
lbl["de.23"] = "Freitag";
lbl["de.24"] = "Samstag";
lbl["de.25"] = "Sonntag";
lbl["de.26"] = "Aktiv";
lbl["de.27"] = "#";
lbl["de.28"] = "Hinzufügen";
lbl["de.29"] = "Löschen";
lbl["de.30"] = "General Conditions of Sale";
lbl["de.31"] = "Invalid field";
lbl["de.32"] = "Please select";

