export var lbl = [];

lbl["fr.0"] = "Création magasin";
lbl["fr.1"] = "Authentification";
lbl["fr.2"] = "Nom";
lbl["fr.3"] = "Prénoms";
lbl["fr.4"] = "Login (mail)";
lbl["fr.5"] = "Password";
lbl["fr.6"] = "Créer magasin";
lbl["fr.7"] = "Annuler";
lbl["fr.8"] = "Alerte !";
lbl["fr.9"] = "Votre magasin a été créé avec succès";
lbl["fr.10"] = "OK";
lbl["fr.11"] = "Je reconnais avoir lu, compris et accepté ";
lbl["fr.12"] = "les conditions générales d'utilisation (CGU)";
lbl["fr.13"] = "Accepter";
lbl["fr.14"] = "Veuillez compléter la saisie des données";

/* AR */

lbl["ar.0"] = "فتح متجر";
lbl["ar.1"] = "حساب التعريف";
lbl["ar.2"] = "الاسم العائلي";
lbl["ar.3"] = "الإسم الشخصي";
lbl["ar.4"] = "تعريف الدخول (البريد)";
lbl["ar.5"] = "الكلمة السرية";
lbl["ar.6"] = "إنشاء المتجر";
lbl["ar.7"] = "إلغاء";
lbl["ar.8"] = "إنذار !";
lbl["ar.9"] = "تم بنجاح فتح المتجر";
lbl["ar.10"] = "حسنا";
lbl["ar.11"] = "أقر أني اطلعت و فهمت و وافقت ";
lbl["ar.12"] = "على الشروط العامة للاستعمال";
lbl["ar.13"] = "موافق";
lbl["ar.14"] = "المرجو إتمام إدخال المعلومات";

/* EN */

lbl["en.0"] = "Create shop";
lbl["en.1"] = "Authentication";
lbl["en.2"] = "First name";
lbl["en.3"] = "Last name";
lbl["en.4"] = "Login (email)";
lbl["en.5"] = "Password";
lbl["en.6"] = "Create shop";
lbl["en.7"] = "Cancel";
lbl["en.8"] = "Alert !";
lbl["en.9"] = "Your store has been successfully created";
lbl["en.10"] = "OK";
lbl["en.11"] = "I acknowledge having read, understood and accepted ";
lbl["en.12"] = "the general conditions of use (GCU)";
lbl["en.13"] = "Accept";
lbl["en.14"] = "Please complete the data entry";

/* DE */

lbl["de.0"] = "Shop Erstellen";
lbl["de.1"] = "Authentifizierung";
lbl["de.2"] = "Vorname";
lbl["de.3"] = "Nachname";
lbl["de.4"] = "Anmeldung (email)";
lbl["de.5"] = "Passwort";
lbl["de.6"] = "Shop Erstellen";
lbl["de.7"] = "Abbrechen";
lbl["de.8"] = "Alert !";
lbl["de.9"] = "Ihr Shop Wurde Erfolgreich Erstellt";
lbl["de.10"] = "OK";
lbl["de.11"] = "I have read, understand and accept ";
lbl["de.12"] = "the general conditions of use (GCU)";
lbl["de.13"] = "Accept";
lbl["de.14"] = "Bitte vervollständigen Sie die Dateneingabe";

