import React, { Component } from 'react';
import Moment from 'moment';
import './creation.css'
import 'react-html5-camera-photo/build/css/index.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecordVinyl, faStop, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as utils from '../lang/articleForm';
import * as comons from '../component/commun';


// This is the main thing to recorde the audio 'MediaRecorder' API 
var mediaRecorder;
var audioData;

const videoConstraints = {
  audio: false,
  video: {
    facingMode: { ideal: "environment" },
    width: 320,
    height: 240
  }
};

const audioConstraints = {
  audio: true
};

export default class ArticleForm extends Component {
  constructor(props) {
    super(props);
    if (typeof this.props.location.id_article != 'undefined' && this.props.location.id_article != '') {
      this.state = {
        info: this.props.location.state.info,
        lang: this.props.location.state.info.lang,
        id_article: this.props.location.id_article,
        id_agence: this.props.location.id_agence_forArticle,
        clientelle: [],
        articleCat: [],
        isRecording: false,
        datef: '',
        blobURL: '',
        dataUri: '',
        photoInDB: '',
        audioInDB: '',
        id_photo: '',
        id_audio: '',

        id_clientelle: [],
        promoTab: [],
        dDebutPromo: '',
        dFinPromo: '',
        promotion: '',
        promo: '',

        articleData: this.props.location.articleData
      };
    }
    else {
      this.state = {
        info: this.props.location.state.info,
        lang: this.props.location.state.info.lang,
        id_article: '',
        id_agence: this.props.location.id_agence_forArticle,
        clientelle: [],
        articleCat: [],
        isRecording: false,
        datef: '',
        blobURL: '',
        dataUri: '',
        photoInDB: '',
        audioInDB: '',
        id_photo: '',
        id_audio: '',

        id_clientelle: [],
        promoTab: [],
        dDebutPromo: '',
        dFinPromo: '',
        promotion: '',
        promo: ''
      };
    }
  }

  setArticleData = () => {
    var today = new Date();
    var date_du_jour = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

    this.state.articleData.prices.forEach(el => {
      if (el.id_type_prix == 1) {
        this.setState({
          _keyAchat: el._key,
          dateDebutAchat: el.date_debut,
          dateFinAchat: el.date_fin,
          id_type_prix_Achat: el.id_type_prix,
          achat: el.prix
        });
      }
      else if (el.id_type_prix == 2) {
        this.setState({
          _keyVente: el._key,
          dateDebutVente: el.date_debut,
          dateFinVente: el.date_fin,
          id_type_prix_Vente: el.id_type_prix,
          vente: el.prix
        });
      }
      else if (el.id_type_prix == 3) {
        this.state.promoTab.push({
          _keyPromo: el._key,
          dateDebutPromo: el.date_debut,
          dateFinPromo: el.date_fin,
          id_type_prix_Promo: el.id_type_prix,
          promo: el.prix,
          statusPromo: (el.date_debut <= date_du_jour && date_du_jour <= el.date_fin ? "en cours" : "non débutée")
        });
      }
    });
    this.setState({
      dDebutPromo: '',
      dFinPromo: '',
      promotion: '',

      annonce: this.state.articleData.annonce,
      quantite: this.state.articleData.quantite,
      description: this.state.articleData.description,
      id_clientelle: this.state.articleData.idClientel,
      idcatarticle: this.state.articleData.idCatArticle,
      photoInDB: this.state.articleData.photo,
      audioInDB: this.state.articleData.audio,
      id_photo: this.state.articleData.idPhoto,
      id_audio: this.state.articleData.idAudio,
      code_article: this.state.articleData.code_article
      // id_agence fait
    });
  }

  getClientelle = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/clientelle/${this.state.id_agence}/${this.state.lang}`).then(res => {
      this.setState({ clientelle: res.data });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getArticleCat = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/articlecat/${this.state.id_agence}/${this.state.lang}`).then(res => {
      this.setState({ articleCat: res.data });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  initCam = () => {
    var video = document.getElementById('video');

    try {
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (videoConstraints) {

          // First get ahold of the legacy getUserMedia, if present
          navigator.getUserMedia = navigator.getUserMedia ||
                                   navigator.webkitGetUserMedia ||
                                   navigator.mozGetUserMedia ||
                                   navigator.msgGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!navigator.getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
//        return new Promise(function(resolve, reject) {
//          getUserMedia.call(navigator, constraints, resolve, reject);
//        });
          if (navigator.getUserMedia) {
            return navigator.getUserMedia(videoConstraints, function (videoStreamObj) {
              // Older browsers may not have srcObject
              if ("srcObject" in video) {
                window.stream = videoStreamObj;
                video.srcObject = videoStreamObj;
              } else {
                window.stream = videoStreamObj;
                // Avoid using this in new browsers, as it is going away.
                video.src = window.URL.createObjectURL(videoStreamObj);
              }
            }, function () { console.log("fail load cam old browser"); });
          }
        }
      }

      navigator.mediaDevices.getUserMedia(videoConstraints)
        .then(function (videoStreamObj) {
          // Older browsers may not have srcObject
          if ("srcObject" in video) {
            window.stream = videoStreamObj;
            video.srcObject = videoStreamObj;
          } else {
            window.stream = videoStreamObj;
            // Avoid using this in new browsers, as it is going away.
            video.src = window.URL.createObjectURL(videoStreamObj);
          }
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    } catch (e) {
      console.log(e.toString());
    }
  }

  captureImage = () => {
    var video = document.getElementById('video');
    var canvas = document.getElementById('canvas');

    try {
      var context = canvas.getContext('2d');
      context.drawImage(video, 0, 20, 320, 240);
      var dataURL = canvas.toDataURL('image/jpeg', 0.3);
      this.setState({ dataUri: dataURL });
    } catch (e) {
      console.log(e.toString());
    }
  }

  deleteImage = () => {
    var canvas = document.getElementById('canvas');

    try {
      var context = canvas.getContext('2d');
      context.clearRect(0, 20, 320, 240);
      this.setState({ dataUri: '' });
    } catch (e) {
      console.log(e.toString());
    }
  }

  initAud = () => {
    try {
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (audioConstraints) {

          // First get ahold of the legacy getUserMedia, if present
          navigator.getUserMedia = navigator.getUserMedia ||
                                   navigator.webkitGetUserMedia ||
                                   navigator.mozGetUserMedia ||
                                   navigator.msgGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!navigator.getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          if (navigator.getUserMedia) {
            return navigator.getUserMedia(audioConstraints, function (audioStreamObj) {
              let audio = document.getElementById('audioListener');
              // Older browsers may not have srcObject
              if ("srcObject" in audio) {
                audio.srcObject = audioStreamObj;
              }
              else {
                // Avoid using this in new browsers, as it is going away.
                audio.src = window.URL.createObjectURL(audioStreamObj);
              }
        
              audio.onloadedmetadata = () => {
                audio.play();
              };
        
              let playAudio = document.getElementById('adioPlay');
        
              // This is the main thing to recorde the audio 'MediaRecorder' API 
              mediaRecorder = new MediaRecorder(audioStreamObj);
              // Pass the audio stream
        
              // If audio data available then push it to the chunk array 
              mediaRecorder.ondataavailable = (ev) => {
                dataArray.push(ev.data);
              }
        
              // Chunk array to store the audio data  
              let dataArray = [];
        
              mediaRecorder.onstop = () => {
                let reader = new FileReader();
        
                // blob of type mp3 
                audioData = new Blob(dataArray, { 'type': 'audio/mp3;' });
        
                reader.readAsDataURL(audioData); // converts the blob to base64 and calls onload
        
                reader.onloadend = () => {
                  this.setState({ blobURL: reader.result });
                };
                // After fill up the chunk array make it empty 
                dataArray = [];
        
                // Older browsers may not have srcObject
                //if ("srcObject" in audio) {
                //  playAudio.srcObject = window.URL.createObjectURL(audioData);
                //}
                //else {
                  // Avoid using this in new browsers, as it is going away.
                  playAudio.src = window.URL.createObjectURL(audioData);
                //}
              }
            }, function () { console.log("fail load cam old browser"); });
          }
        }
      }

      navigator.mediaDevices.getUserMedia(audioConstraints).then(audioStreamObj => {
        let audio = document.getElementById('audioListener');
        // Older browsers may not have srcObject
        if ("srcObject" in audio) {
          audio.srcObject = audioStreamObj;
        }
        else {
          // Avoid using this in new browsers, as it is going away.
          audio.src = window.URL.createObjectURL(audioStreamObj);
        }

        audio.onloadedmetadata = () => {
          audio.play();
        };

        let playAudio = document.getElementById('adioPlay');

        // This is the main thing to recorde the audio 'MediaRecorder' API 
        mediaRecorder = new MediaRecorder(audioStreamObj);
        // Pass the audio stream

        // If audio data available then push it to the chunk array 
        mediaRecorder.ondataavailable = (ev) => {
          dataArray.push(ev.data);
        }

        // Chunk array to store the audio data  
        let dataArray = [];

        mediaRecorder.onstop = () => {
          let reader = new FileReader();

          // blob of type mp3 
          audioData = new Blob(dataArray, { 'type': 'audio/mp3;' });

          reader.readAsDataURL(audioData); // converts the blob to base64 and calls onload

          reader.onloadend = () => {
            this.setState({ blobURL: reader.result });
          };
          // After fill up the chunk array make it empty 
          dataArray = [];

          // Older browsers may not have srcObject
          //if ("srcObject" in audio) {
          //  playAudio.srcObject = window.URL.createObjectURL(audioData);
          //}
          //else {
            // Avoid using this in new browsers, as it is going away.
            playAudio.src = window.URL.createObjectURL(audioData);
          //}
        }
      }).catch(err => {
        console.log(err.name, err.message);
      });
    } catch (e) {
      console.log(e.toString());
    }
  }

  componentDidMount = () => {
    this.getClientelle();
    this.getArticleCat();
    this.initCam();
    this.initAud();
    if (this.state.id_article != '') {
      this.setArticleData();
    }

    this.interval = setInterval(() => {
      if (this.state.isRecording) {
        mediaRecorder.stop();
        this.setState({ isRecording: false });
      }
    }, 16000)
  }

  componentDidUpdate = () => {
    const dataUri = this.state
    if (dataUri.length > 0) {
      this.setState({
        dataUri
      })
    } else if (dataUri.length === 0) {
      this.setState({
        dataUri: this.state.photoInDB
      })
    }
  }

  start = () => {
    if (this.state.isRecording) {
      console.log('Permission Denied');
    } else {
      mediaRecorder.start();
      this.setState({ isRecording: true });
    }
  }

  stop = () => {
    if (this.state.isRecording) {
      mediaRecorder.stop();
      this.setState({ isRecording: false });
    } else {
      console.log('Permission Denied');
    }
  }

  delete = () => {
    if (this.state.isRecording) {
      console.log('Permission Denied');
    } else {
      mediaRecorder.start();
      mediaRecorder.stop();
      this.setState({ blobURL: '' });
      this.state.blobURL = '';
    }
  }

  handleCategorie = e => {
    this.setState({
      idcatarticle: e.target.value
    });
  }

  handleClientelle = (e, c) => {
    const id_clientelle = this.state.id_clientelle;

    if (e.target.checked) {
      id_clientelle.push(c.id)
    }
    else {
      const index = id_clientelle.findIndex((ch) => ch === c.id);
      id_clientelle.splice(index, 1);
    }
    this.setState({ id_clientelle });
  }

  handleDate = e => {
    const dt1 = e.target.value
    const system = Moment(Date()).format('YYYY-MM-DD')
    const dt4 = this.state.dDebutPromo
    if (dt1 >= system && dt1 <= dt4) {
      this.setState({
        datef: dt1
      });
    } else if (dt1 >= system && dt1 >= dt4) {
      this.setState({
        datef: dt1,
        dDebutPromo: dt1
      });
    } else if (system > dt1) {
      this.setState({
        datef: system
      });
    }
  }

  handleDate1 = e => {
    const dt1 = e.target.value
    const system = Moment(Date()).format('YYYY-MM-DD')
    const datef = this.state.datef
    if (dt1 > datef && dt1 >= system) {
      this.setState({
        dDebutPromo: dt1
      });
    } else {
      this.setState({
        dDebutPromo: datef
      });
    }
  }

  handleDate2 = e => {
    const dt1 = e.target.value
    const dt4 = this.state.dDebutPromo
    if (dt1 >= dt4) {
      this.setState({
        dFinPromo: dt1
      });
    } else if (dt1 < dt4) {
      this.setState({
        dFinPromo: ''
      });
    }
  }



  render() {

    const { clientelle, articleCat } = this.state

    return (
      <>

        <div className="container-fluid mt-5">
          <div className="col">
            <form className="row">
              <div className='col-lg-6'>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {utils.lbl[`${(this.state.lang)}.0`]}
                    </span>
                  </div>
                  <input type="text" name="code_article" value={this.state.code_article} onChange={this.onChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Code article" maxLength='20' />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {utils.lbl[`${(this.state.lang)}.3`]}
                    </span>
                  </div>
                  <input type="number" name="quantite" value={this.state.quantite} onChange={this.onChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroupPrepend" placeholder="Quantite" min="0" required />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {utils.lbl[`${(this.state.lang)}.4`]}
                    </span>
                  </div>
                  <input type="number" name="achat" value={this.state.achat} onChange={this.onChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Prix Achat" min="0" required />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {utils.lbl[`${(this.state.lang)}.2`]}
                    </span>
                  </div>
                  <input type="number" name="vente" value={this.state.vente} onChange={this.onChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Prix Vente" min="0" required />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {utils.lbl[`${(this.state.lang)}.1`]}
                    </span>
                  </div>
                  <input type="text" name="annonce" value={this.state.annonce} onChange={this.onChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroupPrepend" placeholder="Annonce" required />
                </div>
                <div className="form-group input-group">
                  <textarea type="text" name="description" value={this.state.description} onChange={this.onChange} className="form-control" 
                    placeholder="Description" rows="4" />
                </div>
              </div>
            </form>
            {/*------------------------------------- the audio ----------------------------- */}
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h4>{utils.lbl[`${(this.state.lang)}.21`]}</h4>
              </div>
              <div className="d-flex flex-row">
                <div className="card-body row justify-content-center">
                  {/*--------------------------- the old one ---------------------------       */}
                  {this.state.id_article === '' ? 
                    ''
                  :
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                      <div className="input-group mb-3">
                        <audio name='oldAudioPlay' src={this.state.audioInDB} controls hidden={this.state.id_article === ''}></audio>
                        <figcaption className="figure-caption">{utils.lbl[`${(this.state.lang)}.25`]}</figcaption>
                      </div>
                    </div>
                  }
                  {/*--------------------------- the controlers ---------------------------       */}
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                    <div className="input-group mb-3">
                      <div className="audio-wrap">
                        <audio id='audioListener' controls="controls" muted={true} hidden></audio>
                      </div>
                      <div className="controller d-flex flex-row">
                        <button className="btn btn-danger ml-2 mr-3 mt-0" onClick={this.start} disabled={this.state.isRecording}>
                          {utils.lbl[`${(this.state.lang)}.5`]}<FontAwesomeIcon className="ml-2" icon={faRecordVinyl} />
                        </button>
                        <button className="btn btn-secondary mr-3 mt-0" onClick={this.stop} disabled={!this.state.isRecording}>
                          {utils.lbl[`${(this.state.lang)}.6`]}<FontAwesomeIcon className="ml-2" icon={faStop} />
                        </button>
                        <button className="btn btn-primary mr-3 mt-0" onClick={this.delete} disabled={this.state.blobURL == ''}>
                          {utils.lbl[`${(this.state.lang)}.20`]}<FontAwesomeIcon className="ml-2" icon={faTrash} />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*--------------------------------- the new one -------------------------- */}
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                    <div className="input-group mb-3">
                      <audio id="adioPlay" controls></audio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*------------------------------------- the photo ----------------------------- */}
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h4>{utils.lbl[`${(this.state.lang)}.23`]}</h4>
              </div>
              <div className="d-flex flex-row">
                <div className="card-body row justify-content-center">
                  {/*--------------------------- the old one---------------------------       */}
                  {this.state.id_article === '' ? 
                    ''
                  :
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                      <figure className="figure flex-row justify-content-center" hidden={this.state.id_article === ''}>
                        <img src={this.state.photoInDB} className="figure-img rounded" width='320' height='280'
                             alt="A generic square placeholder with rounded corners." hidden={this.state.id_article === ''} />
                         <figcaption className="figure-caption">{utils.lbl[`${(this.state.lang)}.22`]}</figcaption>
                      </figure>
                    </div>
                  }
                  {/*--------------------------------- the controllers -------------------------- */}
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                    <div className="card">
                      <video id="video" width='320' height='280' playsInline autoPlay></video>
                      <button id="snap" onClick={this.captureImage}>
                        {utils.lbl[`${(this.state.lang)}.7`]}
                      </button>
                    </div>
                  </div>
                  {/*--------------------------------- the new one -------------------------- */}
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
                    <div className="card">
                      <canvas id="canvas" width='320' height='280'></canvas>
                      <button id="snap" onClick={this.deleteImage}>
                        {utils.lbl[`${(this.state.lang)}.24`]}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*------------------------------------- Nouvelle Promotion -------------------------------- */}
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h4>{utils.lbl[`${(this.state.lang)}.8`]}</h4>
              </div>
              <div className="d-flex flex-row">
                <div className="card-body row">
                  <div className="col-lg-4 col-md-4 col-1-sm">
                    <div className="input-group input-group-sm mb-3 mr-5">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.9`]}</span>
                      </div>
                      <input type="number" name='promotion' value={this.state.promotion} onChange={this.onChange} className="form-control"
                        aria-label="Small" aria-describedby="inputGroupPrepend" placeholder="Prix" min="0" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-1-sm">
                    <div className="input-group input-group-sm mb-3 mr-5">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.10`]}</span>
                      </div>
                      <input type="date" name='dDebutPromo' value={this.state.dDebutPromo} onChange={this.handleDate1} className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-1-sm">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.11`]}</span>
                      </div>
                      <input type="date" name='dFinPromo' value={this.state.dFinPromo} onChange={this.handleDate2} className="form-control"
                        aria-label="Small" aria-describedby="inputGroupPrepend" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*------------------------------------- Anciennes promos -------------------------------- */}
            <div className="card">
              <table className="table table-sm table-light" hidden={this.state.id_article == '' || this.state.promoTab.length == 0}>
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.12`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.13`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.14`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.15`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.16`]}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.promoTab.map(item => (
                    <tr key={item.id}>
                      <td>{item.promo}</td>
                      <td>{item.dateDebutPromo}</td>
                      <td>{item.dateFinPromo}</td>
                      <td>{item.statusPromo}</td>
                      <td>{item.statusPromo == "en cours" ?
                        <div>{utils.lbl[`${(this.state.lang)}.17`]}<input type="date" name='datef' value={this.state.datef} onChange={this.handleDate} dateFormat="yyyy-MM-dd" /></div>
                        : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/*------------------------------------- Categorie article -------------------------------- */}
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h5>{utils.lbl[`${(this.state.lang)}.18`]}</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  {articleCat.map(item => (
                    <label className="container2 ml-4">
                      {item.lbl}
                      <div key={item.id} >
                        <input type="radio" name="idcatarticle" value={item.id} onChange={this.handleCategorie} id={`idcategorie${item.id}`} 
                          key={`ca${item.id}`} defaultChecked={this.state.idcatarticle === item.id} />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            {/*------------------------------------- Clientelle -------------------------------- */}
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h5>{utils.lbl[`${(this.state.lang)}.19`]}</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  {clientelle.map(item => (
                    <div key={item.id} className="col-5">
                      <label className="form-check-label ml-4">
                        <input type="checkbox" name="tous" value={item.id} onChange={(e) => this.handleClientelle(e, item)} id={`clientelle${item.id}`}
                          key={`c${item.id}`} checked={this.state.id_clientelle.find((ch) => ch === item.id)}
                          className="form-check-input" style={{ zoom: 1.5 }} /> {item.lbl}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
}
ArticleForm.propTypes = {
  promotion: PropTypes.number
};