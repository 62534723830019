export var lbl = [];

lbl["fr.0"] = "Nouvelles Commandes";
lbl["fr.1"] = "Commandes à préparer";
lbl["fr.2"] = "Commandes à livrer";
lbl["fr.3"] = "Commandes à annuler";
lbl["fr.4"] = "Commandes Livrées";
lbl["fr.5"] = "Commandes Annulées";
lbl["fr.6"] = "Date commande";
lbl["fr.7"] = "Numéro commande";
lbl["fr.8"] = "Montant";
lbl["fr.9"] = "Quantité";
lbl["fr.10"] = "Annonce";
lbl["fr.11"] = "Opération";
lbl["fr.12"] = "Date";
lbl["fr.13"] = "Statut";
lbl["fr.14"] = "#";
lbl["fr.15"] = "Traiter";
lbl["fr.16"] = "> Montre Menu Magasin";
lbl["fr.17"] = "v Cache Menu Magasin";

/* AR */

lbl["ar.0"] = "طلبات جديدة";
lbl["ar.1"] = "طلبات للإعداد";
lbl["ar.2"] = "طلبات للتوصيل";
lbl["ar.3"] = "طلبات للإلغاء";
lbl["ar.4"] = "طلبات موصلة";
lbl["ar.5"] = "طلبات ملغية";
lbl["ar.6"] = "تاريخ الطلب";
lbl["ar.7"] = "رقم الطلب";
lbl["ar.8"] = "حاصل";
lbl["ar.9"] = "كمية";
lbl["ar.10"] = "إعلان";
lbl["ar.11"] = "عملية";
lbl["ar.12"] = "تاريخ";
lbl["ar.13"] = "وضعية";
lbl["ar.14"] = "#";
lbl["ar.15"] = "معالجة";
lbl["ar.16"] = "أظهر قائمة المحل <";
lbl["ar.17"] = "اخف قائمة المحل v";

/* EN */

lbl["en.0"] = "New Orders";
lbl["en.1"] = "Orders to prepare";
lbl["en.2"] = "Orders to be delivered";
lbl["en.3"] = "Orders to be canceled";
lbl["en.4"] = "Orders Delivered";
lbl["en.5"] = "Canceled Orders";
lbl["en.6"] = "Order Date";
lbl["en.7"] = "Order number";
lbl["en.8"] = "Amount";
lbl["en.9"] = "Quantity";
lbl["en.10"] = "Advert";
lbl["en.11"] = "Operation";
lbl["en.12"] = "Date";
lbl["en.13"] = "Status";
lbl["en.14"] = "#";
lbl["en.15"] = "Process";
lbl["en.16"] = "> Show Shop Menu";
lbl["en.17"] = "v Hide Shop Menu";

/* DE */

lbl["de.0"] = "Neue Bestellungen";
lbl["de.1"] = "Bestellung vorbereiten";
lbl["de.2"] = "Bestellung geliefert";
lbl["de.3"] = "Bestellung stornieren";
lbl["de.4"] = "Bestellung geliefert";
lbl["de.5"] = "Bestellung stornieren";
lbl["de.6"] = "Bestellung date";
lbl["de.7"] = "Bestellunummer";
lbl["de.8"] = "Menge";
lbl["de.9"] = "Menge";
lbl["de.10"] = "Anzeige";
lbl["de.11"] = "Betrieb";
lbl["de.12"] = "Date";
lbl["de.13"] = "Status";
lbl["de.14"] = "#";
lbl["de.15"] = "Verarbeiten";
lbl["de.16"] = "> Showen Agence Menu";
lbl["de.17"] = "v Hidden Agence Menu";

