export var lbl = [];

lbl["fr.0"] = "Télécharger la carte de visite avec le QR Code";
lbl["fr.1"] = "De";
lbl["fr.2"] = "A";
lbl["fr.3"] = "Lundi";
lbl["fr.4"] = "Mardi";
lbl["fr.5"] = "Mercredi";
lbl["fr.6"] = "Jeudi";
lbl["fr.7"] = "Vendredi";
lbl["fr.8"] = "Samedi";
lbl["fr.9"] = "Dimanche";
lbl["fr.10"] = "> Montre Menu Magasin";
lbl["fr.11"] = "v Cache Menu Magasin";
lbl["fr.12"] = "Visitez notre magasin en ligne";

/* AR */

lbl["ar.0"] = "تحميل بطاقة الزيارة مع الرمز QR";
lbl["ar.1"] = "من";
lbl["ar.2"] = "إلى";
lbl["ar.3"] = "الاثنين";
lbl["ar.4"] = "الثلاثاء";
lbl["ar.5"] = "الأربعاء";
lbl["ar.6"] = "الخميس";
lbl["ar.7"] = "الجمعة";
lbl["ar.8"] = "السبت";
lbl["ar.9"] = "الأحد";
lbl["ar.10"] = "أظهر قائمة المحل <";
lbl["ar.11"] = "اخف قائمة المحل v";
lbl["ar.12"] = "زوروا متجرنا على موقعنا الالكتروني";

/* EN */

lbl["en.0"] = "Download visit card with QR Code";
lbl["en.1"] = "From";
lbl["en.2"] = "To";
lbl["en.3"] = "Monday";
lbl["en.4"] = "Tuesday";
lbl["en.5"] = "Wednesday";
lbl["en.6"] = "Thursday";
lbl["en.7"] = "Friday";
lbl["en.8"] = "Saturday";
lbl["en.9"] = "Sunday";
lbl["en.10"] = "> Show Shop Menu";
lbl["en.11"] = "v Hide Shop Menu";
lbl["en.12"] = "Visit our online shop";

/* DE */

lbl["de.0"] = "Download visit card with QR Code";
lbl["de.1"] = "Von";
lbl["de.2"] = "Bis";
lbl["de.3"] = "Montag";
lbl["de.4"] = "Dienstag";
lbl["de.5"] = "Mittwoch";
lbl["de.6"] = "Donnerstag";
lbl["de.7"] = "Freitag";
lbl["de.8"] = "Samstag";
lbl["de.9"] = "Sonntag";
lbl["de.10"] = "> Showen Agence Menu";
lbl["de.11"] = "v Hidden Agence Menu";
lbl["de.12"] = "Visit our online shop";

