import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './activation.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ionImg from '../assets/images/ion.jpg';
import ioffImg from '../assets/images/ioff.jpg';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/activationModuleTableau';
import * as comons from '../component/commun';



export default class ActivationModuleTableau extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      activation: [],
      module: []
    };
  }

  getActivations = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/droits_modules`, dmdData).then(res => {
      this.setState({ activation: res.data });
    }).catch(err => {
      toast.error(err.response.data.error + err.message, {
        autoClose: 3000
      });
      comons.showMsg(utils.lbl[`${(this.state.lang)}.4`] + err);
    });
  }

  getModules = e => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/module/${this.state.lang}`).then(res => {
      this.setState({ module: res.data });
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.4`] + err);
      toast.error(err.response.data.error, err.message, {
        autoClose: 3000
      });
    });
  }

  componentDidMount() {
    this.getActivations();
    this.getModules();
  }



  render() {
    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
          <div className="p-2 flex-shrink-1 bd-info">
            <div className="d-flex justify-content-center">
              <h1 className={{ textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
            </div>
            <div className="d-flex justify-content-center">
              <table className="table table-sm table-light">
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                    {this.state.module.map(item => (
                      <th scope="col">{item.lbl}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.state.activation.map(item => (
                    <tr>
                      <td>{item.nom}</td>
                      <td>{item.ice}</td>
                      <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date_creation))}</td>
                      {item.droits.map(ac => (
                        <td>
                          {(ac.acces ?
                            <Link to={{
                              pathname: '/desactivation/' + item.id_agence + '/' + ac.id_module,
                              state: { info: this.state.info }
                            }}>
                              <img src={ionImg} width="58" height="26" alt="abonné" />
                            </Link>
                            :
                            <Link to={{
                              pathname: '/activation/' + item.id_agence + '/' + ac.id_module,
                              state: { info: this.state.info }
                            }}>
                              <img src={ioffImg} width="58" height="26" alt="non abonné" />
                            </Link>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {/*TODO afficher la cv de l'agence*/}
              <span>chez Karim <Link to={'/activationdetail'}>Detail</Link></span>
            </div>
          </div>
        </div>

      </>
    )
  }
}