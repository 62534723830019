import React, { Component } from 'react';
import { PushSpinner } from "react-spinners-kit";
import './activation.css';
import axios from 'axios';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import CVAgence from '../component/cvAgence';
import * as utils from '../lang/desactivation';
import * as comons from '../component/commun';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class Desactivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      desactivation: '',
      dateDesact: '',
      dateDebut: '',
      dateFin: ''
    };
  }

  getDesactivation = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token,
      idAgence: this.props.match.params.idAgence,
      idModule: this.props.match.params.idModule
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/read_droit_module`, dmdData).then(res => {
      this.setState({ desactivation: res.data });
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.10`] + err)
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })

    });
  }

  getCVAgence = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cv_agence/${this.props.match.params.idAgence}/${this.state.lang}`).then(res => {
      this.props.location.dataAgence = res.data;
      this.setState({ isLoading: false }); //Informer de la fin du chargement
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.10`] + err)

      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })

    });
  }

  componentDidMount = () => {
    this.getDesactivation();
    this.getCVAgence();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleDesactiver = e => {
    e.preventDefault();
    e.target.disabled = true;
    const dmdDesact = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token,
      idAgence: this.props.match.params.idAgence,
      idModule: this.props.match.params.idModule,
      dateDesact: this.state.dateDesact
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/admin/update_droit_module/`, dmdDesact).then(res => {
      e.target.innerHTML = utils.lbl[`${(this.state.lang)}.9`];
    }).catch(err => {
      comons.showMsg(utils.lbl[`${(this.state.lang)}.10`] + err)
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
      e.target.disabled = false;
    });
  }



  render() {
    const { isLoading } = this.state;

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} />
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-shrink-1 bd-info">
            <MenuAgence location={this.props.location} />
          </div>
          <div className="p-2 flex-shrink-1 bd-info">
            <div className="d-flex justify-content-center">
              <h1 className={{ textAlign: 'center' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
            </div>
            <div className="d-flex justify-content-center">
              <table className="table table-sm table-light">
                <thead>
                  <tr>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.5`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                    <th scope="col">{utils.lbl[`${(this.state.lang)}.7`]}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.desactivation.nom}</td>
                    <td>{this.state.desactivation.ice}</td>
                    <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(this.state.desactivation.date_creation))}</td>
                    <td>{this.state.desactivation.nom_module}</td>
                    <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(this.state.desactivation.date_debut))}</td>
                    <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(this.state.desactivation.date_fin))}</td>
                    <td><input type="date" name='dateDesact' value={this.state.dateDesact} onChange={this.handleChange} className="form-control mb-2 mr-sm-2" autoComplete='false' /></td>
                    <td><button className="btn btn-primary" onClick={this.handleDesactiver}>{utils.lbl[`${(this.state.lang)}.8`]}</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <CVAgence location={this.props.location} />
          </div>
        </div>

      </>
    )
  }
}