import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowImage from '../component/showImage';
import * as utils from '../lang/commandeConsultation';
import * as comons from '../component/commun';
import disableBrowserBackButton from 'disable-browser-back-navigation';



class CommandeConsultation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      numCommande: '',
      email: '',
      tele: '',
      idcmd: '',
      cacheRecherche: true,
      datas: { agence: {}, commande: [], suivi: [], annulation: false },
      code: '',
      modalShow: false,
      modalShow1: false,
      msg: ''
    };
    this.state.info.lang = this.state.lang;
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  Modal = () => {
    this.setState({
      modalShow: !this.state.modalShow
    });
  }

  Modal1 = () => {
    this.setState({
      modalShow1: !this.state.modalShow1
    });
  }

  getCommande = () => {
    const ob = {
      numcmd: this.state.numCommande,
      mail: this.state.email,
      tel: this.state.tele,
      lng: this.state.lang
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/commande_suivi`, ob).then(res => {
      //console.warn(res) //TODO modifier la réception de data 
      this.setState({
        datas: res.data,
        idcmd: res.data.commande[0].idCommande,
        cacheRecherche: false
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  demandeAnnulation = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/visiteur/demande_annulation/init`, {
      numcmd: this.state.numCommande,
      idcmd: this.state.idcmd,
      tel: this.state.tele,
      mail: this.state.email,
      lng: this.state.lang
    }).then(res => {
      //console.log(res)
      this.setState({
        modalShow: !this.state.modalShow
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  confirmCode = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/visiteur/demande_annulation/confirm`, {
      numcmd: this.state.numCommande,
      idcmd: this.state.idcmd,
      tel: this.state.tele,
      mail: this.state.email,
      codeconfirm: this.state.code,
      lng: this.state.lang
    }).then(res => {
      this.setState({
        msg: res.data[0].msg,
        modalShow: !this.state.modalShow,
        modalShow1: !this.state.modalShow1
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  changeLanguage = (lang) => {
    this.setState({ lang })
    this.state.info.lang = lang
  }

  componentDidMount() {
    disableBrowserBackButton()
  }


  render() {

    const { datas, numCommande, tele, email, code } = this.state
    return (
      <>

        <nav style={{ backgroundColor: '#000000' }}>
          <div className="d-flex justify-content-center pt-2">
            <h2 style={{ textAlign: 'center', color: 'white' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h2>
          </div>
        </nav>

        <div className="container-fluid mt-2">
          <div className="row mt-2">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.1`]}</span>
                </div>
                <input type="text" name='numCommande' value={numCommande} onChange={this.handleChange} className="form-control"
                       aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                       required autoComplete='false' />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                </div>
                <input type="email" name='email' value={email} onChange={this.handleChange} className="form-control"
                       aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                       autoComplete='false' />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex flex-row justify-content-center">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                </div>
                <input type="number" name='tele' value={tele} onChange={this.handleChange} className="form-control"
                       aria-label="Default" aria-describedby="inputGroup-sizing-default" aria-required="true" aria-invalid="false"
                       min="0" step="1" autoComplete='false' />
              </div>
            </div>
          </div>
          <hr className="my-0" />
          <div className="col">
            <div className="d-flex flex-row justify-content-center">
              <div className="row mr-5">
                <button className="btn btn-primary" onClick={this.getCommande}>{utils.lbl[`${(this.state.lang)}.4`]}</button>
              </div>
              <div className="row">
                <Link to={{
                        pathname: "/",
                        state: { info: this.state.info }
                      }} className="btn btn-secondary">{utils.lbl[`${(this.state.lang)}.5`]}</Link>
              </div>
            </div>
          </div>
        </div>

        {/* **************** Commande a consulter ************* */}
        <div className="justify-content-center mt-4" style={{ display: 'grid' }}>
          <table className="table table-striped table-inverse table-responsive" hidden={this.state.cacheRecherche}>
            <thead className="thead-inverse">
              <tr>
                <th>{utils.lbl[`${(this.state.lang)}.6`]}</th>
                <th>{utils.lbl[`${(this.state.lang)}.11`]}</th>
                <th>{utils.lbl[`${(this.state.lang)}.12`]}</th>
                <th>{utils.lbl[`${(this.state.lang)}.23`]}</th>
              </tr>
            </thead>
            {datas.commande.map(item => (
              <tbody>
                <tr>
                  <td>
                    <Link to={{
                      pathname: '/vitrine/' + datas.agence.id,
                      state: { info: this.state.info }
                    }}>
                      {datas.agence.nom}
                    </Link>
                  </td>
                  <td>{item.montant}</td>
                  <td>{item.idLiv === 1 ? 'Non' : 'Oui'}<br />{item.idLiv === 1 ? '' : item.frLiv}</td>
                  <td>
                    <table className="table table-sm table-light">
                      <thead>
                        <tr>
                          <th>{utils.lbl[`${(this.state.lang)}.7`]}</th>
                          <th>{utils.lbl[`${(this.state.lang)}.8`]}</th>
                          <th>{utils.lbl[`${(this.state.lang)}.9`]}</th>
                          <th>{utils.lbl[`${(this.state.lang)}.10`]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.panier.map(it => (
                          <tr>
                            <td>{it.annonce}</td>
                            <td><ShowImage imgID={it.id_photo} imgStyle={{ width: '7rem', height: '5rem' }} /></td>
                            <td>x {it.q}</td>
                            <td>{it.prix}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        {/*--------------------------------- end ---------------------------------------*/}
        <div className="justify-content-center mt-0" style={{ display: 'grid' }}>
          <table className="table table-striped table-inverse table-responsive" hidden={this.state.cacheRecherche}>
            <thead className="thead-inverse">
              <tr>
                <th>{utils.lbl[`${(this.state.lang)}.13`]}</th>
                <th>{utils.lbl[`${(this.state.lang)}.14`]}</th>
                <th>{utils.lbl[`${(this.state.lang)}.15`]}</th>
              </tr>
            </thead>
            {datas.suivi.map(item => (
              <tbody>
                <tr>
                  <td>{new Intl.DateTimeFormat(this.state.lang, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date))}</td>
                  <td>{item.operation}</td>
                  <td>{item.status}</td>
                </tr>
              </tbody>
            ))}
            {datas.annulation ?
              datas.commande.map(item => (
                <div className="d-flex justify-content-center mb-5" >
                  <Link onClick={this.demandeAnnulation} className="btn btn-primary" type="button">
                    {utils.lbl[`${(this.state.lang)}.16`]}
                  </Link>
                </div>
              )) : ""}
          </table>
        </div>
        {/* ------------------------------Modal -------------------------------*/}
        <Modal visible={this.state.modalShow} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.17`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <p>{utils.lbl[`${(this.state.lang)}.18`]}</p>
            <div className=" justify-content-start mr-3">
              <label>{utils.lbl[`${(this.state.lang)}.19`]}</label>
              <input type="text" name="code" value={code} onChange={this.handleChange} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.Modal}>{utils.lbl[`${(this.state.lang)}.21`]}</button>
            <button type="button" className="btn btn-primary" onClick={this.confirmCode}>{utils.lbl[`${(this.state.lang)}.20`]}</button>
          </div>
        </Modal>
        <Modal visible={this.state.modalShow1} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.17`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <h2>{this.state.msg}</h2>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.Modal1}>{utils.lbl[`${(this.state.lang)}.22`]}</button>
          </div>
        </Modal>
      </>
    )
  }
}

export default withRouter(CommandeConsultation)