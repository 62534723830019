import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap4-modal';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { toast } from 'react-toastify';
import MagasinForm from './magasinForm';
import * as utils from '../lang/magasinCreation';
import * as comons from '../component/commun';
import * as cgu from '../lang/CGU';

export default class MagasinCreation extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      visible: false,
      nom: '',
      prenoms: '',
      login: '',
      password: '',
      titrePers: [],
      hidden: true,
      id_titrepers: '',
      active: false,
      idAgence: '',
      ModalShow: false,
      vs: false,
      checkCondition: false,
      dsButton: true
    };
    this.props.location.agenceData = '';
    this.state.info.lang = this.state.lang;
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }

  handleCategorie = e => {
    this.setState({
      id_titrepers: e.target.value
    })
  }

  getTitrePers = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/titre_pers/${this.state.lang}`).then(res => {
      this.setState({ titrePers: res.data });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    this.getTitrePers();
    disableBrowserBackButton()
  }

  handleCreationSociete = (e) => {
    const form = this.formRef.current;

    e.preventDefault()

    //Désactivation du bouton
    this.setState({ dsButton: true });
    //Vérification de la saisie des données demandées
    if (form.state.raisonSociale == '' || form.state.ice == '' || form.state.mail == '' || form.state.tel == '' || form.state.adr == '' ||
      form.state.id_typecommerce == '' || form.state.id_clientelle == '' || form.state.id_livraison == '' ||
      form.state.id_ville == '' || form.state.id_suivi == '' || this.state.id_titrepers == '' || this.state.nom == '' ||
      this.state.prenoms == '' || this.state.checkCondition == false || this.state.password == '') {
      toast.warning(utils.lbl[`${(this.state.lang)}.14`], {
        autoClose: 4000
      });
      this.setState({ dsButton: false });
    }
    else {
      const societeData = {
        raisonSociale: form.state.raisonSociale,
        ice: form.state.ice,
        site: form.state.site,
        mail: form.state.mail,
        tel: form.state.tel,
        adr: form.state.adr,
        cp: form.state.cp,
        frais_livraison: form.state.fraisLiv,
        color: form.state.couleur,
        newHoraire: form.state.newHoraire,
        myFile: form.state.myFile,
        id_typecommerce: form.state.id_typecommerce,
        id_clientelle: form.state.id_clientelle,
        id_livraison: form.state.id_livraison,
        id_ville: form.state.id_ville,
        id_suivi: form.state.id_suivi,
        cgv: form.state.cgvContent,
        lang: this.state.lang,
        id_titrepers: this.state.id_titrepers,
        nom: this.state.nom,
        prenoms: this.state.prenoms,
        //login
        password: this.state.password
      }

      axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/create_full_agence`, societeData).then(res => {
        this.state.info = res.data;
        this.setState({
          //info: res.data,
          idAgence: res.data.comptes[0].id_agence,
          ModalShow: true
        });
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        });
        this.setState({ dsButton: false });
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      login: this.formRef.current.mail
    });
  }

  handlecheck = (e) => {
    this.setState({
      checkCondition: e.target.checked
    });
    if (e.target.checked) {
      this.setState({ dsButton: false });
    } else {
      this.setState({ dsButton: true });
    }
  }

  conditionsAccepted = () => {
    this.setState({
      vs: !this.state.vs,
      checkCondition: true,
      dsButton: false
    });
  }

  modalConditions = () => {
    if (!this.state.vs) {
      var cgucnt = "";
      for (let i = 2; i <= 20; i++) {
        cgucnt += cgu.lbl[`${(this.state.lang)}.${i}`] + "\n";
      }
    }
    this.setState({
      vs: !this.state.vs,
      cguContent: cgucnt
    });
  }



  render() {
    //console.warn(this.state)
    //console.warn(this.props)
    //console.warn(this.formRef)
    const { nom, prenoms, password } = this.state;

    return (
      <>
        <nav style={{ backgroundColor: '#000000' }}>
          <div className="d-flex justify-content-center pt-2">
            <h1 style={{ textAlign: 'center', color: 'white' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h1>
          </div>
        </nav>
        <div className="container-fluid">
          <MagasinForm location={this.props.location} ref={this.formRef} />
        </div>
        <hr className="my-3" />
        <div className="container mt-0 ">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card text-left border border-info">
                <div className="card-title d-flex justify-content-center">
                  <h4>{utils.lbl[`${(this.state.lang)}.1`]}</h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <div className='d-flex flex-row'>
                    {this.state.titrePers.map(item => (
                      <div key={item.id} className='d-flex flex-row mr-3'>
                        <input onChange={this.handleCategorie} name="id_titrepers" value={item.id} key={item.id} type="radio" />
                        <label>{item.lbl}</label>
                      </div>
                    ))}
                  </div>
                  <form className="d-flex flex-column">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                      </div>
                      <input onChange={this.onChange} value={nom} name="nom" type="text" className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" required />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                      </div>
                      <input onChange={this.onChange} value={prenoms} name="prenoms" type="text" className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" required />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.4`]}</span>
                      </div>
                      <input value={this.formRef.current != null ?
                        this.formRef.current.state.mail : ''} type="email" className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                      </div>
                      <input onChange={this.onChange} type={this.state.hidden ? 'password' : 'text'}
                        value={password} name="password" className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" required />
                      <FontAwesomeIcon onClick={this.toggleShow} icon={this.state.hidden ? faEyeSlash : faEye} style={{
                        color: 'black', marginTop: '8px',
                        marginLeft: '5px'
                      }} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-3" />
        <div className="container mt-0 ">
          <div className="row justify-content-center">
            <div className="col custom-control custom-radio d-flex justify-content-row">
              <label for="agreeConditions">
                <input type="checkbox" className="mr-2" id="agreeConditions" onChange={this.handlecheck} checked={this.state.checkCondition} />
                {utils.lbl[`${(this.state.lang)}.11`]}
                <Link style={{ cursor: 'pointer', marginLeft: '15px' }} onClick={this.modalConditions}>{utils.lbl[`${(this.state.lang)}.12`]}</Link>
              </label>
            </div>
          </div>
        </div>
        <hr className="my-3" />
        <div className="row justify-content-center">
          <div className="modal-footer">
            <button type="button" disabled={this.state.dsButton} className="btn btn-primary" onClick={this.handleCreationSociete}>
              {utils.lbl[`${(this.state.lang)}.6`]}
            </button>
            <Link to={{
              pathname: '/',
              state: { info: this.state.info }
            }} type="button" className="btn btn-danger ml-3" >
              {utils.lbl[`${(this.state.lang)}.7`]}
            </Link>
          </div>
        </div>
        <Modal visible={this.state.ModalShow} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.8`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <h2>{utils.lbl[`${(this.state.lang)}.9`]}</h2>
          </div>
          <div className="modal-footer">
            <Link onClick={this.back} to={{
              pathname: `/magasin-index/${this.state.idAgence}`,
              state: { info: this.state.info }
            }} type="button" className="btn btn-primary">
              {utils.lbl[`${(this.state.lang)}.10`]}
            </Link>
          </div>
        </Modal>
        {/* Conditions Générales d'Utilisation */}
        <Modal visible={this.state.vs}>
          <div className="modal-header">
            <h5 className="modal-title">{cgu.lbl[`${(this.state.lang)}.0`]}</h5>
            <h7 className="modal-title">{cgu.lbl[`${(this.state.lang)}.1`]}</h7>
          </div>
          <div className="modal-body pl-5">
            <textarea onChange={this.onChange} value={this.state.cguContent} name="cguContent" type="text" rows="15" placeholder="" className="form-control" />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={this.modalConditions}>
              {utils.lbl[`${(this.state.lang)}.7`]}
            </button>
            <button type="button" className="btn btn-primary" onClick={this.conditionsAccepted}>
              {utils.lbl[`${(this.state.lang)}.13`]}
            </button>
          </div>
        </Modal>

      </>
    )
  }

  back() {
    disableBrowserBackButton()
  }
}