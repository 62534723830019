import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PushSpinner } from 'react-spinners-kit';
import 'react-toastify/dist/ReactToastify.css';
import '../Pages/home.css';
import MenuUser from '../component/menuUser';
import * as utils from '../lang/home';
import * as comons from '../component/commun';
import Card from '../component/card';



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading1: true, isLoading2: true, isLoading3: true, //Pour patienter le temps du chargement
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'fr'),
      checked: false,
      country: [],
      ville: [],
      typeCommerce: [],
      clientelle: [],
      search: '',
      query: '',
      data: [],
      filteredData: [],
      id_pays: '',
      id_ville: '',
      id_clientelle: [],
      id_TypeCommerce: [],
      noresult: true,
      // speech: this.speechSynthesis()
    };
    this.state.info.lang = this.state.lang;

  }


  getPays = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/pays/${this.state.lang}`).then(res => {
      this.setState({
        country: res.data,
        isLoading3: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.warning(err.message, {
        autoClose: 3000
      });
    });
  }

  getVille = (e) => {
    axios.get((this.state.id_pays != "" ? `${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/ville/${this.state.id_pays}/${this.state.lang}` : `${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/ville/${this.state.lang}`)).then(res => {
      this.setState({
        ville: res.data,
        isLoading1: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getTypeCommerce = (e) => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/typeCommerce/${this.state.lang}`).then(res => {
      this.setState({
        typeCommerce: res.data,
        isLoading2: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getClientelle = (e) => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/clientelle/${this.state.lang}`).then(res => {
      this.setState({
        clientelle: res.data,
        isLoading3: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getDataforSearch = (e) => {
    e.preventDefault();
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/search/[${this.state.id_ville}]/[${this.state.id_clientelle}]/[${this.state.id_TypeCommerce}]/${this.state.query.trim()}/${this.state.lang}`).then(res => {
      this.setState({
        filteredData: res.data,
        noresult: (res.data.length !== 0)
      });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getDataforPub = () => {
    //e.preventDefault();
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/publicite/${this.state.lang}`).then(res => {
      this.setState({ filteredData: res.data });
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getDataToShow = () => {
    this.getPays();
    this.getVille();
    this.getTypeCommerce();
    this.getClientelle();
  }

  componentDidMount() {
    //  window.location.reload()
    this.getDataToShow();
    this.getDataforPub();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.id_pays !== this.state.id_pays) {
      this.getVille()
    }
  }

  handleClientelle = (e) => {
    var id_clientelle = this.state.id_clientelle
    let index
    if (e.target.checked) {
      if (e.target.value == "0") {
        id_clientelle = [];
        for (let i = 0; i < this.state.clientelle.length; i++) {
          let ref = 'clientelle' + (i + 1);
          this.refs[ref].checked = true;
          id_clientelle.push((i + 1));
        }
      } else {
        id_clientelle.push(e.target.value);
      }
    } else {
      if (e.target.value == "0") {
        id_clientelle = [];
        for (let i = 0; i < this.state.clientelle.length; i++) {
          let ref = 'clientelle' + (i + 1);
          this.refs[ref].checked = false;
        }
      } else {
        index = id_clientelle.indexOf(e.target.value);
        id_clientelle.splice(index, 1);
      }
    }

    this.setState({id_clientelle});
  }

  handleTalk = (tosay) => {
    let utter = new SpeechSynthesisUtterance();
    utter.text = tosay;
    utter.lang = this.state.lang; // 'en-US';
    window.speechSynthesis.speak(utter);
    utter.onend = function () {
      window.speechSynthesis.cancel();
    }
  }

  handleMuteTalk = () => {
    window.speechSynthesis.cancel();
  }

  handleTypeCommerce = (e) => {
    var id_TypeCommerce = this.state.id_TypeCommerce
    let index
    if (e.target.checked) {
      if (e.target.value == "0") {
        id_TypeCommerce = [];
        for (let i = 0; i < this.state.typeCommerce.length; i++) {
          let ref = 'typeCommerce' + (i + 1);
          this.refs[ref].checked = true;
          id_TypeCommerce.push((i + 1));
        }
      } else {
        id_TypeCommerce.push(e.target.value);
      }
    } else {
      if (e.target.value == "0") {
        id_TypeCommerce = [];
        for (let i = 0; i < this.state.typeCommerce.length; i++) {
          let ref = 'typeCommerce' + (i + 1);
          this.refs[ref].checked = false;
        }
      } else {
        index = id_TypeCommerce.indexOf(e.target.value);
        id_TypeCommerce.splice(index, 1);
      }
    }

    this.setState({id_TypeCommerce});
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleInputChange = (e) => {
    const query = e.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        return element.name.toLowerCase().includes(query.toLowerCase());
      });

      return {
        query,
        filteredData
      };
    });
  }

  onChangeVille = (e) => {
    this.state.id_ville = '';
    for (let i = 0; i < this.state.ville.length; i++) {
      if (this.state.ville[i].lbl == e.target.value) {
        this.setState({ id_ville: this.state.ville[i].id });
        this.handleTalk(e.target.value);
        break;
      }
    }
  }

  handleChangeCountry = (e) => {
    this.state.id_pays = '';
    for (let i = 0; i < this.state.country.length; i++) {
      if (this.state.country[i].lbl == e.target.value) {
        this.setState({ id_pays: this.state.country[i].id });
        this.handleTalk(e.target.value);
        break;
      }
    }
  }

  changeLanguage = (lang) => {
    this.setState({ lang });
    this.state.lang = lang;
    this.state.info.lang = lang;
    this.getDataToShow();
  }



  render() {

    console.log(this.state)
    const { isLoading1, isLoading2, isLoading3, country, ville, typeCommerce, clientelle } = this.state

    if (isLoading1 || isLoading2 || isLoading3) { //Message informatif du temps d'attente
      return <div class="row d-flex justify-content-center" style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading1 || isLoading2 || isLoading3} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} changeLanguage={this.changeLanguage.bind(this)} lang={this.state.lang} />
        <div class="container-fluid">
          <div class="row mt-2">
            <div class="col-lg-9 col-md-9 col-sm-6 mb-2">
              {/*********************** Affichage de type de commerce ***********************/}
              <div class="card">
                <div class="card-footer">
                  <h5 class="modal-title d-flex justify-content-center" style={{ color: 'black' }} onMouseEnter={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.1`])}>{utils.lbl[`${(this.state.lang)}.1`]}</h5>
                </div>
                <div class="card-body text-capitalize">
                  <div class="row align-self-stretch">
                    {typeCommerce.map(item => (
                      <label style={{ color: 'black' }} class="container2 ml-2" onMouseEnter={() => this.handleTalk(item.lbl)}>
                        <input type="checkbox" name='typeCommerce' value={item.id} onChange={this.handleTypeCommerce}
                          id={item.id} ref={`typeCommerce${item.id}`} key={item.id} /><h6>{item.lbl}</h6>
                        <span class="checkmark"></span>
                      </label>
                    ))}
                    <label style={{ color: 'black' }} class="container2 ml-2" onMouseEnter={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.0`])}>
                      <input type="checkbox" name='typeCommerce' value="0" onChange={this.handleTypeCommerce} id="0" key="0"
                        onClick={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.0`])}
                      />
                      <h6><div >{utils.lbl[`${(this.state.lang)}.0`]}</div></h6>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 mb-2">
              {/*********************** Affichage de la clientelle ***********************/}
              <div class="card">
                <div class="card-footer">
                  <h5 class="modal-title d-flex justify-content-center" style={{ color: 'black' }} onMouseEnter={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.2`])}>{utils.lbl[`${(this.state.lang)}.2`]}</h5>
                </div>
                <div class="card-body text-capitalize">
                  <div class="row">
                    {clientelle.map(item => (
                      <label style={{ color: 'black' }} class="container2 ml-2" onMouseEnter={() => this.handleTalk(item.lbl)}>
                        <input type="checkbox" name='clientelle' value={item.id} onChange={this.handleClientelle}
                          id={item.id} ref={`clientelle${item.id}`} key={item.id} />
                        <h6>{item.lbl}</h6>
                        <span class="checkmark"></span>
                      </label>
                    ))}
                    <label style={{ color: 'black' }} class="container2 ml-2" onMouseEnter={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.0`])}>
                      <input type="checkbox" name='clientelle' value="0" onChange={this.handleClientelle} id="0" key="0" />
                      <h6>{utils.lbl[`${(this.state.lang)}.0`]}</h6>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-row justify-content-center input-group mb-2">
              {/*********************** Affichage des pays ***********************/}
              {comons.srv_url === comons.srvb_url_local ?
                <>
                  <input type='text' name='nom_pays' list='data1' onChange={this.handleChangeCountry} class="form-control py-2 border-right-0 border bg-dark mr-2"
                    style={{ color: '#fff' }} placeholder={utils.lbl[`${(this.state.lang)}.6`]}
                    onClick={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.6`])} />
                  <datalist name="id_pays" value={this.state.id_pays} class="bg-dark mr-1" style={{ color: 'white' }} id="data1">
                    {country.map((item) => (
                      <option style={{ color: 'white' }} value={item.lbl} key={item.id} id={item.id} />
                    ))}
                  </datalist>
                </>
                : ''}
              {/*********************** Affichage des villes ***********************/}
              <input type="text" name="nom_ville" list="data" onChange={this.onChangeVille} class="form-control py-2 border-right-0 border bg-dark"
                style={{ color: 'white' }} placeholder={utils.lbl[`${(this.state.lang)}.3`]}
                onClick={() => this.handleTalk(utils.lbl[`${(this.state.lang)}.3`])} />
              <datalist name="id_ville" value={this.state.id_ville} class="bg-dark" style={{ color: 'white' }} id="data">
                {ville.map((item) => (
                  <option style={{ color: 'white' }} value={item.lbl} key={item.id} id={item.id} />
                ))}
              </datalist>
            </div>
            {/*********************** Affichage du text de recherche ***********************/}
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-row justify-content-center">
              <input type="search" name="query" value={this.state.query} onChange={this.handleInputChange} class="form-control py-2 border-right-0 border bg-dark"
                style={{ color: 'white' }} placeholder={utils.lbl[`${(this.state.lang)}.4`]} id="example-search-input" />
              <Link class="input-group-append" to="">
                <FontAwesomeIcon class="search_icon ml-2" onClick={this.getDataforSearch} style={{ color: 'black' }} icon={faSearch} />
              </Link>
            </div>
          </div>
        </div>
        <hr />
        {/*********************** Affichage du résultat de recherche ***********************/}
        <div class="container-fluid">
          <label style={{ color: 'black' }} class="container2 ml-2" hidden={this.state.noresult}>
            {utils.lbl[`${(this.state.lang)}.5`]}
          </label>
          <div class="row">
            {this.state.filteredData.map(i => (
              <Card agence={i} info={this.state.info} />
            ))}
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(Home)