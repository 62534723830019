import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
//import Loader from 'react-loaders';
import { withRouter, Link } from 'react-router-dom';
import { PushSpinner } from "react-spinners-kit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import CompteForm from './compteForm';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/compteModification';
import * as comons from '../component/commun';



class CompteModification extends Component {
  formRef = React.createRef(); //Pour référencer le formulaire afin de récupérer data

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, //Pour patienter le temps du chargement
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      mail: '',
      id: this.props.location.state.info.id_personnel,
      ModalShow: false,
      ModalShow4: false,
      redirection1: false,
      profil: this.props.location.state.info.comptes[0].profil,
      code: '',
      code2: '',
      hidden: true,
      deleteMsg: false,
      hd: false
    };
  }

  getPersonnelData = () => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/read_personnel`,
      {
        token: this.state.info.token,
        idPersonnel: this.state.id
      }).then(res => {
        this.props.location.infoUser = res.data;
        this.setState({
          mail: res.data.mail,
        });
        this.setState({ isLoading: false }); //Informer de la fin du chargement
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        })
      })
  }

  componentDidMount() {
    this.getPersonnelData();
  }

  handleUpdate = e => {
    const form = this.formRef.current;

    e.preventDefault();
    const objetUpdated = {
      token: this.state.info.token,
      idPersonnel: this.state.id,
      id_titre_pers: form.state.id_titre_pers,
      nom: form.state.nom,
      prenoms: form.state.prenoms,
      adress: form.state.adress,
      id_ville: form.state.id_ville,
      cp: form.state.cp,
      id_profession: form.state.id_profession,
      datedenaissance: form.state.datedenaissance,
      mail: form.state.mail,
      tel: form.state.tel,
      id_suivi: form.state.id_suivi,
      id_lang: form.state.id_lang,
      login: form.state.login,
      password: form.state.password
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/update_personnel`, objetUpdated).then(res => {
      this.setState({ ModalShow: !this.state.ModalShow });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleDelete = e => {
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/delete`,
      {
        mail: this.state.mail,
        token: this.state.info.token,
        idPersonnel: this.state.id
      }).then(res => {
        this.setState({ ModalShow2: !this.state.ModalShow2 });
      }).catch(err => {
        toast.error(err.message + err.response.data.error, {
          autoClose: 4000
        })
      })
  }

  handleValidate = () => {
    const form = this.formRef.current;
    const ob = {
      token: this.state.info.token,
      idPersonnel: this.state.id,
      code: this.state.code
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/confirmation_update`, ob).then(res => {
      this.setState({
        ModalShow: !this.state.ModalShow,
        redirection1: !this.state.redirection1
      });

      this.state.info.nom = form.state.nom + ' ' + form.state.prenoms;

      if (this.state.info.id_lang != form.state.id_lang) {
        axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/sym_lng/${form.state.id_lang}`).then(res2 => {
          this.state.info.id_lang = form.state.id_lang;
          this.state.info.lang = res2.data[0].lbl;
          this.state.lang = res2.data[0].lbl;
        });
      }

      // toast.success(utils.lbl[`${(this.state.lang)}.10`], {
      //   autoClose: 2000
      // });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleValidateDelete = () => {
    const ob = {
      token: this.state.info.token,
      idPersonnel: this.state.id,
      code: this.state.code2
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/personnel/confirmation_delete`, ob).then(res => {
      //console.warn(res)
      if (res.status == 200) {
        this.setState({
          ModalShow2: !this.state.ModalShow2,
          deleteMsg: !this.state.deleteMsg
        });
        // toast.success(utils.lbl[`${(this.state.lang)}.9`], {
        //   autoClose: 2000
        // });
      } else if (res.data[0].msg === 'code erroné') {
        toast.warning(utils.lbl[`${(this.state.lang)}.12`], {
          autoClose: 4000
        });
      }
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  Modal = () => {
    this.setState({
      ModalShow: !this.state.ModalShow
    });
  }

  Modal2 = () => {
    this.setState({
      ModalShow2: !this.state.ModalShow2
    });
  }

  Modal3 = () => { //TODO meme fonction 2 operations différentes?
    this.setState({
      ModalShow4: !this.state.ModalShow4
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  desactivate = () => {
    localStorage.clear();
    disableBrowserBackButton()
  }



  render() {
    //console.log(this.props)
    //console.log(this.state)

    const { isLoading, redirection1, info, deleteMsg } = this.state;
    if (redirection1) {
      if (info.comptes[0].profil === 'Client') {
        return (
          <>
            <Modal visible={true}>
              <div className="modal-header">
                <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.3`]}</h5>
              </div>
              <div className="modal-footer">
                <h3>{utils.lbl[`${(this.state.lang)}.10`]}</h3>
                <Link to={{
                  pathname: '/',
                  state: { info: this.state.info }
                }} type="button" className="btn btn-primary">
                  {utils.lbl[`${(this.state.lang)}.11`]}
                </Link>
              </div>
            </Modal>
          </>
        )
      } else if (info.comptes[0].profil === 'Admin') { //TODO double avec Client 
        return (
          <>
            <Modal visible={true}>
              <div className="modal-header">
                <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.3`]}</h5>
              </div>

              <div className="modal-footer">
                <h3>{utils.lbl[`${(this.state.lang)}.10`]}</h3>
                <Link to={{
                  pathname: '/magasin-index/' + info.comptes[0].id_agence,
                  state: { info: this.state.info }
                }} type="button" className="btn btn-primary">
                  {utils.lbl[`${(this.state.lang)}.11`]}
                </Link>
              </div>
            </Modal>
          </>
        )
      }
    }

    if (deleteMsg) {
      return (
        <>
          <Modal visible={true}>
            <div className="modal-header">
              <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.3`]}</h5>
            </div>

            <div className="modal-footer">
              <h3>{utils.lbl[`${(this.state.lang)}.9`]}</h3>
              <Link to={{ pathname: '/' }} type="button" className="btn btn-primary" onClick={this.desactivate}>
                {utils.lbl[`${(this.state.lang)}.11`]}
              </Link>
            </div>
          </Modal>
        </>
      )
    }

    if (isLoading) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading} />
      </div>
    }

    return (
      <React.Fragment>

        <MenuUser location={this.props.location} />
        <div className="container-fluid">
          {this.state.info.comptes[0].profil === 'Admin' ?
            <span style={{ cursor: 'pointer' }} className='badge badge-primary p-0 m-0' onClick={() => this.setState({ hd: !this.state.hd })}>
              {this.state.hd ? utils.lbl[`${(this.state.lang)}.13`] : utils.lbl[`${(this.state.lang)}.14`]}
            </span> : ''}
          <div className="row">
            {this.state.info.comptes[0].profil === 'Admin' ?
              <div className={this.state.hd ? "col-12 p-0" : "col-lg-2 col-md-3 col-sm-4 p-0"} hidden={this.state.hd}>
                <MenuAgence location={this.props.location} />
              </div> : ''}

            <div className={this.state.info.comptes[0].profil === 'Admin' && !this.state.hd ? 'col-sm-6 col-md-6 col-lg-10 mt-2' : 'col-12'}>
              <div className="row d-flex justify-content-around">
                <CompteForm location={this.props.location} ref={this.formRef} />
                <button type="button" className="btn btn-success btn-sm" onClick={this.handleUpdate}>{utils.lbl[`${(this.state.lang)}.0`]}</button>

                <button type="button" className="btn btn-warning btn-sm" onClick={this.goBack}>{utils.lbl[`${(this.state.lang)}.1`]}</button>
                {/* oui pour le client, non pour le commercant */}
                {this.state.profil == 'Client' ?
                  <button type="button" className="btn btn-danger btn-sm" onClick={this.handleDelete}>{utils.lbl[`${(this.state.lang)}.2`]}</button>
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <Modal visible={this.state.ModalShow || this.state.ModalShow2}>
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.3`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.4`]}</span>
              </div>
              <input type="email" name='mail' value={this.state.mail} onChange={this.handleChange} className="form-control" 
                aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
            </div>
            <br />
            <p>{utils.lbl[`${(this.state.lang)}.5`]}</p>
            <br />
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.6`]} {(this.state.ModalShow ? "" : (this.state.ModalShow2 ? utils.lbl[`${(this.state.lang)}.7`] : ""))}</span>
              </div>
              <input type="text" name={(this.state.ModalShow ? 'code' : (this.state.ModalShow2 ? 'code2' : ""))} 
                value={(this.state.ModalShow ? this.state.code : (this.state.ModalShow2 ? this.state.code2 : ""))} 
                onChange={this.handleChange} className="form-control"
                aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={(this.state.ModalShow ? this.handleValidate : (this.state.ModalShow2 ? this.handleValidateDelete : ""))}>
              {utils.lbl[`${(this.state.lang)}.8`]}
            </button>
            <button type="button" className="btn btn-secondary" onClick={(this.state.ModalShow ? this.Modal : (this.state.ModalShow2 ? this.Modal2 : ""))}>
              {utils.lbl[`${(this.state.lang)}.1`]}
            </button>
          </div>
        </Modal>

        <Modal visible={this.state.ModalShow4}>
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.3`]}</h5>
          </div>
          <div className="modal-footer">
            <h3>{utils.lbl[`${(this.state.lang)}.10`]}</h3>
            <Link to={{
              pathname: '/',
              state: { info: this.state.info }
            }} type="button" className="btn btn-primary">
              {utils.lbl[`${(this.state.lang)}.11`]}
            </Link>
          </div>
        </Modal>

      </React.Fragment>
    )
  }
}

export default withRouter(CompteModification)