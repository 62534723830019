export var lbl = [];

lbl["fr.0"] = "Créer article";
lbl["fr.1"] = "Annuler";
lbl["fr.2"] = "Alerte !";
lbl["fr.3"] = "Votre article a été créé";
lbl["fr.4"] = "OK";
lbl["fr.5"] = "Veuillez compléter la saisie des données";
lbl["fr.6"] = "> Montre Menu Magasin";
lbl["fr.7"] = "v Cache Menu Magasin";

/* AR */


lbl["ar.0"] = "إضافة سلعة";
lbl["ar.1"] = "إلغاء";
lbl["ar.2"] = "إنذار !";
lbl["ar.3"] = "تمت إضافة السلعة";
lbl["ar.4"] = "حسنا";
lbl["ar.5"] = "المرجو إتمام إدخال المعلومات";
lbl["ar.6"] = "أظهر قائمة المحل <";
lbl["ar.7"] = "اخف قائمة المحل v";

/* EN */

lbl["en.0"] = "Add article";
lbl["en.1"] = "Cancel";
lbl["en.2"] = "Alert!";
lbl["en.3"] = "Article created successfully";
lbl["en.4"] = "OK";
lbl["en.5"] = "Please complete the data entry";
lbl["en.6"] = "> Show Shop Menu";
lbl["en.7"] = "v Hide Shop Menu";

/* DE */

lbl["de.0"] = "Artikel erstellen";
lbl["de.1"] = "Löschen";
lbl["de.2"] = "Alarm!";
lbl["de.3"] = "Ihr Artikle wurde erstellet";
lbl["de.4"] = "OK";
lbl["de.5"] = "Bitte vervollständigen Sie die Dateneingabe";
lbl["de.6"] = "> Showen Agence Menu";
lbl["de.7"] = "v Hidden Agence Menu";

