export var lbl = [];

lbl["fr.0"] = "Bonjour";
lbl["fr.1"] = "Accueil";
lbl["fr.2"] = "Profil";
lbl["fr.3"] = "Commandes";
lbl["fr.4"] = "Nouveau commerçant ? Créer un Magasin";
lbl["fr.5"] = "Nouvel Utilisateur ? Créer un Compte";
lbl["fr.6"] = "Etat d'une commande";
lbl["fr.7"] = "Login";
lbl["fr.8"] = "Déconnexion avec succès !";

/* AR */

lbl["ar.0"] = "مرحبا";
lbl["ar.1"] = "استقبال";
lbl["ar.2"] = "حساب";
lbl["ar.3"] = "الطلبات";
lbl["ar.4"] = "تاجر جديد؟ فتح متجر";
lbl["ar.5"] = "زبون جديد؟ فتح حساب";
lbl["ar.6"] = "تتبع الطلبات";
lbl["ar.7"] = "تسجيل الدخول";
lbl["ar.8"] = "تسجيل الخروج موفق !";

/* EN */

lbl["en.0"] = "Hello";
lbl["en.1"] = "Home";
lbl["en.2"] = "Profile";
lbl["en.3"] = "Orders";
lbl["en.4"] = "New vendor ? Create a shop";
lbl["en.5"] = "New user ? Create an account";
lbl["en.6"] = "Order status";
lbl["en.7"] = "Log on";
lbl["en.8"] = "Successfully disconnected!";

/* DE */

lbl["de.0"] = "Hallo";
lbl["de.1"] = "Startseite";
lbl["de.2"] = "Profil";
lbl["de.3"] = "Bestellung";
lbl["de.4"] = "Neuer Händler? Erstellen Sie ein Geschäft";
lbl["de.5"] = "Neuer Benutzer ? Ein Konto erstellen";
lbl["de.6"] = "Status der Bestellung";
lbl["de.7"] = "Anmelden";
lbl["de.8"] = "Erfolgreich getrennt!";

