export var lbl = [];

lbl["fr.0"] = "Nouvelles Commandes";
lbl["fr.1"] = "Commandes en préparation";
lbl["fr.2"] = "Commandes en livraison";
lbl["fr.3"] = "Commandes en annulation";
lbl["fr.4"] = "Commandes reçues";
lbl["fr.5"] = "Commandes annulées";
lbl["fr.6"] = "Magasin";
lbl["fr.7"] = "Numéro commande";
lbl["fr.8"] = "Livraison";
lbl["fr.9"] = "Montant";
lbl["fr.10"] = "Quantité";
lbl["fr.11"] = "Annonce";
lbl["fr.12"] = "Image";
lbl["fr.13"] = "Opération";
lbl["fr.14"] = "Date";
lbl["fr.15"] = "Statut";
lbl["fr.16"] = "#";
lbl["fr.17"] = "Alerte !";
lbl["fr.18"] = "Etes-vous sur de vouloir annuler la Commande ?";
lbl["fr.19"] = "Annuler";
lbl["fr.20"] = "Oui";
lbl["fr.21"] = "Abandonner";
lbl["fr.22"] = "OK";

/* AR */

lbl["ar.0"] = "طلبات جديدة";
lbl["ar.1"] = "طلبات للإعداد";
lbl["ar.2"] = "طلبات للتوصيل";
lbl["ar.3"] = "طلبات للإلغاء";
lbl["ar.4"] = "طلبات موصلة";
lbl["ar.5"] = "طلبات ملغية";
lbl["ar.6"] = "متجر";
lbl["ar.7"] = "رقم الطلب";
lbl["ar.8"] = "توصيل";
lbl["ar.9"] = "حاصل";
lbl["ar.10"] = "كمية";
lbl["ar.11"] = "إعلان";
lbl["ar.12"] = "صورة";
lbl["ar.13"] = "عملية";
lbl["ar.14"] = "تاريخ";
lbl["ar.15"] = "وضعية";
lbl["ar.16"] = "#";
lbl["ar.17"] = "!إنذار";
lbl["ar.18"] = "هل أنتم واثقون من رغبتكم بإلغاء الطلب؟";
lbl["ar.19"] = "إلغاء";
lbl["ar.20"] = "نعم";
lbl["ar.21"] = "التّخلي";
lbl["ar.22"] = "حسنا";

/* EN */

lbl["en.0"] = "New Orders";
lbl["en.1"] = "Orders in preparation";
lbl["en.2"] = "Orders on delivery";
lbl["en.3"] = "Cancellation orders";
lbl["en.4"] = "Orders received";
lbl["en.5"] = "Canceled orders";
lbl["en.6"] = "Shop";
lbl["en.7"] = "Order ID";
lbl["en.8"] = "Delivery";
lbl["en.9"] = "Amount";
lbl["en.10"] = "Amount";
lbl["en.11"] = "Advert";
lbl["en.12"] = "Photo";
lbl["en.13"] = "Operation";
lbl["en.14"] = "Date";
lbl["en.15"] = "Status";
lbl["en.16"] = "#";
lbl["en.17"] = "Alert!";
lbl["en.18"] = "Are you sure you want to cancel the Order ?";
lbl["en.19"] = "Cancel";
lbl["en.20"] = "Yes";
lbl["en.21"] = "Abort";
lbl["en.22"] = "OK";

/* DE */

lbl["de.0"] = "Neue Orders";
lbl["de.1"] = "Bestellungen in Vorbereitung";
lbl["de.2"] = "Bestellungen im Lieferung";
lbl["de.3"] = "Bestellungen stornieren";
lbl["de.4"] = "Bestellungen Erhalten";
lbl["de.5"] = "Stornieren Bestellungen";
lbl["de.6"] = "Shop";
lbl["de.7"] = "Order ID";
lbl["de.8"] = "Lieferung";
lbl["de.9"] = "Menge";
lbl["de.10"] = "Menge";
lbl["de.11"] = "Annonce";
lbl["de.12"] = "Bild";
lbl["de.13"] = "Opération";
lbl["de.14"] = "Datiert";
lbl["de.15"] = "Status";
lbl["de.16"] = "#";
lbl["de.17"] = "Alarm!";
lbl["de.18"] = "Sind Sie sicher,dass Sie die Bestellung stornieren möchten ?";
lbl["de.19"] = "Abberchen";
lbl["de.20"] = "Ja";
lbl["de.21"] = "Abandonner";
lbl["de.22"] = "OK";

