export var lbl = [];

lbl["fr.0"] = "SUIVI";
lbl["fr.1"] = "Dénomination";
lbl["fr.2"] = "Identifiant société national";
lbl["fr.3"] = "Date création";
lbl["fr.4"] = "Ville";
lbl["fr.5"] = "Tél";
lbl["fr.6"] = "Mail";
lbl["fr.7"] = "Valider";
lbl["fr.8"] = "Activé";
lbl["fr.9"] = "ERREUR : ";

/* AR */

lbl["ar.0"] = "تتبع";
lbl["ar.1"] = "اسم المتجر";
lbl["ar.2"] = "رقم الشركة الوطني";
lbl["ar.3"] = "تاريخ الإنشاء";
lbl["ar.4"] = "المدينة";
lbl["ar.5"] = "الهاتف";
lbl["ar.6"] = "بريد إلكتروني";
lbl["ar.7"] = "تأكيد";
lbl["ar.8"] = "مفعّل";
lbl["ar.9"] = "خطأ : ";

/* EN */

lbl["en.0"] = "FOLLOWED";
lbl["en.1"] = "Dénomination";
lbl["en.2"] = "Client Number";
lbl["en.3"] = "Creation Date";
lbl["en.4"] = "City";
lbl["en.5"] = "Phone";
lbl["en.6"] = "Mail";
lbl["en.7"] = "Validate";
lbl["en.8"] = "Enabled";
lbl["en.9"] = "ERROR : ";

/* DE */

lbl["de.0"] = "Zu kasse";
lbl["de.1"] = "Name";
lbl["de.2"] = "Kundennummer";
lbl["de.3"] = "Erselldatum";
lbl["de.4"] = "Stadt";
lbl["de.5"] = "Telfon";
lbl["de.6"] = "E-Mail";
lbl["de.7"] = "Bestätigen";
lbl["de.8"] = "Activieren";
lbl["de.9"] = "ERROR : ";

