export var lbl = [];

lbl["fr.0"] = "SUIVI";
lbl["fr.1"] = "Dénomination";
lbl["fr.2"] = "Identifiant société national";
lbl["fr.3"] = "Date création";
lbl["fr.4"] = "ERREUR : ";

/* AR */

lbl["ar.0"] = "تتبع";
lbl["ar.1"] = "اسم المتجر";
lbl["ar.2"] = "رقم الشركة الوطني";
lbl["ar.3"] = "تاريخ الإنشاء";
lbl["ar.4"] = "خطأ : ";

/* EN */

lbl["en.0"] = "FOLLOWED";
lbl["en.1"] = "Denomination";
lbl["en.2"] = "Client Number";
lbl["en.3"] = "Creation Date";
lbl["en.4"] = "ERROR : ";

/* DE */

lbl["de.0"] = "Zu kasse";
lbl["de.1"] = "Name";
lbl["de.2"] = "Kundennummer";
lbl["de.3"] = "Erstelldatum";
lbl["de.4"] = "ERROR : ";

