import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as utils from '../lang/demandeAnnulationCommande';
import * as comons from '../component/commun';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class DemandeAnnulationCommande extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numCommande: '',
      mailRens: '',
      tele: '',
      codeConfirmation: '',
      numcmd: this.props.match.params.numCommande,
      mail: this.props.match.params.email,
      tel: this.props.match.params.tele,
      id: this.props.match.params.id,
      confirmeFin: []
    };
  }

  demandeConfirm = () => {
    const ob = {
      numcmd: this.props.match.params.numCommande,
      mail: this.props.match.params.email,
      tel: this.props.match.params.tele,
      id: this.props.match.params.id,
      //  idPersonnel: this.state.info.id_personnel,
      token: this.state.info.token
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/client/demande_annulation/init`, ob).then(res => {
      // console.log(res)
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  validerCode = () => {
    const ob = {
      token: this.state.info.token,
      numcmd: this.props.match.params.numCommande,
      mail: this.props.match.params.email,
      tel: this.props.match.params.tele,
      id: this.props.match.params.id,
      codeConfirmation: this.state.codeConfirmation
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/client/demande_annulation/confirm`, ob).then(res => {
      this.setState({
        confirmeFin: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidMount() {
    this.demandeConfirm();
    this.validerCode();
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: [e.target.value]
    });
  }

  Modal = () => {
    this.setState({
      ModalShow: !this.state.ModalShow
    });
  }



  render() {
    //const cmd = data.products.find(x => x.id === this.props.match.params.id)
    //console.warn(this.state)
    //console.warn(this.props)
    const { numCommande, email, tele, id } = this.props.match.params
    const { codeConfirmation } = this.state
    return (
      <>

        <nav style={{ backgroundColor: '#080350' }}>
          <div className="d-flex justify-content-center pt-2">
            <h2 style={{ textAlign: 'center', color: 'white' }}>{utils.lbl[`${(this.state.lang)}.0`]}</h2>
          </div>
        </nav>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg">
              <div className="row d-inline-flex">
                <div className="input-group input-group-md mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-md">{utils.lbl[`${(this.state.lang)}.1`]}</span>
                  </div>
                  <input value={numCommande} className="form-control" aria-describedby="inputGroupPrepend"
                    aria-label="Small" disabled />
                </div>
              </div>
              <div className="row d-flex flex-row ">
                <div className="input-group input-group-md mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-md">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                  </div>
                  <input value={email} className="form-control" aria-describedby="inputGroup-sizing-md"
                    aria-label="Small" disabled />
                </div>
              </div>
              <div className="row">
                <div className="input-group input-group-md mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-md">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                  </div>
                  <input value={tele} className="form-control" aria-describedby="inputGroupPrepend"
                    aria-label="Small" disabled />
                </div>
              </div>
              <div className="row">
                <p>{utils.lbl[`${(this.state.lang)}.4`]}</p>
                <br />
                <div>
                  <div className="input-group input-group-md mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-md">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                    </div>
                    <input type="text" name='codeConfirmation' value={codeConfirmation} onChange={this.handleChange} className="form-control" 
                      aria-label="Small" aria-describedby="inputGroup-sizing-md" />
                  </div>
                </div>
                {/* <div className='d-flex flex-row justify-content-end'>
                  <button className="btn btn-primary" onClick={this.validerCode}>Confirmation de l'annulation</button>
                </div> */}
              </div>
              <div className="d-flex justify-content-center mb-5" >
                <Link to={{
                      pathname: `/demande-annulation-commande-fin/` + id + '/' + numCommande + '/' + tele + '/' + email,
                      info: this.state.confirmeFin
                    }} className="btn btn-primary" type="button">
                  {utils.lbl[`${(this.state.lang)}.6`]}
                </Link>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}