import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
//import '../../../styles/login.css';
import { toast } from 'react-toastify';
//import API from '../../Http/api';
import * as comons from '../component/commun';
import { PushSpinner } from 'react-spinners-kit';
import axios from 'axios';



class ModalAddPublicite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      agences: [],
      villes: [],
      pays: [],
      id_ville: '',
      id_pays: '',
      seccesMessageAdd: 'seccesfuly Adding',
      errorMessageAdd: 'Field Adding',
      is_pays_loading: true,
      is_agences_loading: true,
      selectValue: '', list: [], profil: '', idemp: null
    }
  }

  componentDidMount() {
    this.agence_for_publicite();
    this.getPays();
    // this.getVille();
  }

  getVille = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/ville/${this.state.id_pays}/${this.state.lang}`).then(res => {
      this.setState({
        villes: res.data,
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  getPays = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/pays/${this.state.lang}`).then(res => {
      this.setState({
        pays: res.data,
        is_pays_loading: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  agence_for_publicite = () => {
    var dmdData = {
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    };
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/select_agence_publicite`, dmdData).then(res => {
      //console.log("RES=" + res)
      this.setState({
        agences: res.data,
        is_agences_loading: false //Informer de la fin du chargement

      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  add_agence_publicite = () => {

    const pub = {
      id_agence: this.state.id_agence,
      id_ville: this.state.id_ville,
      id_pays: this.state.id_pays,
      ddate: this.state.ddate,
      idPersonnel: this.props.location.state.info.id_personnel,
      token: this.props.location.state.info.token
    }
    alert(JSON.stringify(this.props.location.state.info.token));


    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/add/publicite`, pub).then(res => {
      this.setState({
        publicite: pub
      });
      toast.success(this.state.seccesMessageAdd, {
        autoClose: 3000
      });
      this.props.onClose();
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id_pays !== this.state.id_pays) {
      this.getVille()
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name == 'id_pays') {
      this.getVille();
    }
  }
  onChangeVille = (e) => {
    this.state.id_ville = '';
    for (let i = 0; i < this.state.villes.length; i++) {
      if (this.state.villes[i].lbl == e.target.value) {
        this.setState({ id_ville: this.state.villes[i].id });
        break;
      }
    }
  }
  handleChangeCountry = (e) => {
    this.state.id_pays = '';
    for (let i = 0; i < this.state.pays.length; i++) {
      if (this.state.pays[i].lbl == e.target.value) {
        this.setState({ id_pays: this.state.pays[i].id });
        break;
      }
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    const modal1 = {
      backgroundColor: '#EEEEEE',
      borderRadius: 5,
      maxWidth: 800,
      minHeight: 200,
      margin: '0 auto',
      padding: 30
    };

    const modal = {
      padding: 50,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      Zindex: 10040,
      backgroundColor: 'rgba(0,0,0,0.3)',
      overflow: 'auto',
      overflowY: 'auto',
    }

    const { is_agences_loading, is_pays_loading, pays, villes, agences } = this.state
    if (is_pays_loading || is_agences_loading) { //Message informatif du temps d'attente
      return <div className='' style={{ marginLeft: '38rem', marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={is_pays_loading || is_agences_loading} />
      </div>
    }

    // let optionAgences 
    // let optionPays = 
    // let optionVilles = 


    return (
      <>

        <div className="backdrop " style={modal}>
          <div style={modal1}>

            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">ADD AGENCE PUBLICITE</h5>
              <button onClick={this.props.onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <label htmlFor="example-email-input" className="col-form-label">Nom Agence</label>
              <div className="form-group row">
                <select onChange={this.handleChange} value={this.state.id_agence} defaultValue={'DEFAULT'} className="custom-select form-control" id="inputGroupSelect01" name="id_agence">
                  <option value="DEFAULT" disabled>Select your Agence</option>
                  {agences.map(item => (
                    <option value={item.id} key={item.id}>{item.lbl}</option>
                  ))
                  }
                </select>
              </div>

              <label htmlFor="example-email-input" className="col-form-label">Selectionner la semaine</label>
              <div className="form-group row">
                <input onChange={this.handleChange} value={this.state.ddate} className="form-control ml-3 mr-3" type="week" placeholder="date" name="ddate" />
              </div>

              <label htmlFor="example-email-input" className="col-form-label">Selectionner Votre Pays</label>
              <div className="form-group row">
                <input className="form-control py-2 border-right-0 border mr-2" placeholder="choisir une pays" type='text' list='data1' name='id_pays'
                  onChange={this.handleChangeCountry} />
                <datalist id="data1"
                  value={this.state.id_pays} name='id_pays'>

                  {pays.map(option => (
                    <option key={option.id} value={option.lbl} id={option.id} />
                  ))}
                </datalist>
              </div>

              <label htmlFor="example-email-input" className="col-form-label">Selectionner Votre Ville</label>
              <div className="form-group row">
                <input className="form-control py-2 border-right-0 border mr-2"
                  placeholder="choisir une pays" type='text' list='data' name='id_pays'
                  onChange={this.onChangeVille} />
                <datalist id="data" value={this.state.id_ville} name='id_ville'>
                  <option value="">ville</option>
                  {villes.map((option) => (
                    <option key={option.id} value={option.lbl} id={option.id} />
                  ))}
                </datalist>
              </div>

            </div>

            <div className="modal-footer"></div>
            <button type="button" className="btn btn-primary ml-5 mr-5 mb-2" onClick={this.add_agence_publicite.bind(this)}>Add publicite</button>
          </div>

        </div>
      </>
    );

  }
}

ModalAddPublicite.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};


export default ModalAddPublicite;