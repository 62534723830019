export var lbl = [];

lbl["fr.0"] = "";
lbl["fr.1"] = "Alerte !";
lbl["fr.2"] = "Veuillez saisir le code de confirmation qui vous a été envoyé par mél";
lbl["fr.3"] = "Code de confirmmation";
lbl["fr.4"] = "LOGIN=MEL";
lbl["fr.5"] = "Annuler";
lbl["fr.6"] = "Créer compte client";
lbl["fr.7"] = "Votre mél a été validé. Félicitations ! Votre compte a été créé avec succès.";
lbl["fr.8"] = "OK";
lbl["fr.9"] = "Ce mél est déjà utilisé!!!";
lbl["fr.10"] = "Veuillez compléter la saisie des données";
lbl["fr.11"] = "Je reconnais avoir lu, compris et accepté ";
lbl["fr.12"] = "les conditions générales d'utilisation (CGU)";
lbl["fr.13"] = "Accepter";
lbl["fr.14"] = "Créer compte";

/* AR */

lbl["ar.0"] = "";
lbl["ar.1"] = "إنذار !";
lbl["ar.2"] = "الرجاء إدخال رمز التأكد المرسل عبر البريد الإلكتروني";
lbl["ar.3"] = "رمز التأكد";
lbl["ar.4"] = "تعريف الدخول=البريد الإلكتروني";
lbl["ar.5"] = "إلغاء";
lbl["ar.6"] = "فتح حساب زبون";
lbl["ar.7"] = "تم التحقق من البريد الإلكتروني. هنيئا ! تم فتح الحساب بنجاح.";
lbl["ar.8"] = "حسنا";
lbl["ar.9"] = "هذا البريد الإلكتروني مستخدم!!!";
lbl["ar.10"] = "المرجو إتمام إدخال المعلومات";
lbl["ar.11"] = "أقر أني اطلعت و فهمت و وافقت ";
lbl["ar.12"] = "على الشروط العامة للاستعمال";
lbl["ar.13"] = "أوافق";
lbl["ar.14"] = "فتح حساب";

/* EN */

lbl["en.0"] = "";
lbl["en.1"] = "Alert!";
lbl["en.2"] = "Please enter the confirmation code that was sent to you by email";
lbl["en.3"] = "Confirmation code";
lbl["en.4"] = "LOGIN=EMAIL";
lbl["en.5"] = "Cancel";
lbl["en.6"] = "Create client account";
lbl["en.7"] = "Your email has been validated. Congratulations! Your account has been successfully created.";
lbl["en.8"] = "OK";
lbl["en.9"] = "This email already exist!!!";
lbl["en.10"] = "Please complete the data entry";
lbl["en.11"] = "I acknowledge having read, understood and accepted ";
lbl["en.12"] = "the general conditions of use";
lbl["en.13"] = "Agree";
lbl["en.14"] = "Create account";

/* DE */

lbl["de.0"] = "";
lbl["de.1"] = "Alarm!";
lbl["de.2"] = "Bitte geben Sie den Kontrollcode ein,der Ihnen per E-Mail zugesandt wurde";
lbl["de.3"] = "Bestätigungscode";
lbl["de.4"] = "Anmeldung=E-MAIL";
lbl["de.5"] = "Abbrechen";
lbl["de.6"] = "Konto Erstellen"; // "Bestätigen";
lbl["de.7"] = "Ihre E-Mail wurde validiert. Herzlichen Glückwunsch,Ihr Konto wurde erfolgreich erstellet";
lbl["de.8"] = "OK";
lbl["de.9"] = "Diese E-Mail existiert bereits!!!";
lbl["de.10"] = "Bitte vervollständigen Sie die Dateneingabe";
lbl["de.11"] = "I acknowledge having read, understood and accepted ";
lbl["de.12"] = "the general conditions of use";
lbl["de.13"] = "Agree";
lbl["de.14"] = "Konto Erstellen";

