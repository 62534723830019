import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PushSpinner } from 'react-spinners-kit';
import MenuUser from '../component/menuUser';
import CVAgence from '../component/cvAgence';
import * as utils from '../lang/vitrineConsultationDetail';
import * as comons from '../component/commun';
import { faShoppingCart, faStepBackward, faStepForward, faAudioDescription, faWallet, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VitrinePanier from '../component/vitrinePanier';

toast.configure()

class VitrineConsultationDetail extends Component {
  constructor(props) {
    super(props);
    //  console.log("NOW VitrineConsultationDetail.CTR this.props.location.resultIDs = " + JSON.stringify(this.props.location.resultIDs));
    this.state = {
      isLoading1: true, isLoading2: false, isLoading3: true, //Pour patienter le temps du chargement
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'en'),
      panier: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.panier != 'undefined') && (this.props.location.state.panier != '') ? this.props.location.state.panier : []),
      panier_copy: [],
      color: ((typeof this.props.location.dataAgence != 'undefined') ? this.props.location.dataAgence.couleur : "#000000"),
      devise: ((typeof this.props.location.dataAgence != 'undefined') ? this.props.location.dataAgence.devise : ""),
      dataAgence: ((typeof this.props.location.dataAgence != 'undefined') ? this.props.location.dataAgence : ""),
      showVitrine: ((typeof this.props.location.dataAgence != 'undefined') ? true : false),
      resultIDs: ((typeof this.props.location.resultIDs != 'undefined') ? this.props.location.resultIDs : []),

      montant: '',
      quantite: '',
      myArticle: [{}],
      id_article: this.props.match.params.idArticle,
      id_agence: this.props.match.params.idAgence,
      prix: '',
      btnPrevOk: true,
      btnNextOk: true,
      modalShowPaAc: 0
    };
    this.state.info.lang = this.state.lang;
    this.showArticle(0);
  }

  getCVAgence = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cv_agence/${this.state.id_agence}/${this.state.lang}`).then(res => {
      this.props.location.dataAgence = res.data;
      for (let i = 0; i < res.data.droits.length; i++) {
        if (res.data.droits[i].idm == 2) {
          this.setState({
            dataAgence: res.data,
            showVitrine: res.data.droits[i].acces,
            color: res.data.couleur,
            devise: res.data.devise,
            isLoading2: false //Informer de la fin du chargement
          });
          break;
        }
      }
    }).catch(err => {
      toast.error(err.message, {
        autoClose: 4000
      });
    });
  }

  getArticle = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/stock/article/client/${this.state.id_article}/${this.state.lang}`).then(res => {
      this.setState({
        myArticle: res.data[0],
        maxQuantite: res.data[0].quantite,
        isLoading1: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      });
    });
  }

  showArticle = (pas) => {
    this.state.btnPrevOk = true;
    this.state.btnNextOk = true;
    var newIndx = this.state.resultIDs.indexOf("" + this.state.id_article) + parseInt(pas);
    if (0 <= newIndx && newIndx < this.state.resultIDs.length) {
      this.state.id_article = this.state.resultIDs[newIndx];
      this.getArticle();
    }
    else if (this.state.id_article != '') {
      this.getArticle();
    }

    if (this.state.resultIDs.length == 1) {
      this.state.btnPrevOk = true;
      this.state.btnNextOk = true;
    }
    else if (newIndx <= 0) {
      this.state.btnPrevOk = true;
      this.state.btnNextOk = false;
    }
    else if (newIndx == this.state.resultIDs.length - 1) {
      this.state.btnPrevOk = false;
      this.state.btnNextOk = true;
    }
    else {
      this.state.btnPrevOk = false;
      this.state.btnNextOk = false;
    }
  }

  notify = () => {
    toast.error(utils.lbl[`${(this.state.lang)}.14`], { autoClose: 1200 });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  disable = () => {
    this.props.history.goBack()
  }

  handleAddPanier = () => {
    var cmd = {
      id_article: this.state.id_article,
      annonce: this.state.myArticle.annonce,
      foto: this.state.myArticle.photo,
      quantite: this.state.quantite,
      maxQuantite: this.state.myArticle.quantite,
      idLiv: this.state.myArticle.id_livraison,
      frLiv: this.state.myArticle.frais_livraison,
      prix: (this.state.myArticle.prix_promo != null && this.state.myArticle.prix_promo != undefined ? this.state.myArticle.prix_promo : this.state.myArticle.prix_vente)
    };
    this.state.panier.push(cmd);
    this.setState({
      quantite: '',
      modalShowPaAc: 0
    });
  }

  showBasket = () => {
    this.state.panier_copy = [];
    this.state.panier.forEach(el => {
      var cmdc = {
        id_article: el.id_article,
        annonce: el.annonce,
        foto: el.foto,
        quantite: el.quantite,
        maxQuantite: el.maxQuantite,
        idLiv: el.idLiv,
        frLiv: el.frLiv,
        prix: el.prix
      };
      this.state.panier_copy.push(cmdc);
    });
    this.setState({
      modalShowPaAc: 2
    });
  }

  addToBasket = () => {
    this.setState({
      modalShowPaAc: 1,
      prix: (typeof this.state.myArticle.prix_promo != 'undefined' && this.state.myArticle.prix_promo != null ? this.state.myArticle.prix_promo : this.state.myArticle.prix_vente)
    });
  }

  buyOne = () => {
    this.state.panier_copy = [];
    const cmd = {
      id_article: this.state.id_article,
      quantite: 1,
      maxQuantite: this.state.myArticle.quantite,
      idLiv: this.state.myArticle.id_livraison,
      frLiv: this.state.myArticle.frais_livraison,
      prix: (typeof this.state.myArticle.prix_promo != 'undefined' && this.state.myArticle.prix_promo != null ? this.state.myArticle.prix_promo : this.state.myArticle.prix_vente)
    };

    this.state.panier.push(cmd);
    this.setState({
      modalShowPaAc: 4
    });
  }

  componentDidMount = () => {
    if (typeof this.props.location.dataAgence == 'undefined') {
      this.state.isLoading2 = true;
      this.getCVAgence();
    }
  }

  changeLanguage = (lang) => {
    this.setState({ lang });
    this.state.info.lang = lang;
  }

  changePanier = (panier) => {
    this.setState({ panier });
  }

  changeModal = (modalShowPaAc) => {
    this.setState({ modalShowPaAc });
  }



  render() {
    //console.log(this.state)
    //console.log(this.props)

    const { isLoading1, isLoading2, myArticle } = this.state

    if (isLoading1 || isLoading2) { //Message informatif du temps d'attente
      return <div className='row d-flex justify-content-center' style={{ marginTop: '20rem' }}>
        <PushSpinner size={50} color="#686769" loading={isLoading1 || isLoading2} />
      </div>
    }

    return (
      <>

        <MenuUser location={this.props.location} changeLanguage={this.changeLanguage.bind(this)} lang={this.state.lang} />
        {this.state.showVitrine ?
          <>
            <div className='container-fluid' style={{ backgroundColor: this.state.color, color: (this.state.color > "#888888" ? "#000000" : "#ffffff") }}>
              <div className='row d-flex justify-content-center mb-5'>
                <div className='d-flex justify-content-center'>
                  <div className={this.state.panier > 0 ? 'col-lg-10 col-md-10 col-sm-12 ' : 'col'}>
                    <CVAgence location={this.props.location} />
                  </div>
                </div>
                {this.state.panier.length > 0 ?
                  <div className='col-lg-2 col-md-2 col-sm-12 mt-5'>
                    <div style={{ marginRight: '10vh', marginTop: '2vh', cursor: 'pointer' }}>
                      <FontAwesomeIcon style={{ width: '5rem', height: '5rem' }} onClick={this.showBasket} icon={faCartPlus} />
                      <span class="badge badge-light" style={{ width: '2rem', height: '2rem' }}>
                        <div className='h5'>{this.state.panier.length}</div>
                      </span>
                    </div>
                  </div>
                : ''}
              </div>
              <div className='row'>
                <div className='col d-flex justify-content-center'>
                  {/*********************** Affichage de l'article ***********************/}
                  <div style={{ margin: 'auto', width: '600px' }}>
                    <div style={{ backgroundColor: '#ffffff', color: '#000000' }}>
                      <div className="card-header mt-1 p-0">
                        <div className="card-title d-flex justify-content-between p-0 m-0">
                          <h5>{myArticle.cat_article}</h5>
                          <h5 className="card-title d-flex justify-content-between">
                            <span>{myArticle.clientel}</span>
                          </h5>
                        </div>
                      </div>
                      <div className="card-body d-flex flex-column justify-content-center">
                        <div className="container mt-1">
                          <div className="row justify-content-center">
                            {/********************** Bouton Précédant **********************/}
                            <button onClick={() => this.showArticle(-1)} className="btn btn-sm btn-secondary" style={{ width: '2rem' }} disabled={this.state.btnPrevOk}>
                              <FontAwesomeIcon icon={faStepBackward} />
                            </button>
                            {/********************** Photo de l'article **********************/}
                            <div className='col-9'>
                              <img src={myArticle.photo} style={{ minHeight: '12rem', minWidth: '12rem' }} className="card-img-top mr-2" alt="" />
                            </div>
                            {/********************** Bouton Suivant **********************/}
                            <button onClick={() => this.showArticle(1)} className="btn btn-sm btn-secondary" style={{ width: '2rem' }} disabled={this.state.btnNextOk}>
                              <FontAwesomeIcon icon={faStepForward} />
                            </button>
                          </div>
                        </div>
                        <div className="container mt-1">
                          {/********************** Audio description **********************/}
                          <div className="row justify-content-center">
                            <FontAwesomeIcon className="mr-1" style={{ zoom: 2 }} icon={faAudioDescription} />
                            <div className="audio-wrap ">
                              <audio src={myArticle.audio} controls="controls"></audio>
                            </div>
                          </div>
                        </div>
                        <div className="container mt-1">
                          <div className="row justify-content-center">
                            <div className="d-flex justify-content-center p-0">
                              <label className="label mr-2 badge badge-dark">
                                {utils.lbl[`${(this.state.lang)}.0`]}
                              </label>
                              <span className="label mr-2 ">
                                <h5>{myArticle.annonce}</h5>
                              </span>
                            </div>
                            {myArticle.prix_promo !== null ?
                              <div className="d-flex justify-content-center p-0">
                                <label className="label mr-2 badge badge-dark">
                                  {utils.lbl[`${(this.state.lang)}.1`]}
                                </label>
                                <span className="label mr-2">
                                  <h5>{myArticle.prix_promo} {this.state.devise}</h5>
                                </span>
                                <span className="label mr-2">
                                  <h6 style={{ textDecorationLine: 'line-through' }}>
                                    {myArticle.prix_vente}
                                  </h6>
                                </span>
                              </div>
                            :
                              <div className="d-flex justify-content-center p-0">
                                <label className="label mr-2 badge badge-dark">
                                  {utils.lbl[`${(this.state.lang)}.1`]}
                                </label>
                                <span className="label mr-2 ">
                                  <h5>{myArticle.prix_vente} {this.state.devise}</h5>
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="p-0 m-0">
                          <div className="container m-0">
                            <ul className='list-group'>
                              <li className='list-group-item d-flex justify-content-between flex-row'>
                                <div className='row m-0'>
                                  <span className="badge badge-dark mr-2">{utils.lbl[`${(this.state.lang)}.2`]}</span>
                                  <p style={{ height: '0.5rem' }}>{" "}{myArticle.libelleLiv}</p>
                                </div>
                                <div className='row'>
                                  <span className="badge badge-dark mr-2">{utils.lbl[`${(this.state.lang)}.3`]}</span>
                                  {myArticle.frais_livraison !== null || myArticle.frais_livraison !== '' ?
                                    <p style={{ height: '0.5rem' }}>{" "}{myArticle.frais_livraison} {this.state.devise}</ p>
                                  : ''}
                                </div>
                              </li>
                              <li className='list-group-item'>
                                <div className='row '>
                                  <span className='badge badge-dark mr-2'>{utils.lbl[`${(this.state.lang)}.4`]}</span>
                                  <p style={{ height: '0.5rem' }}>{" "} {myArticle.description}</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/********************** Bouton Commander & Panier **********************/}
                          {this.state.dataAgence.droits[this.state.dataAgence.droits.findIndex(s => s.idm === 4)].acces ?
                            <div className='row p-0 m-0 d-flex justify-content-center'>
                              <button className="btn btn-primary btn-sm m-1" onClick={this.buyOne} hidden={this.state.panier.length !== 0}>
                                <FontAwesomeIcon className="mr-3" icon={faWallet} />
                                {utils.lbl[`${(this.state.lang)}.5`]}
                              </button>
                          <button className="btn btn-primary btn-sm m-1" onClick={this.addToBasket}>
                            <FontAwesomeIcon className="mr-3" icon={faShoppingCart} />
                            {utils.lbl[`${(this.state.lang)}.6`]}
                          </button>
                        </div>
                        : ''}
                      <div className='d-flex justify-content-center m-0 p-0'>
                        {/********************** Bouton Retour vitrine **********************/}
                        <Link to={{
                          pathname: '/vitrine/' + this.state.id_agence,
                          state: {
                            info: this.state.info,
                            panier: this.state.panier
                          }
                        }} className="btn btn-sm btn-secondary mt-1">
                          {utils.lbl[`${(this.state.lang)}.7`]}
                        </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <VitrinePanier location={this.props.location} changeModal={this.changeModal.bind(this)} modalShowPaAc={this.state.modalShowPaAc} changePanier={this.changePanier.bind(this)} panier={this.state.panier} panier_copy={this.state.panier_copy} />
          </>
        :
          <div className='row d-flex justify-content-center'>
            <h2>{utils.lbl[`${(this.state.lang)}.32`]}</h2>
          </div>
        }

        {/*----------------------------------------- MODAL AJOUTER UN ARTICLE AU PANIER ------------------------------------*/}

        <Modal visible={this.state.modalShowPaAc == 1}>
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.8`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.9`]}</span>
              </div>
              <input type="number" name='quantite' onChange={this.handleChange} value={this.state.quantite} className="form-control"
                aria-label="Small" aria-describedby="inputGroup-sizing-sm" min="1" max={this.state.maxQuantite} step="1" autoFocus={true} />
            </div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.10`]}</span>
              </div>
              <input type="number" name='montant' value={this.state.prix * this.state.quantite} className="form-control"
                aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
            </div>
            <hr />
            {myArticle.id_livraison !== 1 ?
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.11`]}</span>
                </div>
                <input type="number" name='fraisLivraison' onChange={this.handleChange} value={myArticle.frais_livraison} className="form-control"
                  aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
              </div>
              : ''}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ modalShowPaAc: 0 })}>
              {utils.lbl[`${(this.state.lang)}.12`]}
            </button>
            <button type="button" className="btn btn-primary" onClick={this.handleAddPanier}>
              {utils.lbl[`${(this.state.lang)}.13`]}
            </button>
          </div>
        </Modal>

      </>
    )
  }

  handleBack = () => {
    disableBrowserBackButton()
  }
}

export default withRouter(VitrineConsultationDetail)