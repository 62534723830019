import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { faAt, faPhone, faMapMarkerAlt, faEnvelope, faCheckCircle, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import MenuUser from '../component/menuUser';
import MenuAgence from '../component/menuAgence';
import * as utils from '../lang/magasinIndex';
import * as comons from '../component/commun';
import 'react-toastify/dist/ReactToastify.css';



class MagasinIndex extends Component {
  constructor(props) {
    super(props);
    //console.log("NOW dans MagasinIndex.CTR = " + JSON.stringify(this.props))
    this.state = {
      info: this.props.location.state.info,
      lang: this.props.location.state.info.lang,
      idAgence: this.props.match.params.idAgence,
      color: '#ffffff',
      agence: {
        horaireouvert: [],
        typecommerce: [],
        clientelle: []
      },
      hd: false
    };
  }

  updateColor = () => {
    for (let i = 0; i < this.state.info.comptes.length; i++) {
      if (this.state.info.comptes[i].id_agence == this.state.idAgence) {
        this.state.color = this.state.info.comptes[i].couleur_agence;
        break;
      }
    }
  }

  getCVAgence = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/agence/read_cv_agence/${this.state.idAgence}/${this.state.lang}`).then(res => {
      this.setState({
        agence: res.data
      });
    }).catch(err => {
      toast.error(err.message + err.response.data.error, {
        autoClose: 4000
      })
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.idAgence !== this.state.idAgence) {
      this.getCVAgence();
      this.updateColor();
    }
  }

  componentDidMount = () => {
    this.getCVAgence();
    this.updateColor();
  }

  printCVAgence = () => {
    var input = document.getElementById("divToPrint");
    input.hidden = false;

    var w = input.offsetWidth;
    var h = input.offsetHeight;
    html2canvas(input, {
      dpi: 100, //Set to 300 DPI
      scale: 3, //Adjusts your resolution
    }).then((canvas) => {
      var img = canvas.toDataURL("image/png", 1);
      var doc = new jsPDF('l', 'pt',  'a4');
      doc.addImage(img, 'PNG', -w/4, 0, w, h);
      doc.save("cv-magasin-" + this.state.idAgence + ".pdf");
    });

    input.hidden = true;
  }



  render() {
    //console.warn(this.props)
    //console.warn(this.state)
    const { agence, idAgence } = this.state

    if (idAgence !== this.props.match.params.idAgence) {
      this.setState({
        idAgence: this.props.match.params.idAgence
      });
    }

    return (
      <>

        <div id="divToPrint" width="11in" hidden={true}>
          <font size="1">
            <div width="11in" align="center">
              {agence.msg}
              <br />
              {utils.lbl[`${(this.state.lang)}.12`]}
              <br />
              <img id="imgdiv" src={agence.photo} height='85px' width='85px' />&emsp;
              <QRCode id="qrcodediv" height='85px' width='85px' value={`${comons.srv_ptl}://${comons.srv_url + ((comons.srvf_por != "80" && comons.srvf_por != "443") ? ":" + comons.srvf_por : "")}/vitrine/${this.state.idAgence}`} renderAs={'svg'} size={256} level={"H"} includeMargin={false} />
              <br />
              {agence.nom}
              <br />
              {agence.clientelle.map(item => (
                <span>{item.label}&emsp;</span>
              ))}
              <br />
              {agence.typecommerce.map(item => (
                <span>{item.label}&emsp;</span>
              ))}
              <br />
              <table width="11in" cellspacing="0" cellpadding="3">
                <tr align="center">
                  <th>{utils.lbl[`${(this.state.lang)}.1`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.2`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.3`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.4`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.5`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.6`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.7`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.8`]}</th>
                  <th>{utils.lbl[`${(this.state.lang)}.9`]}</th>
                </tr>
                {this.state.agence.horaireouvert.map((item) => (
                  <tr align="center" bgcolor="white">
                    <th bgcolor="white">{item.hrd}:{item.mind}</th>
                    <th bgcolor="white">{item.hrf}:{item.minf}</th>
                    <th bgcolor="white">{item.lundi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.mardi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.mercredi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.jeudi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.vendredi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.samedi ? 'x' : ''}</th>
                    <th bgcolor="white">{item.dimanche ? 'x' : ''}</th>
                  </tr>
                ))}
              </table>
              {/*<FontAwesomeIcon icon={faMapMarkerAlt} />*/}
              {agence.adr}<br />{agence.ville}
              <br />
              {/*<FontAwesomeIcon className='mr-3 ml-2' icon={faPhone} />*/}
              {agence.tel} -
              {/*<FontAwesomeIcon className='mr-3 ml-2' icon={faEnvelope} />*/}
              {agence.mail} -
              {/*<FontAwesomeIcon className='mr-3 ml-2' icon={faAt} />*/}
              {agence.site}
            </div>
          </font>
        </div>

        <MenuUser location={this.props.location} />
        <div className="container-fluid" style={{ backgroundColor: this.state.color }}>
          <span style={{ cursor: 'pointer' }} className='badge badge-primary m-0 p-0' onClick={() => this.setState({ hd: !this.state.hd })}>
            {this.state.hd ? utils.lbl[`${(this.state.lang)}.10`] : utils.lbl[`${(this.state.lang)}.11`]}
          </span>
          <div className="row" >
            <div className='col-sm-8 col-md-8 col-lg-2 m-0 p-0' hidden={this.state.hd} >
              <MenuAgence location={this.props.location} />
            </div>
            <div id="divcontents" className={this.state.hd ? 'col-12' : 'col-sm-12 col-md-12 col-lg-10'}>
              <h1 className='d-flex justify-content-center' style={{ color: 'white' }}>{agence.msg}</h1>
              <div className="card mb-3" style={{ maxWidth: '990px' }}>
                <div className="row no-gutters">
                  <div className="col-md-4">
                    <div className='d-flex flex-row' style={{ textAlign: 'center' }}>
                      <img id="imgdiv" src={agence.photo} className="card-img" alt="magasin" />
                    </div>
                    <button className="btn btn-primary" id="btnimprim" onClick={() => this.printCVAgence()}>
                      <FontAwesomeIcon icon={faQrcode} />&ensp;{utils.lbl[`${(this.state.lang)}.0`]}
                    </button>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <div className="card-title d-flex justify-content-center mb-5">
                        <h5 style={{ height: '0.5rem', textAlign: 'center' }}>{agence.nom}</h5>
                      </div>
                      <div className="card-text d-flex flex-row justify-content-around mb-5">
                        <h5 style={{ height: '0.5rem', textAlign: 'center' }}>
                          {agence.clientelle.map(item => (
                            <span>{item.label}&emsp;</span>
                          ))}
                        </h5>
                      </div>
                      <div className="card-text d-flex flex-row justify-content-around mt-5">
                        <h5 style={{ height: '0.5rem', textAlign: 'center' }}>
                          {agence.typecommerce.map(item => (
                            <span>{item.label}&emsp;</span>
                          ))}
                        </h5>
                      </div>
                      <div className="container-fluid" style={{ display: 'grid' }}>
                        <table className="table table-sm table-hover table-striped table-inverse table-responsive mt-5" style={{ margin: '0px auto' }}>
                          <thead>
                            <tr style={{ backgroundColor: 'white' }}>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.1`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.2`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.3`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.4`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.5`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.6`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.7`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.8`]}</th>
                              <th scope="col">{utils.lbl[`${(this.state.lang)}.9`]}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.agence.horaireouvert.map((item) => (
                            <tr style={{ backgroundColor: 'white', textAlign: 'center' }}>
                              <td style={{ height: '0.5rem' }}>{item.hrd}:{item.mind}</td>
                              <td style={{ height: '0.5rem' }}>{item.hrf}:{item.minf}</td>
                              <td style={{ height: '0.5rem' }}>{item.lundi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.mardi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.mercredi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.jeudi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.vendredi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.samedi ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                              <td style={{ height: '0.5rem' }}>{item.dimanche ? <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faCheckCircle} /> : ''}</td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className='d-flex flex-row justify-content-center p-0 mt-5'>
                        <h6 style={{ height: '0.5rem', textAlign: 'center' }}>
                          <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faMapMarkerAlt} />
                          {agence.adr}<br />{agence.ville}
                        </h6>
                      </div>
                      <br />
                      <div className='d-flex flex-row justify-content-center p-0 mt-5'>
                        <h6 style={{ height: '0.5rem', textAlign: 'center' }}>
                          <FontAwesomeIcon className='mr-2' style={{ height: '1rem' }} icon={faPhone} />
                          {agence.tel} -
                          <FontAwesomeIcon className='mr-2 ml-2' style={{ height: '1rem' }} icon={faEnvelope} />
                          {agence.mail} -
                          <FontAwesomeIcon className='mr-2 ml-2' style={{ height: '1rem' }} icon={faAt} />
                          {agence.site}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(MagasinIndex)