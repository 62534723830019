import React, { Component } from 'react';
import { faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap4-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as comons from '../component/commun';
import * as utils from '../lang/vitrinePanier';



class VitrinePanier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (this.props.location.state.info != '') ? this.props.location.state.info : {}),
      lang: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.lang != 'undefined') && (this.props.location.state.info.lang != '') ? this.props.location.state.info.lang : 'en'),
      panier: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.panier != 'undefined') ? this.props.location.state.info.panier : []),
      panier_copy: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.panier_copy != 'undefined') ? this.props.location.state.info.panier_copy : []),
      id_personnel: ((typeof this.props.location.state != 'undefined') && (typeof this.props.location.state.info != 'undefined') && (typeof this.props.location.state.info.id_personnel != 'undefined') ? this.props.location.state.info.id_personnel : null),
      id_agence: this.props.match.params.idAgence,
      modalShowPaAc: 0,
      languages: [],
      city: [],
      country: [],
      id_pays: '',
      msg: '',
      quantite: 1,
      total: 0,
      disabledCmd: true,
      check: false
    };
    this.state.info.lang = this.state.lang;
  }

  getVille = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/ville/${this.state.id_pays}/${this.state.lang}`).then(res => {
      this.setState({
        city: res.data,
      });
    }).catch(err => {
      toast.warning(err.message, {
        autoClose: 3000
      });
    });
  }

  getPays = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/entite/pays/${this.state.lang}`).then(res => {
      this.setState({
        country: res.data,
        isLoading3: false //Informer de la fin du chargement
      });
    }).catch(err => {
      toast.warning(err.message, {
        autoClose: 3000
      });
    });
  }

  getCGV = () => {
    axios.get(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/constant/cgv/${this.state.id_agence}`).then(res => {
      this.setState({
        cgvContent: res.data
      });
    }).catch(err => {
      toast.warning(err.message, {
        autoClose: 3000
      });
    });
  }

  componentDidMount() {
    this.calculerTotal();
    this.getPays();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modalShowPaAc !== this.props.modalShowPaAc) {
      this.setState({
        modalShowPaAc: this.props.modalShowPaAc,
        panier: this.props.panier,
        panier_copy: this.props.panier_copy
      });
      // Ne prend pas en compte la mise à jour du setState ... 
      this.state.panier = this.props.panier;
      this.calculerTotal();
    }
    if (prevState.id_pays !== this.state.id_pays) {
      this.getVille()
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleChangeQuantite = (e) => {
    this.state.panier[parseInt(e.target.name)].quantite = e.target.value;
    this.setState({
      panier: this.state.panier
    });
    this.calculerTotal();
  }

  handleModalChange = (e) => {
    const modalShowPaAc = parseInt(e.target.value);
    this.setState({ modalShowPaAc });
    this.props.changeModal(modalShowPaAc);
    if (modalShowPaAc == 0) {
      this.state.panier = this.state.panier_copy;
      this.props.changePanier(this.state.panier);
    }
  }

  handlePanierChange = (e) => {
    this.props.changePanier(this.state.panier);
    const modalShowPaAc = 0;
    this.setState({ modalShowPaAc });
    this.props.changeModal(modalShowPaAc);
  }

  handleRemoveItem = (index) => {
    let pn = this.state.panier;
    pn.splice(index, 1);
    this.setState({
      panier: pn
    });
    this.calculerTotal();
  }

  calculerTotal = () => {
    let mntTot = 0;
    this.state.panier.forEach(el => {
      mntTot += (el.prix * el.quantite);
    });
    this.state.total = mntTot;
    this.setState({
      total: mntTot
    });
  }

  handleCommande = () => {
    const { id_personnel } = this.state;
    if (id_personnel != null || id_personnel != undefined) {
      this.handleCommandeClient();
    } else if (id_personnel === null || id_personnel === undefined) {
      this.handleCommandeVisiteur();
    }
  }

  handleCommandeVisiteur = () => {
    var cmd = {
      articles: [],
      nom: this.state.nom,
      adress: this.state.adress,
      id_pays: this.state.id_pays,
      id_ville: this.state.id_ville,
      tel: this.state.tel,
      mail: this.state.mail,
      lang: this.state.lang
    };
    if (this.state.modalShowPaAc === 4) {
      cmd.articles.push({ id_article: this.state.panier[0].id_article, quantite: this.state.quantite });
    }
    else {
      this.state.panier.forEach(el => {
        cmd.articles.push({ id_article: el.id_article, quantite: el.quantite });
      });
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/visiteur/commande/add`, cmd).then(res => {
      this.setState({
        id_commande: res.data[0].id,
        modalShowPaAc: 5
      });
    }).catch(err => {
      toast.error(((typeof err.response != 'undefined' && typeof err.response.data != 'undefined' && typeof err.response.data.error != 'undefined') ? err.response.data.error : err.message), {
        autoClose: 3000
      });
    });
  }

  handleCommandeClient = () => {
    var cmd = {
      idPersonnel: this.state.id_personnel,
      articles: [],
      token: this.state.info.token
    };
    if (this.state.modalShowPaAc === 4) {
      cmd.articles.push({ id_article: this.state.panier[0].id_article, quantite: this.state.quantite });
    }
    else {
      this.state.panier.forEach(el => {
        cmd.articles.push({ id_article: el.id_article, quantite: el.quantite });
      });
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/client/commande/add`, cmd).then(res => {
      this.setState({
        msg: res.data[0].msg,
        modalShowPaAc: 6
      });
      this.state.panier = [];
      this.state.panier_copy = [];
    }).catch(err => {
      toast.error(((typeof err.response != 'undefined' && typeof err.response.data != 'undefined' && typeof err.response.data.error != 'undefined') ? err.response.data.error : err.message), {
        autoClose: 3000
      });
    });
  }

  handleVerifier = () => {
    const cmdV = {
      tel: this.state.tel,
      mail: this.state.mail,
      code: this.state.code,
      id_commande: this.state.id_commande,
      lang: this.state.lang
    }
    axios.post(`${comons.srv_ptl}://${comons.srv_url}:${comons.srv_por}/commande/visiteur/commande/confirm_add`, cmdV).then(res => {
      this.setState({
        msg: res.data.msg,
        modalShowPaAc: 6
      });
      this.state.panier = [];
      this.state.panier_copy = [];
    }).catch(err => {
      toast.warning(err.message, {
        autoClose: 3000
      });
    });
  }

  handlecheck = (e) => {
    this.setState({
      checkCondition: e.target.checked,
      disabledCmd: !this.state.disabledCmd
    });
  }

  conditionsAccepted = () => {
    this.setState({
      vs: !this.state.vs,
      checkCondition: true,
      disabledCmd: false
    });
  }

  modalConditions = () => {
    this.getCGV();
    this.setState({
      vs: !this.state.vs
    });
  }

  onChangeVille = (e) => {
    this.state.id_ville = '';
    for (let i = 0; i < this.state.city.length; i++) {
      if (this.state.city[i].lbl == e.target.value) {
        this.setState({ id_ville: this.state.city[i].id });
        break;
      }
    }
  }

  handleChangeCountry = (e) => {
    this.state.id_pays = '';
    for (let i = 0; i < this.state.country.length; i++) {
      if (this.state.country[i].lbl == e.target.value) {
        this.setState({ id_pays: this.state.country[i].id });
        break;
      }
    }
  }



  render() {

    return (
      <>

        {/* Affiche le contenu du panier */}
        <Modal visible={this.state.modalShowPaAc == 2} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.0`]}</h5>
          </div>
          <div className="modal-body p-0 m-0 ">
            {this.state.panier.map((el, index) => (
              <ul className='list-group list-group-flush d-flex flex-row justify-content-between' key={el.id_article}>
                <li className='list-group-item m-0 p-0'>
                  <img src={el.foto} style={{ maxWidth: '40%' }} alt="" className="img-fluid img-thumbnail rounded-circle" />
                </li>
                <li className='list-group-item'>{el.annonce}</li>
                <li className='list-group-item'>{el.prix}</li>
                <li className='list-group-item'>
                  <input type="number" min="1" max={el.maxQuantite} step="1" name={index} onChange={this.handleChangeQuantite} value={this.state.panier[index].quantite}
                    className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" autoFocus={true} style={{ width: '4em' }} />
                </li>
                <li className='list-group-item'><FontAwesomeIcon onClick={() => this.handleRemoveItem(index)} icon={faTrashAlt} /></li>
              </ul>
            ))}
            <ul className='list-group list-group-flush d-flex flex-row justify-content-between'>
              <li className='list-group-item m-0 p-0'></li>
              <li className='list-group-item'></li>
              <li className='list-group-item'>{utils.lbl[`${(this.state.lang)}.28`]}</li>
              <li className='list-group-item'>{this.state.total}</li>
              <li className='list-group-item'></li>
            </ul>
          </div>
          <div className="modal-footer">
            {/* Bouton Annuler */}
            <button type="button" className="btn btn-secondary" onClick={this.handleModalChange} value="0">
              {utils.lbl[`${(this.state.lang)}.1`]}
            </button>
            {/* Bouton Sauvegarder */}
            <button type="button" className="btn btn-secondary" onClick={this.handlePanierChange}>
              <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faSave} />
              {utils.lbl[`${(this.state.lang)}.2`]}
            </button>
            {/* Bouton commander */}
            <button type="button" className="btn btn-primary" onClick={() => this.setState({ modalShowPaAc: 3 })} disabled={this.state.panier.length <= 0}>
              {/* <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faBoxCheck} /> */}
              {utils.lbl[`${(this.state.lang)}.3`]}
            </button>
          </div>
        </Modal>

        {/*----------------------------------------- MODAL ACHAT ------------------------------------*/}

        {/* Affiche le formulaire pour l'achat du panier */}
        <Modal visible={this.state.modalShowPaAc === 3 || this.state.modalShowPaAc === 4} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.4`]}</h5>
          </div>
          <div className="modal-body pl-5">
            {this.state.modalShowPaAc === 4 ?
              <>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.5`]}</span>
                  </div>
                  <input type="number" name='quantite' value={this.state.quantite} min="1" max={this.state.panier[0].maxQantite} step="1" onChange={this.handleChange} 
                    className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.6`]}</span>
                  </div>
                  <input type="number" name='montant' value={this.state.panier[0].prix * this.state.quantite}
                    className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
                </div>
              </>
            :
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.6`]}</span>
                </div>
                <input type="number" name='montant' value={this.state.total}
                  className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
              </div>
            }
            <hr />
            {this.state.panier.length > 0 && this.state.panier[0].idLiv !== 1 ?
              <>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.7`]}</span>
                  </div>
                  <input type="number" name='fraisLivraison' value={this.state.panier[0].frLiv} onChange={this.handleChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" disabled />
                </div>
                {this.state.id_personnel == null ?
                  <>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.8`]}</span>
                      </div>
                      <input type="text" name='nom' value={this.state.nom} onChange={this.handleChange} className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.9`]}</span>
                      </div>
                      <input type="text" name='adress' value={this.state.adress} onChange={this.handleChange} className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.10`]}</span>
                      </div>
                      <input type="text" name="nom_pays" list="data1" onChange={this.handleChangeCountry} className="form-control py-2 border-right-0 border mr-2"  
                        placeholder="choisir une pays" />
                      <datalist id="data1" name="id_pays" value={this.state.id_pays}>
                        {this.state.country.map((option) => (
                          <option key={option.id} value={option.lbl} id={option.id} />
                        ))}
                      </datalist>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default">{utils.lbl[`${(this.state.lang)}.12`]}</span>
                      <input type="text" name="nom_ville" list="data" onChange={this.onChangeVille} className="form-control py-2 border-right-0 border mr-2"  
                        placeholder="choisir une ville" />
                      <datalist id="data" name="id_ville" value={this.state.id_ville}>
                        {this.state.city.map((option) => (
                          <option key={option.id} value={option.lbl} id={option.id} />
                        ))}
                      </datalist>
                    </div>
                  </>
                : ''}
              </>
              :
              <>
                {this.state.id_personnel == null ?
                  <>
                    <hr />
                    <div className='d-flex flex-row justify-content-center'>
                      <label>{utils.lbl[`${(this.state.lang)}.25`]}</label>
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.8`]}</span>
                      </div>
                      <input type="text" name='nom' value={this.state.nom} onChange={this.handleChange} className="form-control"
                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                    </div>
                  </>
                  : ''}
              </>
            }
            {this.state.id_personnel == null ?
              <>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.14`]}</span>
                  </div>
                  <input type="email" name='mail' value={this.state.mail} onChange={this.handleChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.15`]}</span>
                  </div>
                  <input type="number" name='tel' value={this.state.tel} onChange={this.handleChange} className="form-control"
                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                </div>
              </>
              : ''}
          </div>
          <div className="modal-footer">
            {/* Check accepter les conditions de vente */}
            <div className="row justify-content-center">
              <div>
                <input type="checkbox" id="agreeConditions" onChange={this.handlecheck} checked={this.state.checkCondition} />
                <label htmlFor="agreeConditions">{utils.lbl[`${(this.state.lang)}.16`]}</label>
              </div>
              <Link style={{ cursor: 'pointer', marginLeft: '15px' }} onClick={this.modalConditions}>{utils.lbl[`${(this.state.lang)}.17`]}</Link>
            </div>
            <hr className="my-3" />
            <div className="row justify-content-center">
              {/* Bouton Commander */}
              <button type="button" className="btn btn-primary" disabled={this.state.disabledCmd} onClick={this.handleCommande}>
                {utils.lbl[`${(this.state.lang)}.3`]}
              </button>
              {/* Bouton Annuler */}
              <button type="button" className="btn btn-danger" onClick={this.handleModalChange} value="0">
                {utils.lbl[`${(this.state.lang)}.1`]}
              </button>
            </div>
          </div>
        </Modal>

        {/* -------------- Modal de Confirmation ------------------ */}

        {/* Affiche la saisie du code de confirmation */}
        <Modal visible={this.state.modalShowPaAc == 5} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.19`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">{utils.lbl[`${(this.state.lang)}.20`]}</span>
              </div>
              <input type="text" name='code' value={this.state.code} onChange={this.handleChange} className="form-control"
                aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
            </div>
          </div>
          <div className="modal-footer">
            {/* Bouton Annuler */}
            <button type="button" className="btn btn-secondary" onClick={this.handleModalChange} value="0">
              {utils.lbl[`${(this.state.lang)}.1`]}
            </button>
            <button type="button" className="btn btn-primary" onClick={this.handleVerifier}>
              {utils.lbl[`${(this.state.lang)}.21`]}
            </button>
          </div>
        </Modal>

        {/* ------------------------------------------- MSG MODAL ----------------------------------  */}
        {/* Affiche les msg */}
        <Modal visible={this.state.modalShowPaAc == 6} >
          <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.22`]}</h5>
          </div>
          <div className="modal-body pl-5">
            <h2>{this.state.msg}</h2>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.handlePanierChange}>
              {utils.lbl[`${(this.state.lang)}.27`]}
            </button>
          </div>
        </Modal>

        {/* Conditions Générales de Vente */}
        <Modal visible={this.state.vs} >
          {/* <div className="modal-header">
            <h5 className="modal-title">{utils.lbl[`${(this.state.lang)}.8`]}Conditions Générales de Vente</h5>
          </div> */}
          <div className="modal-body pl-5">
            <textarea type="text" name="cgvContent" value={this.state.cgvContent} className="form-control" rows="11" readOnly aria-readonly />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={this.conditionsAccepted}>
              {utils.lbl[`${(this.state.lang)}.26`]}
            </button>
            <button type="button" className="btn btn-primary" onClick={this.modalConditions}>
              {utils.lbl[`${(this.state.lang)}.1`]}
            </button>
          </div>
        </Modal>

      </>
    )
  }
}

export default withRouter(VitrinePanier)