export var lbl = [];

lbl["fr.0"] = "RETOUR À L'ACCUEIL";
lbl["fr.1"] = "Soumettre";
lbl["fr.2"] = "Refaire une demande d'annulation";

/* AR */

lbl["ar.0"] = "الرجوع إلى الاستقبال";
lbl["ar.1"] = "إرسال";
lbl["ar.2"] = "إعادة طلب الإلغاء";

/* EN */

lbl["en.0"] = "GO BACK TO HOME";
lbl["en.1"] = "Submit";
lbl["en.2"] = "Redo a cancellation request";

/* DE */

lbl["de.0"] = "Startseite";
lbl["de.1"] = "Senden";
lbl["de.2"] = "Stornoanforderung wiederholen";

